import React from 'react';
import {URLAPI} from "../config";

const ItemList1 = ({
    title,
    subtitle,
    rightDescription,
    rightStore,
    photo,
    item,
    onClick }) => {
    return(
        <li className="list-group-item d-flex justify-content-between align-items-center" onClick={(e)=>onClick(item)}>
            <div className="d-flex align-items-center">
                <img
                    src={photo ? URLAPI + photo : window.location.origin + "/base/app-assets/images/profile/no_perfil.png"}
                    alt="" height="38" width="38"
                     className="rounded-circle"/>
                <div className="ms-5 ml-1">
                    <span className="fw-bold mb-0">{title}</span><br/>
                    <span className="text-muted mb-0">{subtitle}</span>
                </div>
            </div>
            <div className="text-right">
                <span>{rightDescription}</span><br/>
                <strong className="mail-date ">{rightStore}</strong>
            </div>
        </li>
      //   <li className={`media mail-read bg-rgba-white`} onClick={(e)=>onClick(item)}>
      //       <div className="d-flex">
      //           <div className="list-icon mr-1">
      //               <div
      //                   className="avatar bg-rgba-primary m-0">
      //                   <img className="img-fluid"
      //                        src={photo ? URLAPI + photo : window.location.origin + "/base/app-assets/images/profile/no_perfil.png"}
      //                        alt="img placeholder"
      //                        height="38" width="38"/>
      //               </div>
      //           </div>
      //           <div className="list-content">
      //               <span className="list-title text-bold-700">{title}</span>
      //               <p className="list-group-item-text  truncate mb-0">
      //                   {subtitle}
      //               </p>
      //           </div>
      //           <div className="float-right">
      //               <span className="list-title">{rightDescription}</span><br/>
      //               <span className="list-title text-bold-700">{rightStore}</span>
      //           </div>
      //       </div>
      //       {/*<div className="media-body">*/}
      //   {/*  <div className="user-details">*/}
      //   {/*      <div*/}
      //   {/*          className="avatar bg-rgba-primary m-0">*/}
      //   {/*          <img className="img-fluid"*/}
      //   {/*               src={photo ? URLAPI + photo : window.location.origin + "/base/app-assets/images/profile/no_perfil.png"}*/}
      //   {/*               alt="img placeholder"*/}
      //   {/*               height="38" width="38"/>*/}
      //   {/*      </div>*/}
      //   {/*    <div className="mail-items">*/}
      //   {/*      <strong className="mail-date">{title}</strong>*/}
      //   {/*    </div>*/}
      //   {/*    <div className="mail-meta-item">*/}
      //   {/*      <span className="float-right">*/}
      //   {/*        <span className="mail-date ">{rightDescription}</span>*/}
      //   {/*      </span>*/}
      //   {/*    </div>*/}
      //   {/*  </div>*/}
      //   {/*  <div className="mail-message ">*/}
      //   {/*    <p className="list-group-item-text  truncate mb-0">*/}
      //   {/*      {subtitle}*/}
      //   {/*    </p>*/}
      //   {/*    <div className="mail-meta-item">*/}
      //   {/*      <span className="float-right">*/}
      //   {/*        <strong className="mail-date ">{rightStore}</strong>*/}
      //   {/*      </span>*/}
      //   {/*    </div>*/}
      //   {/*  </div>*/}
      //   {/*</div>*/}
      // </li>
    )
}

export default ItemList1;
