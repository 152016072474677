import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import {
    updateTaskList,
    clear_task_list,
    UPDATE_TASK_LIST,
    CREATE_TASK,
    UPDATE_TASK,
    getAllTaskList,
    createTask,
    updateTask,
    clear_task,
    deleteTaskTemplate,
    DELETE_TASK_TEMPLATE
} from "../../../redux/task";
// import SelectInput from "../../../components/select";
import Modal from "../../../components/modal";
import CardItem from "../../../components/card-item";
import LoadingSpinner from '../../../components/loading-spinner';
import Footer from "../../../components/footer";
import {URLAPI} from "../../../config";
import Select from "react-select";

const UpdateTask = (props) => {
    let history = useHistory();
    const {id} = useParams();

    const dispatch = useDispatch();
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const _get_all_task = useSelector(
        (state) => state.task_list_module._get_all_task_list
    );

    const _get_all_groups = useSelector(
        (state) => state.users_module._get_all_groups
    );

    const update_task_list = useSelector(
        (state) => state.task_list_module._update_task_list
    );

    const _update_task = useSelector(
        (state) => state.task_list_module._update_task
    );

    const _create_task = useSelector(
        (state) => state.task_list_module._create_task
    );

    const _delete_task_template = useSelector(
        (state) => state.task_list_module._delete_task_template
    );

    const _get_user_data = useSelector(
        (state) => state.users_module._session_data
    );

    const [tipoTarea, setTipoTarea] = useState('');

    useEffect(() => {
        if (_get_user_data.data) {
            setTipoTarea(_get_user_data.data.tipo_tarea);
        }
        setIsLoading(false);
    }, [_get_user_data]);
    // const _get_all_types = useSelector(
    //     (state) => state.task_types_module._get_all_task_types
    // );
    //
    // const [types, setTypes] = useState([]);

    // useEffect(() => {
    //     if (_get_all_types.data.datos) {
    //         setTypes(_get_all_types.data.datos);
    //     }
    // }, [_get_all_types]);

    const [form, setForm] = useState({
        nombre: "",
        id_tipo: 0,
        tipo: '',
    });

    const [formTask, setFormTask] = useState({
        nombre: "",
        descripcion: "",
        id_lista_tarea: id,
        nombre_archivo: '',
        archivo: '',
        archivos: [],
        grupos: []
    });

    const [groups, setGroups] = useState([]);
    const [optionsGroup, setOptionsGroup] = useState([]);
    const [group_select, setGroupSelect] = useState([]);

    useEffect(() => {
        if (_get_all_groups && _get_all_groups.data && _get_all_groups.data.datos) {
            setGroups(_get_all_groups.data.datos.filter(item => tipoTarea === item.tipo_tarea.nombre));
            let options = [];
            // eslint-disable-next-line
            _get_all_groups.data.datos.map((item) => {
                if (tipoTarea === item.tipo_tarea.nombre) {
                    options.push({
                        value: item.id,
                        label: item.nombre,
                    });
                }
            });

            options.unshift({
                value: "ALL",
                label: "TODOS",
            })
            setOptionsGroup(options);
        }
    }, [_get_all_groups, tipoTarea]);

    const handleChangeSelect = (e) => {
        let all = e.filter(x => x.value === "ALL")
        if (all.length > 0) {
            setGroupSelect(all)
        } else {
            setGroupSelect(e);
        }
    };

    useEffect(() => {
        if (_get_all_task.data) {
            let item = _get_all_task.data.datos.filter((x) => x.id === parseInt(id))
            if (item.length > 0) {
                setForm(item[0]);
            }
        }
    }, [_get_all_task, id]);

    useEffect(() => {
        if (update_task_list.status === 200) {
            dispatch(clear_task_list(UPDATE_TASK_LIST));
            history.push("/lista-tareas");
            dispatch(getAllTaskList(token));
        } else if (update_task_list.status !== 0) {
            dispatch(clear_task_list(UPDATE_TASK_LIST));
            toast.error(update_task_list.message, {position: toast.POSITION.TOP_RIGHT});
        }
    }, [update_task_list, dispatch, history, token]);


    useEffect(() => {
        if (_update_task.status === 200) {
            dispatch(clear_task(UPDATE_TASK));
            history.push(`/lista-tarea/editar/${id}`);
            dispatch(getAllTaskList(token));
            setIsLoading(false);
            setModal(false);
        } else if (_update_task.status !== 0) {
            dispatch(clear_task(UPDATE_TASK));
            toast.error(_update_task.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_update_task, dispatch, history, token, id]);


    useEffect(() => {
        if (_delete_task_template.status === 200) {
            dispatch(clear_task(DELETE_TASK_TEMPLATE));
            dispatch(getAllTaskList(token));
            setIsLoading(false);
            setFormTask({
                nombre: _delete_task_template.data.datos[0].nombre,
                descripcion: _delete_task_template.data.datos[0].descripcion,
                id_lista_tarea: id,
                nombre_archivo: '',
                archivo: '',
                archivos: _delete_task_template.data.datos[0].archivos,
            })
            toast.success("Archivo eliminado correctamente", {position: toast.POSITION.TOP_RIGHT});
        } else if (_delete_task_template.status !== 0) {
            dispatch(clear_task(DELETE_TASK_TEMPLATE));
            toast.error(_delete_task_template.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_delete_task_template, dispatch, history, token, id]);


    useEffect(() => {
        if (_create_task.status === 201) {
            dispatch(clear_task(CREATE_TASK));
            history.push(`/lista-tarea/editar/${id}`);
            dispatch(getAllTaskList(token));
            setIsLoading(false);
            setModal(false);
        } else if (_create_task.status !== 0) {
            dispatch(clear_task_list(CREATE_TASK));
            toast.error(_create_task.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_create_task, dispatch, history, token, id]);

    const handleChange = (e) => {
        if (e.target.name === "id_tipo") {
            setForm({
                ...form,
                [e.target.name]: parseInt(e.target.value),
            });
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleChangeTask = (e) => {
        if (e.target.name === 'archivo') {
            setFormTask({
                ...formTask,
                nombre_archivo: e.target.files[0].name,
                archivo: e.target.files[0],
            });
        } else {
            setFormTask({
                ...formTask,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        //validate data
        if (!form.nombre) {
            toast.error("Debe ingresar el nombre de la tarea", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.id_tipo === 0) {
            toast.error("Debe seleccionar el tipo de tarea", {position: toast.POSITION.TOP_RIGHT});
            return;
        }

        dispatch(updateTaskList(token, id, form));
    };

    function delete_task_template(id) {
        dispatch(deleteTaskTemplate(token, id));
    }

    const [isLoading, setIsLoading] = useState(false);

    const handleSubmitTask = (e) => {
        setIsLoading(true);
        e.preventDefault();
        let all = group_select.filter(x => x.value === "ALL")
        if (all.length > 0) {
            let array = [];
            // eslint-disable-next-line
            groups.map((item) => {
                array.push(item.id)
            });
            formTask.roles = array;
        } else {
            let array = [];
            // eslint-disable-next-line
            group_select.map((item) => {
                array.push(item.value)
            });
            formTask.roles = array;
        }
        //validate data
        if (!formTask.nombre) {
            toast.error("Debe ingresar el nombre de la tarea", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (!formTask.descripcion) {
            toast.error("Debe ingresar una descripción", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }

        if (formTask.roles.length === 0) {
            toast.error("Debe seleccionar por lo menos un rol", {position: toast.POSITION.TOP_RIGHT});
            return;
        }

        if (idTask === 0) {
            dispatch(createTask(token, formTask));
        } else {
            dispatch(updateTask(token, idTask, formTask));
        }
    };

    const [modal, setModal] = useState(false);

    const [idTask, setIdTask] = useState(0);

    const [permisos, setPermisos] = useState([]);

    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);
        }
    }, [props]);


    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Tareas"
                            items={[
                                {label: "Lista de tareas", link: "/lista-tareas"},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Actualizar Lista de Tareas</h4>
                                </div>
                                <div className="card-body">
                                    <div><ToastContainer/></div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input
                                                name="nombre"
                                                label="Nombre"
                                                value={form.nombre}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input
                                                name="id_tipo"
                                                label="Tipo"
                                                value={form.tipo}
                                                onChange={handleChange}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <h6>Tareas</h6>
                                        </div>
                                        <div className="col-12 d-flex justify-content-end mb-2">
                                            <button type="button"
                                                    className="btn btn-calendar text-bold "
                                                    onClick={() => {
                                                        setModal(true);
                                                        setIdTask(0);
                                                        setFormTask({
                                                            nombre: "",
                                                            descripcion: "",
                                                            id_lista_tarea: id,
                                                            nombre_archivo: '',
                                                            archivo: '',
                                                            archivos: [],
                                                        })
                                                        document.getElementById("archivo").value = "";
                                                    }}
                                            >
                                                Agregar tarea
                                            </button>
                                        </div>
                                    </div>
                                    {form.nombre ?
                                        <div className="row col-12">
                                            {
                                                form.tareas.map((_, i) => (
                                                    <div className="col-xl-3 col-md-6 col-xs-12 col-sm-6" key={i}>
                                                        <CardItem
                                                            color={'#13a3d7'}
                                                            onClick={
                                                                () => {
                                                                    let groups = _.roles.map((g) => {
                                                                        return {
                                                                            value: g.id,
                                                                            label: g.nombre,
                                                                        };
                                                                    })
                                                                    setGroupSelect(groups);
                                                                    setModal(true);
                                                                    setIdTask(parseInt(_.id));
                                                                    setFormTask({
                                                                        nombre: _.nombre,
                                                                        descripcion: _.descripcion,
                                                                        id_lista_tarea: id,
                                                                        nombre_archivo: '',
                                                                        archivo: '',
                                                                        archivos: _.archivos,
                                                                        grupos: groups
                                                                    })
                                                                    document.getElementById("archivo").value = "";
                                                                }
                                                            }
                                                        >
                                                            <h5 className="text-white">{_.nombre}</h5>
                                                            <h6 className="text-white">{_.descripcion}</h6>
                                                        </CardItem>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        : <></>
                                    }
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to="/lista-tareas" className="btn btn-outline-dark m-1">
                                        Cancelar
                                    </Link>
                                    <Button type="submit" text="Guardar" theme="calendar" classes="m-1"/>
                                </div>
                            </form>
                        </div>
                    </div>
                    <Modal className="modal-main" show={modal} style={{width: "40%"}}>
                        {isLoading && <LoadingSpinner/>}
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <form onSubmit={handleSubmitTask}>
                                        <div>
                                            {idTask === 0 ?
                                                <h5>Crear tarea</h5>
                                                :
                                                <h5>Editar tarea</h5>
                                            }
                                        </div>
                                        <div>
                                            <div className="form-group">
                                                <Input
                                                    name="nombre"
                                                    label="Nombre"
                                                    value={formTask.nombre}
                                                    onChange={handleChangeTask}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <Input
                                                    name="descripcion"
                                                    label="Descripción"
                                                    value={formTask.descripcion}
                                                    onChange={handleChangeTask}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Roles</label>
                                                <Select
                                                    value={group_select}
                                                    options={optionsGroup}
                                                    isMulti={true}
                                                    onChange={handleChangeSelect}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <Input
                                                    id="archivo"
                                                    name="archivo"
                                                    label="archivo"
                                                    type="file"
                                                    onChange={handleChangeTask}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <Input
                                                    name="nombre_archivo"
                                                    label="Nombre de archivo"
                                                    value={formTask.nombre_archivo}
                                                    onChange={handleChangeTask}
                                                />
                                            </div>
                                            {idTask !== 0 &&
                                                formTask.archivos.length > 0 && (
                                                    <div className="row mt-2">
                                                        <div className="col-12">
                                                            <h4>ARCHIVOS</h4>
                                                        </div>
                                                        {
                                                            formTask.archivos.map((_, i) => (
                                                                <div className="col-6 text-center mb-1" key={i}>
                                                                    <div>
                                                                        <a href={URLAPI + '/media/' + _.archivo}
                                                                           target="_blank" rel="noopener noreferrer"
                                                                           download={_.nombre}>
                                                                            <img
                                                                                className="logo"
                                                                                src="../../../base/app-assets/images/icon/doc.png"
                                                                                alt="archivo"
                                                                            />
                                                                            <br/>
                                                                            <label>{_.nombre}</label>
                                                                        </a>
                                                                    </div>
                                                                    {(permisos.filter(x => x.codigo === 'delete_tasks').length > 0) &&
                                                                        <>
                                                                            {/*eslint-disable-next-line*/}
                                                                            <a href="#"
                                                                               style={{
                                                                                   position: 'relative',
                                                                                   bottom: 130,
                                                                                   right: '-25%',
                                                                                   borderRadius: 10,
                                                                               }}
                                                                               onClick={() => {
                                                                                   delete_task_template(_.id)
                                                                               }}
                                                                            >
                                                                                <img
                                                                                    src="../../../base/app-assets/images/icon/delete.png"
                                                                                    alt="Eliminar"
                                                                                    width={20}
                                                                                    height={20}
                                                                                />
                                                                            </a>
                                                                        </>
                                                                    }
                                                                </div>

                                                            ))
                                                        }
                                                    </div>
                                                )}

                                        </div>
                                        <div className="text-right">
                                            <button type="button" className="btn btn-outline-dark" onClick={() => {
                                                setModal(false);
                                                setIdTask(0);
                                                setFormTask({
                                                    nombre: "",
                                                    descripcion: "",
                                                    id_lista_tarea: id,
                                                })
                                            }}> Cancelar
                                            </button>
                                            <Button type="submit" text="Guardar" theme="calendar" classes="m-1"/>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default UpdateTask;
