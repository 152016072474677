import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import Select from "react-select";
import SelectInput from "../../../components/select";
import {URLAPI} from "../../../config/index";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import {
    clear_user_module,
    UPDATE_USER,
    update_user,
    get_all_users
} from "../../../redux/users";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {Switch, Space} from "antd";
import Footer from "../../../components/footer";

const UpdateUser = () => {
    const {id} = useParams();


    const dispatch = useDispatch();
    const history = useHistory();

    const _get_all_groups = useSelector(
        (state) => state.users_module._get_all_groups
    );

    const _get_all_type_task = useSelector(
        (state) => state.task_types_module._get_all_task_types
    );

    const _get_all_users = useSelector((state) => state.users_module._get_all_users);

    const _update_user = useSelector((state) => state.users_module._update_user);


    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const [form, setForm] = useState({
        usuario: "",
        identificacion: "",
        nombres: "",
        apellidos: "",
        correo: "",
        jefe_zonal: false,
        foto: "",
        id_grupo_permiso: 0,
        id_tipo_tarea: 0,
        url_confirmacion: window.location.origin + "/activar-cuenta",
        grupo_permiso: "",
        tipo_tarea: "",
        tiendas: []
    });
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        if (_get_all_users.data) {
            const user = _get_all_users.data.filter(
                (user) => user.id === parseInt(id)
            );
            if (user.length > 0) {
                let tiendas = user[0].tiendas.map((store) => {
                    return {
                        value: store.codigo,
                        label: store.nombre,
                    };
                })
                setStoreSelect(tiendas);
                setForm({
                    usuario: user[0].usuario,
                    identificacion: user[0].identificacion,
                    nombres: user[0].nombres,
                    apellidos: user[0].apellidos,
                    correo: user[0].correo,
                    jefe_zonal: user[0].jefe_zonal,
                    foto: '/media/' + user[0].foto,
                    grupo_permiso: user[0].grupo_permiso.length > 0 ? user[0].grupo_permiso[0].nombre : "",
                    tipo_tarea: user[0].tipo_tarea ? user[0].tipo_tarea.nombre : "",
                    url_confirmacion: window.location.origin + "/activar-cuenta",
                    tiendas: tiendas
                });
            }
        }
    }, [_get_all_users, id]);

    useEffect(() => {
        if (_update_user.status === 200) {
            dispatch(clear_user_module(UPDATE_USER));
            history.push("/usuarios");
            dispatch(get_all_users(token));
        } else if (_update_user.status !== 0) {
            dispatch(clear_user_module(UPDATE_USER));
            toast.error(_update_user.message, {position: toast.POSITION.TOP_RIGHT});
        }
    }, [_update_user, dispatch, history, token]);


    useEffect(() => {
        if (_get_all_groups.data.datos) {
            setGroups(_get_all_groups.data.datos);
        }
    }, [_get_all_groups]);

    const [typesTask, setTypesTask] = useState([]);

    useEffect(() => {
        if (_get_all_type_task.data.datos) {
            setTypesTask(_get_all_type_task.data.datos);
        }
    }, [_get_all_type_task]);

    const handleChange = (e) => {
        if (e.target.name === "id_grupo_permiso" || e.target.name === "id_tipo_tarea") {
            setForm({
                ...form,
                [e.target.name]: parseInt(e.target.value),
            });
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }

    };

    const [imagePreview, setImagePreview] = useState(null);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        let url = URL.createObjectURL(file);
        setImagePreview(url);
        setForm({
            ...form,
            foto: e.target.files[0],
        });
    };

    const handleCheckedChange = (checked) => {
        setForm({
            ...form,
            jefe_zonal: checked,
        });
    };

    const _get_all_stores = useSelector(
        (state) => state.stores_module._get_all_stores
    );

    const [stores, setStores] = useState([]);
    const [optionsStores, setOptionsStores] = useState([]);
    const [store_select, setStoreSelect] = useState([]);

    useEffect(() => {
        if (_get_all_stores.data) {
            setStores(_get_all_stores.data.datos)
            let options = _get_all_stores.data.datos.map((item) => {
                return {
                    value: item.codigo,
                    label: item.nombre,
                };
            });
            options.unshift({
                value: "ALL",
                label: "TODOS",
            })
            setOptionsStores(options);
        }

    }, [_get_all_stores]);

    const handleChangeSelect = (e) => {
        let all = e.filter(x => x.value === "ALL")
        if (all.length > 0) {
            setStoreSelect(all)
        } else {
            setStoreSelect(e);
        }
    };

    const handleSubmit = (e) => {

        e.preventDefault();

        let all = store_select.filter(x => x.value === "ALL")

        if (all.length > 0) {
            form.tiendas = stores;

        } else {
            let array = store_select.map((item) => {
                return {
                    codigo: item.value,
                    nombre: item.label,
                };
            });
            form.tiendas = array;
        }

        //validate fields
        if (form.usuario === "") {
            toast.error("Debe ingresar el usuario", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.identificacion === "") {
            toast.error("Debe ingresar la identificación", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.nombres === "") {
            toast.error("Debe ingresar los nombres", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.apellidos === "") {
            toast.error("Debe ingresar los apellidos", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.correo === "") {
            toast.error("Debe ingresar el correo electrónico", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.id_grupo_permiso === 0) {
            toast.error("Debe seleccionar un grupo de permiso", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.id_tipo_tarea === 0) {
            toast.error("Debe seleccionar un tipo de tarea", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.tiendas.length === 0) {
            toast.error("Debe seleccionar por lo menos una tienda", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        dispatch(update_user(form, id, token));
    };


    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Usuarios"
                            items={[
                                {label: "Lista de usuarios", link: "/usuarios"},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Actualizar Usuario</h4>
                                </div>
                                <div className="card-body">
                                    <div><ToastContainer/></div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input
                                                label="usuario"
                                                name="Usuario"
                                                value={form.usuario}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input
                                                label="Identificación"
                                                name="identificacion"
                                                value={form.identificacion}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input
                                                label="Nombres"
                                                name="nombres"
                                                value={form.nombres}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input
                                                label="Apellidos"
                                                name="apellidos"
                                                value={form.apellidos}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input
                                                label="Correo"
                                                name="correo"
                                                value={form.correo}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-6 mt-1">
                                            <Space size={14} direction='horizontal'>
                                                <strong>JEFE ZONAL</strong>
                                                <Switch
                                                    checked={form.jefe_zonal}
                                                    checkedChildren="SI"
                                                    unCheckedChildren="NO"
                                                    onChange={handleCheckedChange}
                                                />
                                            </Space>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Grupo de Permisos</label>
                                                <SelectInput
                                                    label="Rol"
                                                    name="id_grupo_permiso"
                                                    select={form.grupo_permiso}
                                                    onChange={handleChange}
                                                    options={groups ? groups.map((group) => ({
                                                        name: group.nombre,
                                                        id: group.id,
                                                    })) : []}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Tipo de tarea</label>
                                                <SelectInput
                                                    label="Tipo de tarea"
                                                    name="id_tipo_tarea"
                                                    select={form.tipo_tarea}
                                                    onChange={handleChange}
                                                    options={typesTask ? typesTask.map((tt) => ({
                                                        name: tt.nombre,
                                                        id: tt.id,
                                                    })) : []}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Tiendas</label>
                                                <Select
                                                    value={store_select}
                                                    options={optionsStores}
                                                    isMulti={true}
                                                    onChange={handleChangeSelect}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <Input
                                                name="foto"
                                                label="Foto"
                                                type="file"
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                        <div className="col-md-6 text-center">
                                            <img
                                                src={
                                                    imagePreview == null
                                                        ? `${URLAPI}${form.foto}`
                                                        : imagePreview
                                                }
                                                alt="imagen"
                                                width={100}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to="/usuarios" className="btn btn-outline-dark m-1">
                                        Cancelar
                                    </Link>
                                    <Button type="submit" text="Guardar" theme="calendar"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default UpdateUser;
