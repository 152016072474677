import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import {
    updateTaskType,
    clear_task_types,
    UPDATE_TASK_TYPE,
    getAllTaskTypes
} from "../../../redux/task_types";
import Footer from "../../../components/footer";

const UpdateTaskType = () => {
    let history = useHistory();
    const {id} = useParams();

    const dispatch = useDispatch();
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const _get_all_task_types = useSelector(
        (state) => state.task_types_module._get_all_task_types
    );

    const _update_task_type = useSelector(
        (state) => state.task_types_module._update_task_type
    );
    const [form, setForm] = useState({
        nombre: "",
    });

    useEffect(() => {
        if (_get_all_task_types.data) {
            let item = _get_all_task_types.data.datos.filter((x) => x.id === parseInt(id))
            if (item.length > 0) {
                setForm(item[0]);
            }
        }
    }, [_get_all_task_types, id]);

    useEffect(() => {
        if (_update_task_type.status === 200) {
            dispatch(clear_task_types(UPDATE_TASK_TYPE));
            history.push("/tipo-tareas");
            dispatch(getAllTaskTypes(token));
        } else if (_update_task_type.status !== 0) {
            dispatch(clear_task_types(UPDATE_TASK_TYPE));
            toast.error(_update_task_type.message, {position: toast.POSITION.TOP_RIGHT});
        }
    }, [_update_task_type, dispatch, history, token]);

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        //validate data
        if (form.nombre === "") {
            toast.error("Debe ingresar el nombre", {position: toast.POSITION.TOP_RIGHT});
            return;
        }

        dispatch(updateTaskType(token, id, form));
    };

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Tipo de tarea"
                            items={[
                                {label: "Lista de tipo de tareas", link: "/tipo-tareas"},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Actualizar Tipo de Tareas</h4>
                                </div>
                                <div className="card-body">
                                    <div><ToastContainer/></div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Input
                                                name="nombre"
                                                label="Nombre"
                                                value={form.nombre}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to="/tipo-tareas" className="btn btn-outline-dark m-1">
                                        Cancelar
                                    </Link>
                                    <Button type="submit" text="Guardar" theme="calendar" classes="m-1"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default UpdateTaskType;
