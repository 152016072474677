import React, {useState, useEffect} from 'react';
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {Link, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
// import MultiselectTwoSides from 'react-multiselect-two-sides';
import "./create.scss";
import {
    create_group,
    CREATE_GROUP,
    clear_user_module,
    get_all_groups
} from '../../../redux/users';
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../../../components/footer";
// import {URLAPI} from "../../../config";
import Select from "react-select";
import SelectInput from "../../../components/select";

const CreateGroup = () => {
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const dispatch = useDispatch();
    const history = useHistory();
    const _get_all_permissions = useSelector((state) => state.users_module._get_all_permissions);
    const _create_group = useSelector((state) => state.users_module._create_group);
    const _get_all_type_task = useSelector(
        (state) => state.task_types_module._get_all_task_types
    );

    const [permission, setPermission] = useState([]);
    const [permissionOptions, setPermissionOptions] = useState([]);
    const [permissionSelect, setPermissionSelect] = useState([]);

    const [form, setForm] = useState({
        nombre: "",
        id_tipo_tarea: 0,
        permisos: [],
    });

    const [typesTask, setTypesTask] = useState([]);

    useEffect(() => {
        if (_get_all_type_task.data.datos) {
            setTypesTask(_get_all_type_task.data.datos);
        }
    }, [_get_all_type_task]);

    useEffect(() => {
        if (_create_group.status === 201) {
            dispatch(clear_user_module(CREATE_GROUP));
            history.push("/roles");
            dispatch(get_all_groups(token));
        } else if (_create_group.status !== 0) {
            dispatch(clear_user_module(CREATE_GROUP));
            toast.error(_create_group.message, {position: toast.POSITION.TOP_RIGHT});
        }
    }, [_create_group, dispatch, history, token]);

    useEffect(() => {
        if (_get_all_permissions.data) {
            if (_get_all_permissions.data.datos !== undefined) {
                setPermission(_get_all_permissions.data.datos)
                let datos = []
                // eslint-disable-next-line
                _get_all_permissions.data.datos.map((item) => {
                    datos.push({
                        label: item.nombre,
                        model: item.modelo,
                        value: item.codigo,
                    })
                })
                datos.unshift({
                    value: "ALL",
                    label: "TODOS",
                })
                setPermissionOptions(datos);
            }
        }
    }, [_get_all_permissions]);

    // const handleChangeMultiselect = (e) => {
    //     setPermissionSelect(e);
    //     let permisos = [];
    //     // eslint-disable-next-line
    //     e.map((item) => {
    //         permisos.push({codigo: item});
    //     })
    //     setForm({
    //         ...form,
    //         permisos: permisos,
    //     });
    // }

    const handleChange = (e) => {
        if (e.target.name === "id_tipo_tarea") {
            setForm({
                ...form,
                [e.target.name]: parseInt(e.target.value),
            });
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let all = permissionSelect.filter(x => x.value === "ALL")

        if (all.length > 0) {
            form.permisos = permission;

        } else {
            let array = permissionSelect.map((item) => {
                return {
                    nombre: item.label,
                    modelo: item.model,
                    codigo: item.value,
                };
            });
            form.permisos = array;
        }

        if (form.nombre === "") {
            toast.error("Debe ingresar el nombre del grupo", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.id_tipo_tarea === 0) {
            toast.error("Debe seleccionar un tipo de tarea", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.permisos.length === 0) {
            toast.error("Debe seleccionar al menos un permiso", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        dispatch(create_group(form, token));

    }

    const handleChangeSelect = (e) => {
        let all = e.filter(x => x.value === "ALL")
        if (all.length > 0) {
            setPermissionSelect(all)
        } else {
            setPermissionSelect(e);
        }
    };

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Roles"
                            items={[
                                {label: "Lista de roles", link: "/roles"},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Crear Rol</h4>
                                </div>
                                <div className="card-body">
                                    <div><ToastContainer /></div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input
                                                label="nombre"
                                                name="nombre"
                                                onChange={handleChange}
                                                value={form.nombre}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Tipo de tarea</label>
                                                <SelectInput
                                                    label="Tipo de tarea"
                                                    name="id_tipo_tarea"
                                                    value={form.tipo_tarea}
                                                    onChange={handleChange}
                                                    options={typesTask ? typesTask.map((tt) => ({
                                                        name: tt.nombre,
                                                        id: tt.id,
                                                    })) : []}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <div className="form-group">
                                                <label>Permisos</label>
                                                <Select
                                                    options={permissionOptions}
                                                    isMulti={true}
                                                    onChange={handleChangeSelect}
                                                    value={permissionSelect}
                                                />
                                            </div>
                                           {/* <MultiselectTwoSides
                                                options={permission}
                                                value={permissionSelect}
                                                className="msts_theme_example"
                                                onChange={handleChangeMultiselect}
                                                availableHeader="Permisos Disponibles"
                                                selectedHeader="Permisos Seleccionados"
                                                labelKey="name"
                                                showControls
                                                searchable
                                            /> */}
                                        </div>
                                    </div>

                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to="/roles" className="btn btn-outline-dark m-1">
                                        Cancelar
                                    </Link>
                                    <Button
                                        text="Crear"
                                        theme="calendar"
                                        type="submit"
                                        classes="m-1"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default CreateGroup;
