import {
    URLAPI,
    OBSERVATION_PATH,
    OBSERVATION_CREATE_PATH,
    OBSERVATION_UPDATE_PATH
} from "../config/index";
import {LOGOUT} from './users'
import axios from "axios";

const init = {
    _get_all_observation: {
        data: [],

        status: 0,
        message: {},
    },

    _create_observation: {
        data: {},
        status: 0,
        message: {},
    },
    _update_observation: {
        data: {},
        status: 0,
        message: {},
    },
};

const GET_ALL_OBSERVATION = "GET_ALL_OBSERVATION";
export const CREATE_OBSERVATION = "CREATE_OBSERVATION";
export const UPDATE_OBSERVATION = "UPDATE_OBSERVATION";

export const observation_module = (state = init, action) => {
    switch (action.type) {
        case GET_ALL_OBSERVATION:
            return {
                ...state,
                _get_all_observation: action.payload,
            };
        case CREATE_OBSERVATION:
            return {
                ...state,
                _create_observation: action.payload,
            };
        case UPDATE_OBSERVATION:
            return {
                ...state,
                _update_observation: action.payload,
            };
        case LOGOUT:
            return init;
        default:
            return state;
    }
};

export const getAllObservation = (token) => async (dispatch) => {
    try {
        const response = await axios.get(`${URLAPI}${OBSERVATION_PATH}`, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: GET_ALL_OBSERVATION,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: GET_ALL_OBSERVATION,
                payload: {
                    data: [],
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: GET_ALL_OBSERVATION,
                payload: {
                    data: [],
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};


export const createObservation = (token, data) => async (dispatch) => {
    try {
        const response = await axios.post(
            `${URLAPI}${OBSERVATION_CREATE_PATH}`,
            data,
            {
                headers: {
                    Authorization: `Basic ${token}`,
                },
            }
        );
        dispatch({
            type: CREATE_OBSERVATION,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: CREATE_OBSERVATION,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CREATE_OBSERVATION,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const updateObservation = (token, id, data) => async (dispatch) => {
    try {
        const response = await axios.patch(
            `${URLAPI}${OBSERVATION_UPDATE_PATH}${id}/`,
            data,
            {
                headers: {
                    Authorization: `Basic ${token}`,
                },
            }
        );
        dispatch({
            type: UPDATE_OBSERVATION,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: UPDATE_OBSERVATION,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: UPDATE_OBSERVATION,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const clear_observation = (type) => async (dispatch) => {
    dispatch({
        type: type,
        payload: {
            data: [],
            status: 0,
            message: {},
        },
    });
};
