import {
  URLAPI,
  CITIES_PATH,
  CITY_CREATE_PATH,
  CITY_UPDATE_PATH,
} from "../config/index";
import { LOGOUT } from './users'
import axios from "axios";

const init = {
  _get_all_cities: {
    data: [],

    status: 0,
    message: {},
  },

  _create_city: {
    data: {},
    status: 0,
    message: {},
  },
  _update_city: {
    data: {},
    status: 0,
    message: {},
  },
};

const GET_ALL_CITIES = "GET_ALL_CITIES";
export const CREATE_CITY = "CREATE_CITY";
export const UPDATE_CITY = "UPDATE_CITY";

export const cities_module = (state = init, action) => {
  switch (action.type) {
    case GET_ALL_CITIES:
      return {
        ...state,
        _get_all_cities: action.payload,
      };
    case CREATE_CITY:
      return {
        ...state,
        _create_city: action.payload,
      };
    case UPDATE_CITY:
      return {
        ...state,
        _update_city: action.payload,
      };
    case LOGOUT:
      return init;
    default:
      return state;
  }
};

export const getAllCities = (token) => async (dispatch) => {
  try {
    const response = await axios.get(`${URLAPI}${CITIES_PATH}`, {
      headers: {
        Authorization: `Basic ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_CITIES,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ALL_CITIES,
        payload: {
          data: [],
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: GET_ALL_CITIES,
        payload: {
          data: [],
          status: 500,
          message: error.message,
        },
      });
    }
  }
};

export const createCity = (token, data) => async (dispatch) => {
  try {
    let form = new FormData();

    for (var key in data) {
      form.append(key, data[key]);
    }
    const response = await axios.post(
      `${URLAPI}${CITY_CREATE_PATH}`,
      form,
      {
        headers: {
          Authorization: `Basic ${token}`,
        },
      }
    );
    dispatch({
      type: CREATE_CITY,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CREATE_CITY,
        payload: {
          data: {},
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: CREATE_CITY,
        payload: {
          data: {},
          status: 500,
          message: error.message,
        },
      });
    }
  }
};

export const updateCity = (token, id, data) => async (dispatch) => {
  try {
    let form = new FormData();

    for (var key in data) {
      form.append(key, data[key]);
    }
    const response = await axios.patch(
      `${URLAPI}${CITY_UPDATE_PATH}${id}/`,
      form,
      {
        headers: {
          Authorization: `Basic ${token}`,
        },
      }
    );
    dispatch({
      type: UPDATE_CITY,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: UPDATE_CITY,
        payload: {
          data: {},
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: UPDATE_CITY,
        payload: {
          data: {},
          status: 500,
          message: error.message,
        },
      });
    }
  }
};

export const clear_cities = (type) => async (dispatch) => {
  dispatch({
    type: type,
    payload: {
      data: [],
      status: 0,
      message: {},
    },
  });
};
