import {
  URLAPI,
  CHECKS_PATH,
  CHECK_CREATE_PATH,
  CHECK_UPDATE_PATH,
} from "../config/index";
import { LOGOUT } from './users'
import axios from "axios";

const init = {
  _get_all_checks: {
    data: [],

    status: 0,
    message: {},
  },

  _create_check: {
    data: {},
    status: 0,
    message: {},
  },
  _update_check: {
    data: {},
    status: 0,
    message: {},
  },
};

const GET_ALL_CHECKS = "GET_ALL_CHECKS";
export const CREATE_CHECK = "CREATE_CHECK";
export const UPDATE_CHECK = "UPDATE_CHECK";

export const checks_module = (state = init, action) => {
  switch (action.type) {
    case GET_ALL_CHECKS:
      return {
        ...state,
        _get_all_checks: action.payload,
      };
    case CREATE_CHECK:
      return {
        ...state,
        _create_check: action.payload,
      };
    case UPDATE_CHECK:
      return {
        ...state,
        _update_check: action.payload,
      };
    case LOGOUT:
      return init;
    default:
      return state;
  }
};

export const getAllChecks = (token) => async (dispatch) => {
  try {
    const response = await axios.get(`${URLAPI}${CHECKS_PATH}`, {
      headers: {
        Authorization: `Basic ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_CHECKS,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ALL_CHECKS,
        payload: {
          data: [],
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: GET_ALL_CHECKS,
        payload: {
          data: [],
          status: 500,
          message: error.message,
        },
      });
    }
  }
};

export const createCheck = (token, data) => async (dispatch) => {
  try {
    let form = new FormData();

    for (var key in data) {
      form.append(key, data[key]);
    }
    const response = await axios.post(
      `${URLAPI}${CHECK_CREATE_PATH}`,
      form,
      {
        headers: {
          Authorization: `Basic ${token}`,
        },
      }
    );
    dispatch({
      type: CREATE_CHECK,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CREATE_CHECK,
        payload: {
          data: {},
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: CREATE_CHECK,
        payload: {
          data: {},
          status: 500,
          message: error.message,
        },
      });
    }
  }
};

export const updateCheck = (token, id, data) => async (dispatch) => {
  try {
    let form = new FormData();

    for (var key in data) {
      form.append(key, data[key]);
    }
    const response = await axios.patch(
      `${URLAPI}${CHECK_UPDATE_PATH}${id}/`,
      form,
      {
        headers: {
          Authorization: `Basic ${token}`,
        },
      }
    );
    dispatch({
      type: UPDATE_CHECK,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: UPDATE_CHECK,
        payload: {
          data: {},
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: UPDATE_CHECK,
        payload: {
          data: {},
          status: 500,
          message: error.message,
        },
      });
    }
  }
};

export const clear_checks = (type) => async (dispatch) => {
  dispatch({
    type: type,
    payload: {
      data: [],
      status: 0,
      message: {},
    },
  });
};
