import {
  URLAPI,
  ASSIST_CONTROL_PATH,
  MARKINGS_PATH,
  ASSIST_CONTROL_CREATE_PATH,
  ASSIST_CONTROL_UPDATE_PATH,
} from "../config/index";
import { LOGOUT } from './users'
import axios from "axios";

const init = {
  /* _get_all_assist_control: {
    data: [],

    status: 0,
    message: {},
  }, */
  _create_assist_control: {
    data: {},
    status: 0,
    message: {},
  },
  _update_assist_control: {
    data: {},
    status: 0,
    message: {},
  },

};

//const GET_ALL_ASSIST_CONTROL = "GET_ALL_ASSIST_CONTROL";
export const CREATE_ASSIST_CONTROL = "CREATE_ASSIST_CONTROL";
export const UPDATE_ASSIST_CONTROL = "UPDATE_ASSIST_CONTROL";

export const assist_control_module = (state = init, action) => {
  switch (action.type) {
    /* case GET_ALL_ASSIST_CONTROL:
      return {
        ...state,
        _get_all_assist_control: action.payload,
      }; */
    case CREATE_ASSIST_CONTROL:
      return {
        ...state,
        _create_assist_control: action.payload,
      };
    case UPDATE_ASSIST_CONTROL:
      return {
        ...state,
        _update_assist_control: action.payload,
      };
    case LOGOUT:
      return init;
    default:
      return state;
  }
};

/* export const getAllAssistControl = (token) => async (dispatch) => {
  try {
    const response = await axios.get(`${URLAPI}${ASSIST_CONTROL_PATH}`, {
      headers: {
        Authorization: `Basic ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_ASSIST_CONTROL,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ALL_ASSIST_CONTROL,
        payload: {
          data: [],
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: GET_ALL_ASSIST_CONTROL,
        payload: {
          data: [],
          status: 500,
          message: error.message,
        },
      });
    }
  }
}; */

export const createAssistControl = (token, data) => async (dispatch) => {
  try {
    let form = new FormData();

    for (var key in data) {
      form.append(key, data[key]);
    }
    const response = await axios.post(
      `${URLAPI}${ASSIST_CONTROL_CREATE_PATH}`,
      form,
      {
        headers: {
          Authorization: `Basic ${token}`,
        },
      }
    );
    dispatch({
      type: CREATE_ASSIST_CONTROL,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CREATE_ASSIST_CONTROL,
        payload: {
          data: {},
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: CREATE_ASSIST_CONTROL,
        payload: {
          data: {},
          status: 500,
          message: error.message,
        },
      });
    }
  }
};

export const updateAssistControl = (token, id, data) => async (dispatch) => {
  try {
    let form = new FormData();

    for (var key in data) {
      form.append(key, data[key]);
    }
    const response = await axios.patch(
      `${URLAPI}${ASSIST_CONTROL_UPDATE_PATH}${id}/`,
      form,
      {
        headers: {
          Authorization: `Basic ${token}`,
        },
      }
    );
    dispatch({
      type: UPDATE_ASSIST_CONTROL,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: UPDATE_ASSIST_CONTROL,
        payload: {
          data: {},
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: UPDATE_ASSIST_CONTROL,
        payload: {
          data: {},
          status: 500,
          message: error.message,
        },
      });
    }
  }
};

export const searchAssistControl = async (token, paramsUrl) => {
  return await axios.get(`${URLAPI}${ASSIST_CONTROL_PATH}${paramsUrl}`, {
      headers: {
          Authorization: `Basic ${token}`,
      },
  });
}

export const searchMarkings = async (token, paramsUrl) => {
  return await axios.get(`${URLAPI}${MARKINGS_PATH}${paramsUrl}`, {
      headers: {
          Authorization: `Basic ${token}`,
      },
  });
}

export const clear_assist_control = (type) => async (dispatch) => {
  dispatch({
    type: type,
    payload: {
      data: [],
      status: 0,
      message: {},
    },
  });
};
