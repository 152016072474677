import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import Footer from "../../../components/footer";
import Breadcrumbs from "../../../components/breadcrumbs";
import Button from "../../../components/button";
import Modal from "../../../components/modal";
import LoadingSpinner from "../../../components/loading-spinner";
import Input from "../../../components/input";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SelectInput from '../../../components/select';
import {searchMarkings, updateAssistControl, UPDATE_ASSIST_CONTROL, clear_assist_control} from '../../../redux/assist_control';


const AssistControl = (props) => {
    const {token} = useSelector((state) => state.users_module.login.data) || null;
    let dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const [datas, setDatas] = useState([]);
    const [usuario, setUsuario] = useState('');
    const [users, setUsers] = useState([]);
    const [fecha_inicial, setFechaInicial] = useState('');
    const [fecha_final, setFechaFinal] = useState('');
    const [modalObservation, setModalObservation] = useState(false);
    const [id, setId] = useState(0);
    const [observation, setObservation] = useState('');
    const [typeTask, setTypeTask] = useState(0);

    const _get_user_data = useSelector(
        (state) => state.users_module._session_data
    );

    useEffect(() => {
        if (_get_user_data.data) {
            setTypeTask(_get_user_data.data.tipo_tarea);
        }
        setIsLoading(false);
    }, [_get_user_data, token]);

    const _get_all_users = useSelector(
        (state) => state.users_module._get_all_users
    );

    useEffect(() => {
        if (_get_all_users.data) {
            let filter = _get_all_users.data.filter(x => x.activo === true && x.tipo_tarea.nombre === typeTask);
            setUsers(filter);
        }
    }, [_get_all_users, typeTask]);

    const handleChange = (e) => {
        setUsuario(e.target.value);
    }

    const getMarkings = async (params) => {
        const response = await searchMarkings(token, params);
        if (response && response.data && response.data.datos) {
            setDatas(response.data.datos);
        }
        setIsLoading(false);
    }


    function search_assist_control() {
        setIsLoading(true);
        if (usuario === "") {
            toast.error("Debe seleccionar un usuario", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (fecha_inicial === "") {
            toast.error("Debe seleccionar la fecha inicial", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (fecha_final === "") {
            toast.error("Debe seleccionar la fecha final", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }

        let variants = "?usuario=" + usuario + '&&fecha_inicio=' + fecha_inicial + '&&fecha_fin=' + fecha_final;
        getMarkings(variants);
    }

    const _update_assist_control = useSelector((state) => state.assist_control_module._update_assist_control);

    useEffect(() => {
        if (_update_assist_control.status === 200) {
            toast.success("Actualizado correctamente", {position: toast.POSITION.TOP_RIGHT});
            dispatch(clear_assist_control(UPDATE_ASSIST_CONTROL));
            setIsLoading(false);
            setModalObservation(false);
            setObservation('');
            setId(0);
            search_assist_control();
        } else if (_update_assist_control.status !== 0) {
            toast.error(_update_assist_control.message, {position: toast.POSITION.TOP_RIGHT});
            dispatch(clear_assist_control(UPDATE_ASSIST_CONTROL));
            setIsLoading(false);
        }
        // eslint-disable-next-line
    }, [_update_assist_control]);
    
    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        
        if (observation === "") {
            toast.error("Debe ingresar una observación", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        
        dispatch(updateAssistControl(token, id, {'observacion': observation}));
    };

    return (
        <div className="app-content content">
            <div className="content-overlay"/>
            {isLoading && <LoadingSpinner/>}
            <div><ToastContainer/></div>
            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Actividades"
                            items={[{label: "Marcaciones", link: "/marcaciones"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="row m-1">
                                    <div className="col-md-3 col-sm-6 col-12">
                                        <label>Usuario</label>
                                        <SelectInput
                                            id="usuario"
                                            name="usuario"
                                            value={usuario}
                                            onChange={handleChange}
                                            options={users ? users.map((item) => ({
                                                name: item.nombres + ' ' + item.apellidos,
                                                id: item.usuario,
                                            })) : []}
                                        />
                                    </div>
                                    <div className="col-md-3 col-sm-6 col-12">
                                        <Input
                                            name="fecha_inicial"
                                            label="Desde"
                                            type="date"
                                            value={fecha_inicial}
                                            onChange={(e) => {setFechaInicial(e.target.value)}}
                                        />
                                    </div>
                                    <div className="col-md-3 col-sm-6 col-12">
                                        <Input
                                            name="fecha_inicio"
                                            label="Hasta"
                                            type="date"
                                            value={fecha_final}
                                            onChange={(e) => {setFechaFinal(e.target.value)}}
                                        />
                                    </div>
                                    <div className="col-md-3 col-sm-6 col-12 mt-md-2 mt-sm-3 text-center">
                                        <button type='button' onClick={() => {search_assist_control()}} className='btn btn-calendar'>Buscar</button>
                                    </div>
                                    
                                    <div className='col-12'>
                                        {datas && 
                                            datas.map((x) => (
                                                <div className='row mb-2'>
                                                    <div className='col-12'>
                                                        <h5 style={{background: '#FFC912', padding: '5px 10px'}}>{x.fecha}</h5>
                                                    </div>
                                                    <div className='col-md-6 col-12'>
                                                        <small>Fecha de inicio de jornada</small><br/>
                                                        <strong>{x.ingreso}</strong>
                                                    </div>
                                                    <div className='col-md-6 col-12'>
                                                        <small>Fecha de fin de jornada</small><br/>
                                                        <strong>{x.salida}</strong>
                                                    </div>
                                                    {x.observacion ? (
                                                        <div className='col-12'>
                                                            <small>Observación</small><br/>
                                                            <strong>{x.observacion}</strong>
                                                        </div>
                                                    ) : (
                                                        <div className='col-12 text-right'>
                                                            <button type='button' onClick={() => {setModalObservation(true); setId(x.id); setObservation('');}} className='btn btn-calendar'>Agregar observación</button>
                                                        </div>
                                                    )}
                                                    <div className='col-12 mt-1'>
                                                        <table className='table table-hover'>
                                                            <thead>
                                                                <tr>
                                                                    <th>Tienda</th>
                                                                    <th>Tipo</th>
                                                                    <th>Fecha</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {x.tiendas.map((t) => (
                                                                    <tr>
                                                                        <td>{t.nombre}</td>
                                                                        <td>{t.tipo}</td>
                                                                        <td>{t.fecha}</td>
                                                                    </tr>
                                                                ))}        
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal-main" show={modalObservation} style={{width: "40%"}}>
                {isLoading && <LoadingSpinner/>}
                <div className="modal-content">
                    <div className="modal-header">
                        <h5>Observación</h5>
                    </div>
                    <div className="modal-body p-2">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <Input
                                    id="observation"
                                    name="observation"
                                    label="Observación"
                                    onChange={(e) => {setObservation(e.target.value)}}
                                    value={observation}
                                />
                            </div>
                            <div className="text-center">
                                <button type="button" className="btn btn-outline-dark" onClick={() => {
                                    setModalObservation(false);
                                    setId(0);
                                }}> Cancelar
                                </button>
                                <Button type="submit" text="Guardar" theme="calendar" classes="m-1"/>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
            <Footer/>
        </div>
    )
}

export default AssistControl;
