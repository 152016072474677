import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Footer from "../../../components/footer";
import CardItem from "../../../components/card-item";
import Breadcrumbs from "../../../components/breadcrumbs";
import Search from "../../../components/search";
import {URLAPI} from "../../../config";
import {searchActivities} from "../../../redux/task_scheduled";
import Modal from "../../../components/modal";
import SelectInput from "../../../components/select";

const ActivitiesList = (props) => {
    let history = useHistory();
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const _get_all_activities = useSelector(
        (state) => state.task_scheduled_module._get_all_activities
    );

    const [modalDetail, setModalDetail] = useState(false);
    const [data, setData] = useState([]);
    const [item, setItem] = useState({});
    const [groups, setGroups] = useState([]);
    
    useEffect(() => {
        if (_get_all_activities.data.datos) {
            setData(_get_all_activities.data.datos);
            setGroups(_get_all_activities.data.grupos);
        }
    }, [_get_all_activities]);

    const handleSearch = async (e) => {
        // setBuscar(e.target.value);
        const params = '?buscar=' + e.target.value;
        const response = await searchActivities(token, params);
        if (response && response.data && response.data.datos) {
            setData(response.data.datos);
        }
    }

    const handleChange = (e) => {
        if (e.target.value === '0') {
            setData(_get_all_activities.data.datos);
        } else {
            let filter = _get_all_activities.data.datos.filter(x => x.grupo === e.target.value);
            setData(filter);
        }
    };

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Actividades"
                            items={[{label: "Lista de actividades", link: "/actividades"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-6 mb-2">
                                        <SelectInput
                                            label="Rol"
                                            name="id_grupo_permiso"
                                            onChange={handleChange}
                                            options={groups ? groups.map((group) => ({
                                                name: group.nombre,
                                                id: group.nombre,
                                            })) : []}
                                        />
                                    </div>
                                    <div className="col-sm-6 mb-2">
                                        <Search placeholder={"Buscar"}
                                                onChange={handleSearch}
                                        />
                                    </div>
                                    {data.map((item) => (
                                        <div className="col-md-4 col-sm-6 col-12">
                                            <CardItem data-toggle="modal"
                                                      data-target="#activitytModal"
                                                      onClick={() => {
                                                            setModalDetail(true);
                                                            setItem(item);
                                                              /* history.push(`/actividades/detalle/${item.username}/${item.fecha}`); */
                                                          }
                                                      }>
                                                <>
                                                    <div className=" d-flex">
                                                        <div
                                                            className="list-icon mr-1">
                                                            <div
                                                                className="avatar bg-rgba-primary m-0">
                                                                <img
                                                                    src={item.foto ? URLAPI + item.foto : window.location.origin + "/base/app-assets/images/profile/no_perfil.png"
                                                                    }
                                                                    alt="img"
                                                                    height="70"
                                                                    width="70"/>
                                                            </div>
                                                        </div>
                                                        <div className="list-content" style={{width: '100%', cursor: 'pointer'}}>
                                                            <p className="text-bold-700" style={{fontSize: 18}}>{item.usuario }</p>
                                                            <p className="text-bold-700" style={{color: '#FFC912', fontSize: 35}}>{parseFloat(item.porcentaje).toFixed(2)} %</p>
                                                            <div className="progress mt-1">
                                                                <div className="progress-bar bg-calendar"
                                                                     role="progressbar" style={{width: item.porcentaje + '%'}}
                                                                     aria-valuenow="100" aria-valuemin="0"
                                                                     aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </>
                                            </CardItem>
                                        </div>
                                    ))}

                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal className="modal-main" show={modalDetail} style={{width: "30%"}}>
                        <div className="modal-header">
                            <h5 className="text-bold-400">Seleccionar</h5>
                            <button type="button" style={{marginRight: -24}}
                                    className="btn btn-calendar badge-circle" onClick={() => {
                                setModalDetail(!modalDetail);
                            }}> X
                            </button>
                        </div>
                        <div className="card-body">
                            <div className="text-center">
                                <button style={{width: '90%'}} type="button" className="btn btn-calendar mb-1" onClick={() => {
                                    history.push(`/actividades/detalle/${item.username}/${item.fecha}`);
                                }}>Ver todas las actividades
                                </button>
                                {/* <button style={{width: '90%'}} type="button" className="btn btn-calendar mb-1" onClick={() => {
                                    history.push(`/actividades/mensuales/${item.username}/${item.fecha}`);
                                }}>Ver seguimiento mensual
                                </button> */}
                                <button style={{width: '90%'}} type="button" className="btn btn-calendar" onClick={() => {
                                    history.push(`/actividades/agenda/${item.username}/${item.usuario}`);
                                }}>Ver agenda
                                </button>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
            <Footer/>
        </div>
    )

}

export default ActivitiesList;
