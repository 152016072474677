import React, {useEffect, useState} from "react";
import DataTable from "../../../components/data-table";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
// import SelectInput from "../../../components/select";
import Input from "../../../components/input";
import Button from "../../../components/button";
import {clear_task_list, CREATE_TASK_LIST, createTaskList, getAllTaskList} from "../../../redux/task";
import Footer from "../../../components/footer";
// import {URLAPI} from "../../../config";
// import {Map, Marker, ZoomControl} from "pigeon-maps";
import Modal from "../../../components/modal";
import { searchTypeUser } from "../../../redux/users";

const Task = (props) => {
    const dispatch = useDispatch();
    let history = useHistory();

    const {user, token} = useSelector((state) => state.users_module.login.data) || null;

    const _createTaskList = useSelector((state) => state.task_list_module._create_task_list);

    // const _get_all_types = useSelector(
    //     (state) => state.task_types_module._get_all_task_types
    // );

    // const [types, setTypes] = useState([]);
    const [modal, setModal] = useState(false);

    // useEffect(() => {
    //     if (_get_all_types.data.datos) {
    //         setTypes(_get_all_types.data.datos);
    //     }
    // }, [_get_all_types]);


    const task_list = useSelector(
        (state) => state.task_list_module._get_all_task_list
    );

    const initForm = {
        // id_tipo: 0,
        nombre: '',
    }

    const [form, setForm] = useState(initForm);

    const [data, setData] = useState([]);

    useEffect(() => {
        typeUser();
        // eslint-disable-next-line
    }, [task_list, user]);

    const typeUser = async (e) => {
        const params = '?usuario=' + user;
        const response = await searchTypeUser(token, params);
        if (response.status === 200) {
            let type = response.data.tipo_tarea;
            let filter = task_list.data.datos.filter(x => x.tipo === type);
            if (filter.length > 0) {
                setData(filter);
            }
        }
    }

    const [permisos, setPermisos] = useState([]);
    const [actions, setActions] = useState([]);


    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);

        }
    }, [props]);


    useEffect(() => {
        let act = []
        if (permisos) {
            if (permisos.filter(x => x.codigo === 'change_tasklist').length > 0) {
                act.push({
                    name: "edit",
                    label: "Edit",
                    icon: "bx bx-edit",
                    color: "calendar",
                    onClick: (item) => {
                        history.push(`/lista-tarea/editar/${item.id}`);
                    },
                })

            }
        }
        setActions(act);
    }, [permisos, history]);

    // const [showOverlay, setShowOverlay] = useState(false);
    // const [showSibarRight, setShowSibarRight] = useState(false);

    useEffect(() => {
        if (_createTaskList.status === 201) {
            setForm({
                // id_tipo: 0,
                nombre: '',
            })
            // setShowOverlay(false);
            // setShowSibarRight(false);
            setModal(false);
            dispatch(clear_task_list(CREATE_TASK_LIST));
            let id = _createTaskList.data.datos[0].id
            history.push(`/lista-tarea/editar/${id}`);
            dispatch(getAllTaskList(token));
        } else if (_createTaskList.status !== 0) {
            dispatch(clear_task_list(CREATE_TASK_LIST));
            toast.error(_createTaskList.message, {position: toast.POSITION.TOP_RIGHT});
        }
    }, [_createTaskList, dispatch, history, token]);

    const handleChange = (e) => {
        // if (e.target.name === "id_tipo") {
        //     setForm({
        //         ...form,
        //         [e.target.name]: parseInt(e.target.value),
        //     });
        // } else {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
        // }

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        //validate fields
        if (!form.nombre) {
            toast.error("Debe ingresar el nombre de la tarea", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        // if (form.id_tipo === 0) {
        //     toast.error("Debe seleccionar el tipo de tarea", {position: toast.POSITION.TOP_RIGHT});
        //     return;
        // }
        dispatch(createTaskList(token, form));
    };

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Tareas"
                            items={[{label: "Lista de tareas", link: "/lista-tareas"}]}
                        />
                        {
                            permisos.filter(element => element.codigo === "add_tasklist").length > 0 &&
                            <button
                                className="mt-2 btn btn-calendar"
                                onClick={() => {
                                    // setShowSibarRight(!showSibarRight);
                                    // setShowOverlay(!showSibarRight);
                                    setModal(true);
                                }}
                            >Nueva lista</button>
                        }
                    </div>
                </div>
                <div className="content-body">
                    <div><ToastContainer/></div>
                    <div className="content-area-wrapper" style={{margin: 0}}>
                        <div className="card">
                            <div className="card-content">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <DataTable
                                            dataTable={data}
                                            columns={[
                                                {
                                                    name: "fecha_creacion",
                                                    label: "Fecha",
                                                    type: "text",
                                                },
                                                {
                                                    name: "nombre",
                                                    label: "Descripción",
                                                    type: "text",
                                                },
                                                {
                                                    name: "tipo",
                                                    label: "Tipo",
                                                    type: "text",
                                                }
                                            ]}
                                            actions={actions}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal-main" show={modal} style={{width: "40%"}}>
                <div className="modal-header">
                    <h5 className="text-bold-400">Nueva lista de tareas</h5>
                    <button type="button" style={{marginRight: -24}}
                            className="btn btn-calendar badge-circle" onClick={() => {
                        setModal(false);
                    }}> X
                    </button>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit} id="compose-form">
                        <div className="card-content">
                            <div className="card-body pt-0">
                                <div className="form-group">
                                    <Input
                                        name="nombre"
                                        label="Nombre"
                                        value={form.nombre}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="card-footer d-flex justify-content-end pt-0">
                            <Button
                                text="Guardar"
                                theme="calendar"
                                type="submit"
                            />
                        </div>
                    </form>
                </div>
            </Modal>
            <Footer/>
        </div>
    );
};

export default Task;
