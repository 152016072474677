import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {
    createCheck,
    CREATE_CHECK,
    clear_checks,
    getAllChecks
} from "../../../redux/checks";
import Footer from "../../../components/footer";
import SelectInput from "../../../components/select";

const CreateCheck = () => {
    const dispatch = useDispatch();
    let history = useHistory();

    const _create_check = useSelector(
        (state) => state.checks_module._create_check
    );

    const _get_all_banks = useSelector(
        (state) => state.banks_module._get_all_banks
    );

    const _get_all_societies = useSelector(
        (state) => state.societies_module._get_all_societies
    );

    const _get_all_stores = useSelector(
        (state) => state.stores_module._get_all_stores
    );

    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const [banks, setBanks] = useState([]);
    const [societies, setSocieties] = useState([]);
    const [stores, setStores] = useState([]);
    const [v_stores, setVStores] = useState([]);

    const [form, setForm] = useState({
        numero: "",
        id_banco: 0,
        id_tienda: 0,
    });

    useEffect(() => {
        if (_get_all_banks && _get_all_banks.data && _get_all_banks.data.datos) {
            setBanks(_get_all_banks.data.datos);
        }
    }, [_get_all_banks]);
    
    useEffect(() => {
        if (_get_all_societies && _get_all_societies.data && _get_all_societies.data.datos) {
            setSocieties(_get_all_societies.data.datos);
        }
    }, [_get_all_societies]);

    useEffect(() => {
        if (_get_all_stores && _get_all_stores.data && _get_all_stores.data.datos) {
            setStores(_get_all_stores.data.datos);
        }
    }, [_get_all_stores]);

    useEffect(() => {
        if (_create_check.status === 201) {
            dispatch(clear_checks(CREATE_CHECK));
            history.push("/cheques");
            dispatch(getAllChecks(token));
        } else if (_create_check.status !== 0) {
            dispatch(clear_checks(CREATE_CHECK));
            toast.error(_create_check.message, {position: toast.POSITION.TOP_RIGHT});
        }
    }, [_create_check, dispatch, history, token]);

    const handleChange = (e) => {
        if (e.target.name === "id_banco" || e.target.name === "id_tienda") {
            setForm({
                ...form,
                [e.target.name]: parseInt(e.target.value),
            });
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }
    };
    
    const handleChangeSocieties = (e) => {
        console.log(e.target.value)
        let item = stores.filter((x) => parseInt(x.id_sociedad) === parseInt(e.target.value))
        if (item.length > 0) {
            setVStores(item);
            document.getElementById("id_tienda").selectedIndex = 0;
            setForm({
                ...form,
                id_tienda: 0
            })
        }
    };



    const handleSubmit = (e) => {
        e.preventDefault();
        //validate fields
        if (form.numero === "") {
            toast.error("Debe ingresar el número de cheque", {position: toast.POSITION.TOP_RIGHT});
            return;
        }

        if (form.id_banco === 0) {
            toast.error("Debe seleccionar un banco", {position: toast.POSITION.TOP_RIGHT});
            return;
        }

        if (form.id_tienda === 0) {
            toast.error("Debe seleccionar una tienda", {position: toast.POSITION.TOP_RIGHT});
            return;
        }

        dispatch(createCheck(token, form));
    };

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Cheques"
                            items={[
                                {label: "Lista de cheques", link: "/cheques"},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Crear Cheque</h4>
                                </div>
                                <div className="card-body">
                                    <div><ToastContainer/></div>
                                    <div className="row">
                                        <div className=" col-md-6">
                                            <Input
                                                name="numero"
                                                label="Número de cheque"
                                                value={form.numero}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Banco</label>
                                                <SelectInput
                                                    label="banco"
                                                    name="id_banco"
                                                    value={form.id_banco}
                                                    onChange={handleChange}
                                                    options={banks ? banks.map((item) => ({
                                                        name: item.nombre,
                                                        id: item.id,
                                                    })) : []}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Sociedad</label>
                                                <SelectInput
                                                    label="sociedad"
                                                    name="id_sociedad"
                                                    value={form.id_sociedad}
                                                    onChange={handleChangeSocieties}
                                                    options={societies ? societies.map((item) => ({
                                                        name: item.nombre,
                                                        id: item.id,
                                                    })) : []}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Tienda</label>
                                                <SelectInput
                                                    label="tienda"
                                                    id="id_tienda"
                                                    name="id_tienda"
                                                    value={form.id_tienda}
                                                    onChange={handleChange}
                                                    options={v_stores ? v_stores.map((item) => ({
                                                        name: item.nombre,
                                                        id: item.id,
                                                    })) : []}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to="/cheques" className="btn btn-outline-dark m-1">
                                        Cancelar
                                    </Link>
                                    <Button type="submit" text="Crear" theme="calendar" classes="m-1"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default CreateCheck;
