import React, {useEffect, useState} from "react";
import DataTable from "../../../components/data-table";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Button from "../../../components/button";
import Modal from "../../../components/modal";
import Input from "../../../components/input";
import LoadingSpinner from '../../../components/loading-spinner';
import CheckBox from "../../../components/check-box";

import {
    clear_observation, 
    createObservation, 
    CREATE_OBSERVATION, 
    getAllObservation, 
    updateObservation, 
    UPDATE_OBSERVATION
} from "../../../redux/observation";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Observation = (props) => {
    let history = useHistory();
    let dispatch = useDispatch();

    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const _get_all_observation = useSelector((state) => state.observation_module._get_all_observation);
    const _create_observation = useSelector((state) => state.observation_module._create_observation);
    const _update_observation = useSelector((state) => state.observation_module._update_observation);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (_get_all_observation.data.datos) {
            setData(_get_all_observation.data.datos);
        }
    }, [_get_all_observation]);

    const [permisos, setPermisos] = useState([]);
    const [actions, setActions] = useState([]);
    const [modal, setModal] = useState(false);
    const [modal_delete, setModalDelete] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [id, setId] = useState(0);

    const [form, setForm] = useState({
        nombre: "",
        activo: "",
        id: 0
    });

    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);
        }
    }, [props]);

    useEffect(() => {
        let act = []
        if (permisos) {
            if (permisos.filter(x => x.codigo === 'change_observations').length > 0) {
                act.push({
                    name: "edit",
                    label: "Edit",
                    icon: "bx bx-edit",
                    color: "calendar",
                    onClick: (item) => {
                        setModal(true);
                        setId(item.id);
                        setForm({...form, nombre: item.nombre, activo: item.activo});
                    },
                })
            }
        }
        setActions(act);
    // eslint-disable-next-line
    }, [permisos, history]);

    const handleChange = (e) => {
        if (e.target.name === 'activo') {
            setForm({
                ...form,
                [e.target.name]: e.target.checked,
            });
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }
    };

    useEffect(() => {
        if (_create_observation.status === 201) {
            toast.success("Creado correctamente", {position: toast.POSITION.TOP_RIGHT});
            dispatch(clear_observation(CREATE_OBSERVATION));
            dispatch(getAllObservation(token));
            setIsLoading(false);
            setModal(false);
        } else if (_create_observation.status !== 0) {
            dispatch(clear_observation(CREATE_OBSERVATION));
            toast.error(_create_observation.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_create_observation, dispatch, history, token, id]);

    useEffect(() => {
        if (_update_observation.status === 200) {
            toast.success("Actualizado correctamente", {position: toast.POSITION.TOP_RIGHT});
            dispatch(clear_observation(UPDATE_OBSERVATION));
            dispatch(getAllObservation(token));
            setIsLoading(false);
            setModal(false);
            setId(0);
        } else if (_update_observation.status !== 0) {
            dispatch(clear_observation(UPDATE_OBSERVATION));
            toast.error(_update_observation.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_update_observation, dispatch, history, token, id]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (form.nombre === "") {
            toast.error("Debe ingresar el nombre", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (id === 0) {
            dispatch(createObservation(token, form));
        } else {
            console.log(form);
            dispatch(updateObservation(token, id, form));
        }
    };

    const handleDelete = () => {
        dispatch(updateObservation(token, id, form));
    };

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Lista"
                            items={[{label: "Observaciones", link: "/observaciones"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div><ToastContainer/></div>
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                {
                                    permisos.filter(x => x.codigo === 'add_observations').length > 0 ? 
                                    <Button type="button" text="Crear" theme="calendar" onClick={() => {
                                        setModal(true); 
                                        setForm({...form, nombre: ''}); 
                                        setId(0);
                                    }}/> : null
                                }
                                <div className="table-responsive">
                                    <DataTable
                                        dataTable={data}
                                        columns={[
                                            {
                                                name: "fecha_creacion",
                                                label: "Fecha de creación",
                                                type: "text",
                                            },
                                            {
                                                name: "nombre",
                                                label: "Descripción",
                                                type: "text",
                                            },
                                            {
                                                name: "tipo",
                                                label: "Tipo",
                                                type: "text",
                                            },
                                            {
                                                name: "activo",
                                                label: "Activo",
                                                type: "boolean",
                                            },

                                        ]}
                                        actions={actions}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal-main" show={modal} style={{width: "40%"}}>
                {isLoading && <LoadingSpinner/>}
                <div className="modal-content">
                    <div className="modal-header">
                        <h5>Observación</h5>
                    </div>
                    <div className="modal-body p-2">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <Input
                                    id="nombre"
                                    name="nombre"
                                    label="Nombre"
                                    onChange={handleChange}
                                    value={form.nombre}
                                />
                            </div>
                            {(id !== 0) &&
                                <div className="form-group">
                                    <CheckBox label="Activo" name="activo" id="activo" onChange={handleChange}state={form.activo}/>
                                </div> 
                            }
                            <div className="text-center">
                                <button type="button" className="btn btn-outline-dark" onClick={() => {
                                    setModal(false);
                                    setId(null);
                                    setForm({
                                        correo: "",
                                        id: id,
                                    })
                                }}> Cancelar
                                </button>
                                <Button type="submit" text="Guardar" theme="calendar" classes="m-1"/>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
            <Modal className="modal-main" show={modal_delete} style={{width: "40%"}}>
                {isLoading && <LoadingSpinner/>}
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="text-center">
                            <h5>¿Está seguro de inactivar el registro?</h5>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={() => {handleDelete()}}>
                            SI
                        </button>
                        <button type="button" className="btn btn-secondary" onClick={() => {setModalDelete(false)}}>
                            NO
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Observation;
