import {
    URLAPI,
    NOTIFICATIONS_TASK_PATH,
    UPDATE_NOTIFICATIONS_TASK_PATH,
} from "../config/index";
import { LOGOUT } from './users'
import axios from "axios";

const init = {
    _get_all_notifications: {
        data: [],
        status: 0,
        message: {},
    },
    _update_notifications: {
        data: [],
        status: 0,
        message: {},
    },
};

const GET_ALL_NOTIFICATIONS = "GET_ALL_NOTIFICATIONS";
export const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS";

export const notifications_module = (state = init, action) => {
    switch (action.type) {
        case GET_ALL_NOTIFICATIONS:
            return {
                ...state,
                _get_all_notifications: action.payload,
            };
        case UPDATE_NOTIFICATIONS:
            return {
                ...state,
                _update_notifications: action.payload,
            };
        case LOGOUT:
            return init;
        default:
            return state;
    }
};

export const getAllNotifications = (token, user) => async (dispatch) => {
    try {
        const paramsUrl = '?usuario=' + user
        const response = await axios.get(`${URLAPI}${NOTIFICATIONS_TASK_PATH}${paramsUrl}`, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: GET_ALL_NOTIFICATIONS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: GET_ALL_NOTIFICATIONS,
                payload: {
                    data: [],
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: GET_ALL_NOTIFICATIONS,
                payload: {
                    data: [],
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const updateNotifications = (token, id, data) => async (dispatch) => {
    try {
        let form = new FormData();

        for (var key in data) {
            form.append(key, data[key]);
        }
        const response = await axios.patch(
            `${URLAPI}${UPDATE_NOTIFICATIONS_TASK_PATH}${id}/`,
            form,
            {
                headers: {
                    Authorization: `Basic ${token}`,
                },
            }
        );
        dispatch({
            type: UPDATE_NOTIFICATIONS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: UPDATE_NOTIFICATIONS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: UPDATE_NOTIFICATIONS,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const searchNotifications = async (token, paramsUrl) => {
    return await axios.get(`${URLAPI}${NOTIFICATIONS_TASK_PATH}${paramsUrl}`, {
        headers: {
            Authorization: `Basic ${token}`,
        },
    });
}


export const clear_notification = (type) => async (dispatch) => {
    dispatch({
        type: type,
        payload: {
            data: [],
            status: 0,
            message: {},
        },
    });
};
