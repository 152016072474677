import React from 'react';


const CardItem = ({title, color, footer, children, ...props}) => {
    return (
        <div className="card "
             {...props}
        >
            <div className="card-content" style={{background: color, boxShadow: "0px 0px 10px #888888",}}>
                {title &&
                <div className="card-header text-white d-flex justify-content-center">
                    <h5 className="text-white">
                        {title}
                    </h5>
                </div>
                }
                <div className="card-body">
                    {children}
                </div>
                {footer &&
                <div className="card-footer d-flex justify-content-center text-white text-bold-400">
                    {footer}
                </div>
                }
            </div>
        </div>
    )
}

export default CardItem;
