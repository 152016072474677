import {createStore, combineReducers, compose, applyMiddleware} from "redux";
import {loadState} from "./state";
import thunk from "redux-thunk";
import {persistStore, persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {users_module} from "./users";
import {provinces_module} from "./provinces";
import {cities_module} from "./cities";
import {stores_module} from "./stores";
import {assist_control_module} from "./assist_control";
import {task_types_module} from "./task_types";
import {schedules_module} from "./schedules";
import {task_list_module} from "./task";
import {task_scheduled_module} from "./task_scheduled";
import {notifications_module} from "./notifications";
import {observation_module} from "./observation";
import {skip_task_module} from "./skip_task";
import {zones_module} from "./zones";
import {banks_module} from "./banks";
import {societies_module} from "./societies";
import {checks_module} from "./checks";

const rootReducer = combineReducers({
    users_module: users_module,
    provinces_module: provinces_module,
    cities_module: cities_module,
    stores_module: stores_module,
    assist_control_module: assist_control_module,
    task_types_module: task_types_module,
    schedules_module: schedules_module,
    task_list_module: task_list_module,
    task_scheduled_module: task_scheduled_module,
    notifications_module: notifications_module,
    observation_module: observation_module,
    skip_task_module: skip_task_module,
    zones_module: zones_module,
    banks_module: banks_module,
    societies_module: societies_module,
    checks_module: checks_module,
});

const persistConfig = {
    key: "root",
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const generateStore = () => {
    const initialData = loadState();

    const store = createStore(
        persistedReducer,
        initialData,
        composeEnhancers(applyMiddleware(thunk))
    );

    const persistor = persistStore(store, ["auth"]);

    return {store, persistor};
};
