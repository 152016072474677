import {
  URLAPI,
  BANKS_PATH,
  BANK_CREATE_PATH,
  BANK_UPDATE_PATH,
} from "../config/index";
import { LOGOUT } from './users'
import axios from "axios";

const init = {
  _get_all_banks: {
    data: [],

    status: 0,
    message: {},
  },

  _create_bank: {
    data: {},
    status: 0,
    message: {},
  },
  _update_bank: {
    data: {},
    status: 0,
    message: {},
  },
};

const GET_ALL_BANKS = "GET_ALL_BANKS";
export const CREATE_BANK = "CREATE_BANK";
export const UPDATE_BANK = "UPDATE_BANK";

export const banks_module = (state = init, action) => {
  switch (action.type) {
    case GET_ALL_BANKS:
      return {
        ...state,
        _get_all_banks: action.payload,
      };
    case CREATE_BANK:
      return {
        ...state,
        _create_bank: action.payload,
      };
    case UPDATE_BANK:
      return {
        ...state,
        _update_bank: action.payload,
      };
    case LOGOUT:
      return init;
    default:
      return state;
  }
};

export const getAllBanks = (token) => async (dispatch) => {
  try {
    const response = await axios.get(`${URLAPI}${BANKS_PATH}`, {
      headers: {
        Authorization: `Basic ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_BANKS,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ALL_BANKS,
        payload: {
          data: [],
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: GET_ALL_BANKS,
        payload: {
          data: [],
          status: 500,
          message: error.message,
        },
      });
    }
  }
};

export const createBank = (token, data) => async (dispatch) => {
  try {
    let form = new FormData();

    for (var key in data) {
      form.append(key, data[key]);
    }
    const response = await axios.post(
      `${URLAPI}${BANK_CREATE_PATH}`,
      form,
      {
        headers: {
          Authorization: `Basic ${token}`,
        },
      }
    );
    dispatch({
      type: CREATE_BANK,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CREATE_BANK,
        payload: {
          data: {},
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: CREATE_BANK,
        payload: {
          data: {},
          status: 500,
          message: error.message,
        },
      });
    }
  }
};

export const updateBank = (token, id, data) => async (dispatch) => {
  try {
    let form = new FormData();

    for (var key in data) {
      form.append(key, data[key]);
    }
    const response = await axios.patch(
      `${URLAPI}${BANK_UPDATE_PATH}${id}/`,
      form,
      {
        headers: {
          Authorization: `Basic ${token}`,
        },
      }
    );
    dispatch({
      type: UPDATE_BANK,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: UPDATE_BANK,
        payload: {
          data: {},
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: UPDATE_BANK,
        payload: {
          data: {},
          status: 500,
          message: error.message,
        },
      });
    }
  }
};

export const clear_banks = (type) => async (dispatch) => {
  dispatch({
    type: type,
    payload: {
      data: [],
      status: 0,
      message: {},
    },
  });
};
