import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import Footer from "../../../components/footer";
import Breadcrumbs from "../../../components/breadcrumbs";
import {URLAPI} from "../../../config";
import {DirectionsRenderer, GoogleMap, MarkerF, useJsApiLoader} from "@react-google-maps/api";
import {KEY_MAPS} from "../../../config";
import Modal from "../../../components/modal";
import LoadingSpinner from "../../../components/loading-spinner";
import {ReactComponent as ReactLogo} from '../../../button.svg';
import Input from "../../../components/input";
import { searchActivitiesAgenda, searchActivitiesDetail, getLogHistoryTaskScheduled } from '../../../redux/task_scheduled';
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const ActivitiesAgenda = (props) => {
    let history = useHistory();
    const {username, usuario} = useParams();
    const {token} = useSelector((state) => state.users_module.login.data) || null;
        

    const [isLoading, setIsLoading] = useState(false);
    const [item, setItem] = useState({});

    const {isLoaded} = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: KEY_MAPS,
    });

    const [modal, setModal] = useState(false);
    const [modalLocations, setModalLocations] = useState(false);
    const [dataHistory, setDataHistory] = useState([]);
    const [datas, setDatas] = useState([]);
    const [fecha_inicial, setFechaInicial] = useState('');
    const [fecha_final, setFechaFinal] = useState('');

    const activitiesAgenda = async (params) => {
        const response = await searchActivitiesAgenda(token, params);
        if (response && response.data && response.data.datos) {
            setDatas(response.data.datos);
        }
    }

    const [directionsResponse, setDirectionsResponse] = useState(null);

    function clearRoute() {
        setDirectionsResponse(null);
    }

    let directionsService;

    const calculateRoute = () => {
        directionsService = new window.google.maps.DirectionsService()
        const routesCopy = item.ubicaciones.map(route => {
            return {
                location: {lat: route.lat, lng: route.lng},
                stopover: true
            };
        });

        const origin =
            routesCopy.length === 1
                ? new window.google.maps.LatLng(
                    routesCopy[0].location.lat,
                    routesCopy[0].location.lng
                )
                : routesCopy.shift().location;
        const destination =
            routesCopy.length === 1
                ? new window.google.maps.LatLng(
                    routesCopy[0].location.lat,
                    routesCopy[0].location.lng
                )
                : routesCopy.pop().location;
        const waypoints = routesCopy;

        //call getDirection function
        getDirection(origin, destination, waypoints);
    };

    const getDirection = (origin, destination, waypoints) => {
        waypoints.length >= 1
            ? directionsService.route(
                {
                    origin: origin,
                    destination: destination,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                    waypoints: waypoints
                },
                (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        setDirectionsResponse(result)
                    } else {
                        console.log('error fetching directions1455 ' + result);
                    }
                }
            )
            : directionsService.route(
                {
                    origin: origin,
                    destination: destination,
                    travelMode: window.google.maps.TravelMode.DRIVING
                },
                (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        setDirectionsResponse(result)
                    } else {
                        console.log('error fetching directions ' + result);
                    }
                }
            );
    };

    function search_activities_agenda() {
        if (fecha_inicial === "") {
            toast.error("Debe seleccionar la fecha inicial", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (fecha_final === "") {
            toast.error("Debe seleccionar la fecha final", {position: toast.POSITION.TOP_RIGHT});
            return;
        }

        let variants = "?usuario=" + username + '&&fecha_inicio=' + fecha_inicial + '&&fecha_fin=' + fecha_final;
        activitiesAgenda(variants);
    }

    const activitiesDetail = async (params) => {
        const response = await searchActivitiesDetail(token, params);
        if (response && response.data && response.data.cantidad > 0) {
            setItem(response.data.datos[0]);
            let result = "?id_tarea_programada=" + response.data.datos[0].id_tarea_horario;
            const respo = await getLogHistoryTaskScheduled(token, result);
            if (respo && respo.data && respo.data.cantidad > 0) {
                setDataHistory(respo.data.datos);
            }
            setModal(true);
            setIsLoading(false);
        } else {
            setItem({});
            setIsLoading(false);
        }
    };

    const onItemClickListener = async (id) => {
        setIsLoading(true);
        let params = "?id_tarea_horario=" + id;
        activitiesDetail(params);
    };
    

    return (
        <div className="app-content content">
            <div className="content-overlay"/>
            {isLoading && <LoadingSpinner/>}
            <div><ToastContainer/></div>
            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Actividades"
                            items={[{label: "Lista de actividades", link: "/actividades"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="row m-1">
                                    <div className="col-12">
                                        <div className="border_card d-flex">
                                            <div style={{cursor: 'pointer'}} onClick={() => {history.push(`/actividades`);}}>
                                                <ReactLogo width={50} heght={40}/>
                                            </div>
                                            <p className="ml-1" style={{marginTop: 10, fontSize: 18, color: '#727E8C'}}>Agenda de actividades de {usuario}</p>
                                        </div>
                                    </div>
                                    <div className=" col-md-4">
                                        <Input
                                            name="fecha_inicial"
                                            label="Desde"
                                            type="date"
                                            value={fecha_inicial}
                                            onChange={(e) => {setFechaInicial(e.target.value)}}
                                        />
                                    </div>
                                    <div className=" col-md-4">
                                        <Input
                                            name="fecha_inicio"
                                            label="Hasta"
                                            type="date"
                                            value={fecha_final}
                                            onChange={(e) => {setFechaFinal(e.target.value)}}
                                        />
                                    </div>
                                    <div className=" col-md-4 mt-2 text-center">
                                        <button type='button' onClick={() => {search_activities_agenda()}} className='btn btn-calendar'>Buscar</button>
                                    </div>
                                    <div className='col-12 mt-2'>
                                        <div className=" d-flex">
                                            <div className="mr-2">
                                                <h5 className="text-bold-700">Tareas</h5>
                                            </div>
                                            <div className="mr-2">
                                                <div className=" d-flex">
                                                    <div style={{
                                                        width: 20,
                                                        height: 20,
                                                        borderRadius: '50%',
                                                        background: '#48D7A4',
                                                        display: 'flex',
                                                        marginRight: 10
                                                    }}/>
                                                    <h5 style={{color: '#48D7A4'}}>Realizadas</h5>
                                                </div>
                                            </div>
                                            <div className="mr-2">
                                                <div className=" d-flex">
                                                    <div style={{
                                                        width: 20,
                                                        height: 20,
                                                        borderRadius: '50%',
                                                        background: '#FFC912',
                                                        display: 'flex',
                                                        marginRight: 10
                                                    }}/>
                                                    <h5 style={{color: '#FFC912'}}>Pendientes</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        {datas && 
                                            <table className='table table-hover'>
                                                <thead>
                                                    <tr>
                                                        <th>Tarea</th>
                                                        <th>Fecha</th>
                                                        <th>Tienda</th>
                                                        <th>Estado</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {datas.map((x) => (
                                                        <tr onClick={() => {onItemClickListener(x.id_tarea_horario)}} style={{cursor: 'pointer'}}>
                                                            <td>{x.tarea}</td>
                                                            <td>{x.fecha}</td>
                                                            <td>{x.tienda}</td>
                                                            <td className='text-center'>
                                                                {x.realizado === 1 ?
                                                                    <div style={{
                                                                        width: 20,
                                                                        height: 20,
                                                                        borderRadius: '50%',
                                                                        background: '#48D7A4',
                                                                        display: 'flex',
                                                                        marginRight: 10
                                                                    }}/>
                                                                    :
                                                                    <div style={{
                                                                        width: 20,
                                                                        height: 20,
                                                                        borderRadius: '50%',
                                                                        background: '#FFC912',
                                                                        display: 'flex',
                                                                        marginRight: 10
                                                                    }}/>

                                                                }
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal className="modal-main" show={modal} style={{width: "40%"}}>
                    <div className="modal-header">
                        <h5 className="text-bold-400">{(item && item.tipo !== 'TAREA') &&
                            <>{item.descripcion}</>
                        }</h5>
                        <button type="button" style={{marginRight: -24}}
                                className="btn btn-calendar badge-circle" onClick={() => {
                            setModal(!modal);
                            setItem({})
                            setDataHistory([]);
                        }}> X
                        </button>
                    </div>
                    <div className="card-body">
                        <div className="row mb-1">
                            {(item && item.tipo === 'TAREA') ?
                                <>
                                    <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                        <div style={{marginTop: 5, marginBottom: 5}}>
                                            <strong>{item.tarea}</strong>
                                        </div>
                                        <span>{item.lista_tarea}</span><br/>
                                        <span>{(item.realizado === 1) ? 'Realizada el ' + item.fecha : 'Fecha: ' + item.fecha}</span>
                                        {(item.ubicaciones.length > 0) &&
                                            <div className="d-flex">
                                                <img
                                                    src={window.location.origin + "/base/app-assets/images/logo/geolocation.png"}
                                                    alt="" height="20" width="20"
                                                    className="rounded-circle mr-1"/>
                                                {/*eslint-disable-next-line*/}
                                                <a href="#" onClick={() => {
                                                    setModalLocations(true);
                                                    calculateRoute();
                                                }}>Ver ubicación</a>
                                            </div>
                                        }
                                    </div>
                                    <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                        <div style={{marginTop: 5, marginBottom: 5}}>
                                            <strong>{item.tienda}</strong>
                                        </div>
                                        <span>{item.observacion_inicial}</span>
                                    </div>
                                    <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                        <div style={{marginTop: 5, marginBottom: 5}}>
                                            <strong>Recursos de la tarea</strong>
                                        </div>
                                        {item.recursos.map((re) => (
                                            <div className="d-flex">
                                                <img
                                                    src={window.location.origin + "/base/app-assets/images/logo/download.png"}
                                                    alt="" height="20" width="20"
                                                    className="rounded-circle mr-1"/>
                                                {/*eslint-disable-next-line*/}
                                                <a href={URLAPI + re.archivo} target="_blank">{re.nombre}</a>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                        <div style={{marginTop: 5, marginBottom: 5}}>
                                            <strong>Resultados</strong>
                                        </div>
                                        {item.resultados.map((re) => (
                                            <div className="d-flex">
                                                <img
                                                    src={window.location.origin + "/base/app-assets/images/logo/download.png"}
                                                    alt="" height="20" width="20"
                                                    className="rounded-circle mr-1"/>
                                                {/*eslint-disable-next-line*/}
                                                <a href={URLAPI + re.archivo} target="_blank">{re.nombre}</a>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                        <div style={{marginTop: 5, marginBottom: 5}}>
                                            <strong>Fotografías de evidencia</strong>
                                        </div>
                                        <div className="row mb-1">
                                            {item.imagenes.map((re) => (
                                                <div className="col-4 text-center">
                                                    {/*eslint-disable-next-line*/}
                                                    <a href={URLAPI + re.archivo} target="_blank">
                                                        <img
                                                            src={URLAPI + re.archivo}
                                                            alt="" height="80" width="100"/>
                                                    </a>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                        <div style={{marginTop: 5, marginBottom: 5}}>
                                            <strong>Observaciones finales</strong>
                                        </div>
                                        {item.observacion && 
                                            <div style={{marginBottom: 10}}>
                                                <span>{item.observacion}</span><br/>
                                            </div>
                                        }
                                        <div style={{marginBottom: 10}}>
                                            <span>{item.observacion_final}</span><br/>
                                        </div>
                                    </div>
                                    {(dataHistory.length > 0) && (
                                        <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                            <div style={{marginTop: 5, marginBottom: 5}}>
                                                <strong>Historias</strong>
                                            </div>
                                            <div className="mb-1">
                                                    {dataHistory.map((item) => (
                                                        item.logs.map((x) => (
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <div><span>{x.nombre_tipo}</span></div>
                                                                <div className="text-right"><span className='text-right'>{x.fecha}</span></div>
                                                            </div>
                                                        ))
                                                    ))}
                                            </div>
                                        </div>
                                    )}
                                </>
                                :
                                <>
                                    <div className="col-12 mb-1">
                                        <span>Fecha y hora: {item.fecha}</span>
                                    </div>
                                    <div className="col-12 mb-1">
                                        <div className="d-flex">
                                            <img
                                                src={window.location.origin + "/base/app-assets/images/logo/geolocation.png"}
                                                alt="" height="20" width="20"
                                                className="rounded-circle mr-1"/>
                                            <span>Ubicación</span>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-1 text-center">
                                        {(item && item.latitud && item.longitud && isLoaded) &&
                                            <GoogleMap
                                                center={{
                                                    lat: parseFloat(item.latitud),
                                                    lng: parseFloat(item.longitud)
                                                }}
                                                zoom={11}
                                                options={{streetViewControl: false}}
                                                mapContainerStyle={{width: "100%", height: "50vh"}}
                                            >
                                                <MarkerF
                                                    position={{
                                                        lat: parseFloat(item.latitud),
                                                        lng: parseFloat(item.longitud)
                                                    }}
                                                />
                                            </GoogleMap>
                                        }
                                    </div>
                                    <div className="col-12 mb-1">
                                        <div className="d-flex">
                                            <img
                                                src={window.location.origin + "/base/app-assets/images/logo/image.png"}
                                                alt="" height="20" width="20"
                                                className="rounded-circle mr-1"/>
                                            <span>Foto</span>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-1 text-center">
                                        {/*eslint-disable-next-line*/}
                                        <a href={URLAPI + item.imagen} target="_blank">
                                            <img
                                                src={item.imagen ? URLAPI + item.imagen : window.location.origin + "/base/app-assets/images/logo/image.png"}
                                                alt="" height="180" width="50%"/>
                                        </a>
                                    </div>
                                </>

                            }
                        </div>
                    </div>
                </Modal>

                <Modal className="modal-main" show={modalLocations} style={{width: "40%"}}>
                    <div>
                        <button type="button" className="close btn btn-calendar badge-circle" onClick={() => {
                            setModalLocations(!modalLocations);
                            clearRoute();
                        }}> X
                        </button>
                    </div>
                    <div className="card-body">
                        <div className="row mb-1">
                            {(isLoaded && item && item.ubicaciones) &&
                                <GoogleMap
                                    center={item.ubicaciones[0]}
                                    zoom={11}
                                    options={{streetViewControl: false}}
                                    mapContainerStyle={{width: "100%", height: "50vh"}}
                                >
                                    {directionsResponse && (
                                        <DirectionsRenderer directions={directionsResponse}/>
                                    )}
                                </GoogleMap>
                            }
                        </div>
                    </div>
                </Modal>
            </div>
            <Footer/>
        </div>
    )
}

export default ActivitiesAgenda;
