import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import Footer from "../../../components/footer";
import SelectInput from "../../../components/select";
import TextArea from "../../../components/text-area";
import { getAllChecks, updateCheck, clear_checks, UPDATE_CHECK } from "../../../redux/checks";

const UpdateBank = () => {
    let history = useHistory();
    const {id} = useParams();

    const dispatch = useDispatch();
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const [banks, setBanks] = useState([]);
    const [societies, setSocieties] = useState([]);
    const [stores, setStores] = useState([]);
    const [payment_date, setPaymentDate] = useState('');
    const [v_stores, setVStores] = useState([]);
    /* let states = [
        {"id": 'not_collected', "nombre": 'Girado y no cobrado'},
        {"id": 'collected', "nombre": 'Cobrado'},
        {"id": 'canceled', "nombre": 'Anulado'}
    ]; */

    const _get_all_checks = useSelector(
        (state) => state.checks_module._get_all_checks
    );

    const _get_all_banks = useSelector(
        (state) => state.banks_module._get_all_banks
    );

    const _get_all_societies = useSelector(
        (state) => state.societies_module._get_all_societies
    );

    const _get_all_stores = useSelector(
        (state) => state.stores_module._get_all_stores
    );

    useEffect(() => {
        if (_get_all_banks && _get_all_banks.data && _get_all_banks.data.datos) {
            setBanks(_get_all_banks.data.datos);
        }
    }, [_get_all_banks]);
    
    useEffect(() => {
        if (_get_all_societies && _get_all_societies.data && _get_all_societies.data.datos) {
            setSocieties(_get_all_societies.data.datos);
        }
    }, [_get_all_societies]);

    useEffect(() => {
        if (_get_all_stores && _get_all_stores.data && _get_all_stores.data.datos) {
            setStores(_get_all_stores.data.datos);
        }
    }, [_get_all_stores]);

    const _update_check = useSelector(
        (state) => state.checks_module._update_check
    );
    const [form, setForm] = useState({
        numero: "",
        banco: "",
        tienda: "",
        sociedad: "",
        beneficiario: null,
        observacion: null,
        fecha_entrega: null,
        id_banco: 0,
        id_tienda: 0,
        id_sociedad: 0,
        estado: 'not_collected',
        monto: 0,
    });

    useEffect(() => {
        if (_get_all_checks.data) {
            let item = _get_all_checks.data.datos.filter((x) => x.id === parseInt(id))
            if (item.length > 0) {
                setForm(item[0]);
                console.log(item[0])
                let v_stores = stores.filter((x) => x.sociedad === item[0].sociedad)
                if (v_stores.length > 0) {
                    setVStores(v_stores);
                }
            }
        }
    }, [_get_all_checks, stores, id]);

    useEffect(() => {
        if (_update_check.status === 200) {
            dispatch(clear_checks(UPDATE_CHECK));
            history.push("/cheques");
            dispatch(getAllChecks(token));
        } else if (_update_check.status !== 0) {
            dispatch(clear_checks(UPDATE_CHECK));
            toast.error(_update_check.message, {position: toast.POSITION.TOP_RIGHT});
        }
    }, [_update_check, dispatch, history, token]);

    const handleChange = (e) => {
        if (e.target.name === "id_banco" || e.target.name === "id_tienda") {
            setForm({
                ...form,
                [e.target.name]: parseInt(e.target.value),
            });
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }
    };
    
    const handleChangeSocieties = (e) => {
        let item = stores.filter((x) => parseInt(x.id_sociedad) === parseInt(e.target.value))
        if (item.length > 0) {
            setVStores(item);
            document.getElementById("id_tienda").selectedIndex = 0;
            setForm({
                ...form,
                id_tienda: 0
            })
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        //validate data
        if (form.id_banco === 0) {
            toast.error("Debe seleccionar un banco", {position: toast.POSITION.TOP_RIGHT});
            return;
        }

        if (form.id_tienda === 0) {
            toast.error("Debe seleccionar una tienda", {position: toast.POSITION.TOP_RIGHT});
            return;
        }

        if (form.beneficiario === null) {
            toast.error("Debe ingresar un beneficiario", {position: toast.POSITION.TOP_RIGHT});
            return;
        }

        if (form.fecha_entrega === null) {
            toast.error("Debe seleccionar un fecha de entrega", {position: toast.POSITION.TOP_RIGHT});
            return;
        }

        if (parseFloat(form.monto) <= 0) {
            toast.error("Debe ingresarun monto mayor a 0", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        form.estado = 'not_collected';
        dispatch(updateCheck(token, id, form));
    };

    function canceled_check() {
        let data = {'estado': 'canceled', 'observacion': form.observacion};
        dispatch(updateCheck(token, id, data));
    }

    function collected_check() {
        if (payment_date === '') {
            toast.error("Debe seleccionar una fecha de cobro", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        let data = {'estado': 'collected', 'fecha_cobro': payment_date, 'observacion': form.observacion};
        dispatch(updateCheck(token, id, data));
    }

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Cheques"
                            items={[
                                {label: "Lista de cheques", link: "/cheques"},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Actualizar Cheque</h4>
                                </div>
                                <div className="card-body">
                                    <div><ToastContainer/></div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <small>Número de cheque</small>
                                            <h1>{form.numero}</h1>
                                        </div>
                                        <div className="col-md-6 text-right">
                                            <h3>{form.nombre_estado}</h3>
                                        </div>
                                        {(form.estado === 'blank') ? (
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Banco</label>
                                                    <SelectInput
                                                        label="banco"
                                                        name="id_banco"
                                                        value={form.id_banco}
                                                        select={form.banco}
                                                        onChange={handleChange}
                                                        options={banks ? banks.map((item) => ({
                                                            name: item.nombre,
                                                            id: item.id,
                                                        })) : []}
                                                    />
                                                </div>
                                            </div>
                                        ):(
                                            <div className="col-md-6">
                                                <small>Banco</small>
                                                <h5>{form.banco}</h5>
                                            </div>   
                                        )}
                                        {(form.estado === 'blank') ? (
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Sociedad</label>
                                                    <SelectInput
                                                        label="Sociedad"
                                                        name="id_sociedad"
                                                        value={form.id_sociedad}
                                                        select={form.sociedad}
                                                        onChange={handleChangeSocieties}
                                                        options={societies ? societies.map((item) => ({
                                                            name: item.nombre,
                                                            id: item.id,
                                                        })) : []}
                                                    />
                                                </div>
                                            </div>
                                        ):(
                                            <div className="col-md-6">
                                                <small>Sociedad</small>
                                                <h5>{form.sociedad}</h5>
                                            </div>   
                                        )}
                                        {(form.estado === 'blank') ? (
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Tienda</label>
                                                    <SelectInput
                                                        label="tienda"
                                                        id="id_tienda"
                                                        name="id_tienda"
                                                        value={form.id_tienda}
                                                        select={form.tienda}
                                                        onChange={handleChange}
                                                        options={v_stores ? v_stores.map((item) => ({
                                                            name: item.nombre,
                                                            id: item.id,
                                                        })) : []}
                                                    />
                                                </div>
                                            </div>
                                        ):(
                                            <div className="col-md-6">
                                                <small>Tienda</small>
                                                <h5>{form.tienda}</h5>
                                            </div>   
                                        )}    
                                        {(form.estado === 'blank') ? (
                                            <div className="col-md-6">
                                                <Input
                                                    name="beneficiario"
                                                    label="Beneficiario"
                                                    value={form.beneficiario}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        ):(
                                            <div className="col-md-6">
                                                <small>Beneficiario</small>
                                                <h5>{form.beneficiario}</h5>
                                            </div>   
                                        )}    
                                        {(form.estado === 'blank') ? (
                                            <div className="col-md-6">
                                                <Input
                                                    name="fecha_entrega"
                                                    type="date"
                                                    label="Fecha de entrega"
                                                    value={form.fecha_entrega}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        ):(
                                            <div className="col-md-6">
                                                <small>Fecha de entrega</small>
                                                <h5>{form.fecha_entrega}</h5>
                                            </div>   
                                        )}
                                        {(form.estado === 'blank') ? (
                                            <div className="col-md-6">
                                                <Input
                                                    name="monto"
                                                    label="Monto"
                                                    type="number"
                                                    step=".02"
                                                    value={form.monto}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        ):(
                                            <div className="col-md-6">
                                                <small>Monto</small>
                                                <h5>$ {form.monto}</h5>
                                            </div>   
                                        )}
                                        {(form.estado === 'not_collected') ? (
                                            <div className="col-md-6">
                                                <Input
                                                    name="fecha_cobro"
                                                    type="date"
                                                    label="Fecha de cobro"
                                                    value={payment_date}
                                                    onChange={(e) => {setPaymentDate(e.target.value);}}
                                                />
                                            </div>
                                        ):(
                                            (form.estado === 'collected') &&
                                            <div className="col-md-6">
                                                <small>Fecha de cobro</small>
                                                <h5>{form.fecha_cobro}</h5>
                                            </div>   
                                        )}
                                        {(form.estado !== 'canceled') ? (
                                            <div className="col-12 mt-1">
                                                <div className="form-group">
                                                    <TextArea
                                                        label="Observación"
                                                        name="observacion"
                                                        id="observacion"
                                                        onChange={handleChange}
                                                        value={form.observacion}
                                                        rows={5}
                                                        placeholder="Observación"
                                                    />
                                                </div>
                                            </div>
                                        ):(
                                            <div className="col-md-12">
                                                <small>Observación</small>
                                                <h5>$ {form.observacion}</h5>
                                            </div>   
                                        )}
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to="/cheques" className="btn btn-outline-dark m-1">
                                        Cancelar
                                    </Link>
                                    {(form.estado === 'blank') &&
                                        <Button type="submit" text="Guardar" theme="calendar" classes="m-1"/>
                                    }
                                    {(form.estado === 'not_collected' || form.estado === 'collected') &&
                                        <button className="btn btn-calendar mr-1" type="button" onClick={() => {
                                            canceled_check()
                                        }}>Anular
                                        </button>
                                    }
                                    {(form.estado === 'not_collected') &&
                                        <button className="btn btn-calendar mr-1" type="button" onClick={() => {
                                            collected_check()
                                        }}>Aprobar
                                        </button>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default UpdateBank;
