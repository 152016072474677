import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import {
    updateBank,
    clear_banks,
    UPDATE_BANK,
    getAllBanks
} from "../../../redux/banks";
import Footer from "../../../components/footer";

const UpdateBank = () => {
    let history = useHistory();
    const {id} = useParams();

    const dispatch = useDispatch();
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const _get_all_banks = useSelector(
        (state) => state.banks_module._get_all_banks
    );

    const _update_bank = useSelector(
        (state) => state.banks_module._update_bank
    );
    const [form, setForm] = useState({
        nombre: "",
    });

    useEffect(() => {
        if (_get_all_banks.data) {
            let item = _get_all_banks.data.datos.filter((x) => x.id === parseInt(id))
            if (item.length > 0) {
                setForm(item[0]);
            }
        }
    }, [_get_all_banks, id]);

    useEffect(() => {
        if (_update_bank.status === 200) {
            dispatch(clear_banks(UPDATE_BANK));
            history.push("/bancos");
            dispatch(getAllBanks(token));
        } else if (_update_bank.status !== 0) {
            dispatch(clear_banks(UPDATE_BANK));
            toast.error(_update_bank.message, {position: toast.POSITION.TOP_RIGHT});
        }
    }, [_update_bank, dispatch, history, token]);

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        //validate data
        if (form.nombre === "") {
            toast.error("Debe ingresar el nombre", {position: toast.POSITION.TOP_RIGHT});
            return;
        }

        dispatch(updateBank(token, id, form));
    };

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Banco"
                            items={[
                                {label: "Lista de bancos", link: "/bancos"},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Actualizar Banco</h4>
                                </div>
                                <div className="card-body">
                                    <div><ToastContainer/></div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Input
                                                name="nombre"
                                                label="Nombre"
                                                value={form.nombre}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to="/bancos" className="btn btn-outline-dark m-1">
                                        Cancelar
                                    </Link>
                                    <Button type="submit" text="Guardar" theme="calendar" classes="m-1"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default UpdateBank;
