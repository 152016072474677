import React, {useEffect, useRef, useState} from "react";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useDispatch, useSelector} from "react-redux";
import Footer from "../../../components/footer";
import InfiniteScroll from "react-infinite-scroller";
import {searchItemTaskScheduled} from "../../../redux/task_scheduled";
import {DirectionsRenderer, GoogleMap, useJsApiLoader} from "@react-google-maps/api";
import {KEY_MAPS, URLAPI} from "../../../config";
import Modal from "../../../components/modal";
import TextArea from "../../../components/text-area";
import {
    updateNotifications,
    UPDATE_NOTIFICATIONS,
    clear_notification,
    searchNotifications
} from "../../../redux/notifications"
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";
import Search from "../../../components/search";

const NotificationsList = (props) => {
    const dispatch = useDispatch();
    let history = useHistory();
    const {token, user} = useSelector((state) => state.users_module.login.data) || null;
    const _get_all_notifications = useSelector((state) => state.notifications_module._get_all_notifications);
    const [data, setData] = useState([]);
    const [itemData, setItemData] = useState({});

    useEffect(() => {
        if (_get_all_notifications.data) {
            setData(_get_all_notifications.data.datos)
        }
    }, [_get_all_notifications.data]);


    const [modal, setModal] = useState(false);
    const [modalLocations, setModalLocations] = useState(false);


    async function itemClickNav(item) {
        let params = "?id=" + item.tarea.id_task_scheduled + '&&tipo=TAREA';
        setItemData(item);
        const response = await searchItemTaskScheduled(token, params);
        if (response.data.datos.length > 0) {
            setItem(response.data.datos[0])
        }

        setModal(true);

    }

    const [item, setItem] = useState({});
    const originRef = useRef();
    const destiantionRef = useRef();
    const [directionsResponse, setDirectionsResponse] = useState(null);

    let directionsService;
    const calculateRoute = () => {
        directionsService = new window.google.maps.DirectionsService()
        const routesCopy = item.ubicaciones.map(route => {
            return {
                location: {lat: route.lat, lng: route.lng},
                stopover: true
            };
        });

        const origin =
            routesCopy.length === 1
                ? new window.google.maps.LatLng(
                    routesCopy[0].location.lat,
                    routesCopy[0].location.lng
                )
                : routesCopy.shift().location;
        const destination =
            routesCopy.length === 1
                ? new window.google.maps.LatLng(
                    routesCopy[0].location.lat,
                    routesCopy[0].location.lng
                )
                : routesCopy.pop().location;
        const waypoints = routesCopy;

        //call getDirection function
        getDirection(origin, destination, waypoints);
    };

    const getDirection = (origin, destination, waypoints) => {
        waypoints.length >= 1
            ? directionsService.route(
                {
                    origin: origin,
                    destination: destination,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                    waypoints: waypoints
                },
                (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        setDirectionsResponse(result)
                    } else {
                        console.error('error fetching directions1455 ' + result);
                    }
                }
            )
            : directionsService.route(
                {
                    origin: origin,
                    destination: destination,
                    travelMode: window.google.maps.TravelMode.DRIVING
                },
                (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        setDirectionsResponse(result)
                    } else {
                        console.error('error fetching directions ' + result);
                    }
                }
            );
    };

    const {isLoaded} = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: KEY_MAPS,
    });

    function clearRoute() {
        setDirectionsResponse(null);
        originRef.current.value = "";
        destiantionRef.current.value = "";
    }

    const [observationAdmin, setObservationAdmin] = useState(null);

    const handleChange = (e) => {
        setObservationAdmin(e.target.value);
    }

    const _update_notification = useSelector(
        (state) => state.notifications_module._update_notifications
    );

    useEffect(() => {
        if (_update_notification.status === 200) {
            dispatch(clear_notification(UPDATE_NOTIFICATIONS));
            history.push("/notificaciones");
            setModal(false);
            toast.success('Notificación actualizada correctamente', {position: toast.POSITION.TOP_RIGHT});
            setData(_update_notification.data.datos);
        } else if (_update_notification.status !== 0) {
            dispatch(clear_notification(UPDATE_NOTIFICATIONS));
            toast.error(_update_notification.message, {position: toast.POSITION.TOP_RIGHT});
        }
    }, [_update_notification, dispatch, history, token]);

    const handleSubmit = (id, type) => {
        const form = {
            observacion: observationAdmin,
            tipo: type
        }
        dispatch(updateNotifications(token, id, form));
    };

    const handleSearch = async (e) => {
        const params = '?usuario=' + user + '&&buscar=' + e.target.value
        const response = await searchNotifications(token, params);
        setData(response.data.datos)
    }

    return (
        <div className="app-content content">
            <div className="content-overlay"/>
            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Notificaciones"
                            items={[{label: "Notificaciones", link: "/notificaciones/"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="email-app-area mt-2">
                                    <div className="email-app-list-wrapper">
                                        <div className="email-app-list">
                                            <div className="email-user-list list-group">
                                                <ul className="users-list-wrapper media-list"
                                                    // style={{overflowY: 'scroll',}}
                                                >
                                                    <InfiniteScroll
                                                        pageStart={0}
                                                        // loadMore={() => loadFunc()}
                                                        // hasMore={HasMore}
                                                        useWindow={false}

                                                        loader={
                                                            <div
                                                                className="col-12 d-flex justify-content-center">
                                                                <div
                                                                    className="spinner-border text-success"
                                                                    role="status"
                                                                >
                                                                                        <span
                                                                                            className="sr-only">Loading...</span>
                                                                </div>
                                                                {" "}
                                                            </div>
                                                        }
                                                    >
                                                        <div className="mb-1">
                                                            <Search placeholder={"Buscar"} onChange={handleSearch}/>
                                                        </div>
                                                        {data.map((item) => (
                                                            <li className={`list-group-item d-flex justify-content-between align-items-center ${item.check === true ? '' : 'read-notification'}`}
                                                                onClick={() => itemClickNav(item)}
                                                                style={{cursor: 'pointer'}}
                                                            >
                                                                <div
                                                                    className="d-flex align-items-center">
                                                                    <div className="ml-1">
                                                                        <span className="fw-bold mb-0">Tarea</span><br/>
                                                                        <strong
                                                                            className="text-muted mb-0">{item.tarea.tienda}</strong>
                                                                    </div>
                                                                    <div className="ml-3">
                                                                        <strong
                                                                            className="fw-bold mb-0">{item.tarea.nombre}</strong><br/>
                                                                        <span
                                                                            className="text-muted mb-0">{item.tarea.fecha}</span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </InfiniteScroll>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal className="modal-main" show={modal} style={{width: "40%"}}>
                    <div className="modal-header">
                        <h5 className="text-bold-400">
                            {(item && item.tipo !== 'TAREA') &&
                                <>{item.descripcion}</>
                            }
                        </h5>
                        <button type="button" style={{marginRight: -24}}
                                className="btn btn-calendar badge-circle" onClick={() => {
                            setModal(!modal);
                            setItem({})
                        }}> X
                        </button>
                    </div>
                    <div className="card-body">
                        <div className="row mb-1">
                            {(item && item.tipo === 'TAREA') &&
                                <>
                                    <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                        <div style={{marginTop: 5, marginBottom: 5}}>
                                            <strong>{item.tarea}</strong>
                                        </div>
                                        <span>{item.lista_tarea}</span><br/>
                                        <span>{(item.realizado === 1) ? 'Realizada el ' + item.fecha : 'Fecha: ' + item.fecha}</span>
                                        {(item.ubicaciones.length > 0) &&
                                            <div className="d-flex">
                                                <img
                                                    src={window.location.origin + "/base/app-assets/images/logo/geolocation.png"}
                                                    alt="" height="20" width="20"
                                                    className="rounded-circle mr-1"/>
                                                {/*eslint-disable-next-line*/}
                                                <a href="#" onClick={() => {
                                                    setModalLocations(true);
                                                    calculateRoute();
                                                }}>Ver ubicación</a>
                                            </div>
                                        }
                                    </div>
                                    <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                        <div style={{marginTop: 5, marginBottom: 5}}>
                                            <strong>{item.tienda}</strong>
                                        </div>
                                        <span>{item.observacion_inicial}</span>
                                    </div>
                                    <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                        <div style={{marginTop: 5, marginBottom: 5}}>
                                            <strong>Recursos de la tarea</strong>
                                        </div>
                                        {item.recursos.map((re) => (
                                            <div className="d-flex">
                                                <img
                                                    src={window.location.origin + "/base/app-assets/images/logo/download.png"}
                                                    alt="" height="20" width="20"
                                                    className="rounded-circle mr-1"/>
                                                {/*eslint-disable-next-line*/}
                                                <a href={URLAPI + re.archivo} target="_blank">{re.nombre}</a>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                        <div style={{marginTop: 5, marginBottom: 5}}>
                                            <strong>Resultados</strong>
                                        </div>
                                        {item.resultados.map((re) => (
                                            <div className="d-flex">
                                                <img
                                                    src={window.location.origin + "/base/app-assets/images/logo/download.png"}
                                                    alt="" height="20" width="20"
                                                    className="rounded-circle mr-1"/>
                                                {/*eslint-disable-next-line*/}
                                                <a href={URLAPI + re.archivo} target="_blank">{re.nombre}</a>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                        <div style={{marginTop: 5, marginBottom: 5}}>
                                            <strong>Fotografías de evidencia</strong>
                                        </div>
                                        <div className="row mb-1">
                                            {item.imagenes.map((re) => (
                                                <div className="col-4 text-center">
                                                    {/*eslint-disable-next-line*/}
                                                    <a href={URLAPI + re.archivo} target="_blank">
                                                        <img
                                                            src={URLAPI + re.archivo}
                                                            alt="" height="80" width="100"/>
                                                    </a>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                        <div style={{marginTop: 5, marginBottom: 5}}>
                                            <strong>Observaciones finales</strong>
                                        </div>
                                        <span>{item.observacion_final}</span>
                                    </div>
                                    {itemData.check === false &&
                                        <div className="col-12 mb-1 pt-1" style={{background: '#F4F4F4'}}>
                                            <TextArea
                                                label="Observaciones auditoría"
                                                name="observationAdmin"
                                                id="observationAdmin"
                                                onChange={handleChange}
                                                value={observationAdmin}
                                                rows={5}
                                                placeholder="Observaciones auditoría"
                                            />
                                        </div>
                                    }
                                </>
                            }
                        </div>
                        {itemData.check === false &&
                            <div className="text-center">
                                <button className="btn btn-calendar mr-5" onClick={() => {
                                    handleSubmit(itemData.id, 1)
                                }}>Omitir
                                </button>
                                <button className="btn btn-calendar" onClick={() => {
                                    handleSubmit(itemData.id, 2)
                                }}>Notificar a zonal
                                </button>
                            </div>
                        }
                    </div>
                </Modal>
                <Modal className="modal-main" show={modalLocations} style={{width: "40%"}}>
                    <div>
                        <button type="button" className="close btn btn-calendar badge-circle" onClick={() => {
                            setModalLocations(!modalLocations);
                            clearRoute();
                        }}> X
                        </button>
                    </div>
                    <div className="card-body">
                        <div className="row mb-1">
                            {(isLoaded && item && item.ubicaciones) &&
                                <GoogleMap
                                    center={item.ubicaciones[0]}
                                    zoom={11}
                                    options={{streetViewControl: false}}
                                    mapContainerStyle={{width: "100%", height: "50vh"}}
                                >
                                    {directionsResponse && (
                                        <DirectionsRenderer directions={directionsResponse}/>
                                    )}
                                </GoogleMap>
                            }
                        </div>
                    </div>
                </Modal>
            </div>
            <Footer/>
        </div>
    );
};

export default NotificationsList;
