import React, {useEffect, useState} from "react";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useDispatch, useSelector} from "react-redux";
import {ToastContainer, toast} from "react-toastify";
import Button from "../../../components/button";
import Footer from "../../../components/footer";
import InfiniteScroll from "react-infinite-scroller";
import ItemList1 from "../../../components/item-list-1";
import Modal from "../../../components/modal";
import {DirectionsRenderer, GoogleMap, useJsApiLoader, MarkerF} from "@react-google-maps/api";
import {KEY_MAPS, URLAPI} from "../../../config";
import {
    searchItemTaskScheduled,
    searchTaskScheduled,
    getLogHistoryTaskScheduled,
    get_report,
    getAllTaskScheduled,
    clear_task_scheduled,
    GET_REPORT
} from "../../../redux/task_scheduled"
import Input from "../../../components/input";
import SelectInput from "../../../components/select";
import LoadingSpinner from "../../../components/loading-spinner";

const TaskScheduled = (props) => {
    const dispatch = useDispatch();
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const task_scheduled = useSelector(
        (state) => state.task_scheduled_module._get_all_task_scheduled
    );
    const [data, setData] = useState([]);
    const [item, setItem] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [n_page, setNPage] = useState(0);
    // const [permisos, setPermisos] = useState([]);

    // useEffect(() => {
    //     if (props) {
    //         const array = Object.values(props);
    //         setPermisos(array);
    //     }
    // }, [props]);

    useEffect(() => {
        if (task_scheduled.data.datos) {
            setData(task_scheduled.data.datos);
            setNPage(task_scheduled.data.n_page);
        }
    }, [task_scheduled]);

    const [page, setPage] = useState(1);

    function more_data() {
        let count = page + 1;
        setPage(count)
        dispatch(getAllTaskScheduled(token, count));
    }


    function less_data() {
        let count = page - 1;
        setPage(count)
        dispatch(getAllTaskScheduled(token, count));
    }

    // useEffect(() => {
    //     if (permisos.filter(x => x.codigo === 'view_taskscheduled').length > 0) {
    //         setInterval(function () {
    //             let param = "?"
    //             if (form.fecha_inicio && form.fecha_fin) {
    //                 param += "fecha_inicio=" + form.fecha_inicio + "&&fecha_fin=" + form.fecha_fin
    //             }
    //             if (form.tarea) {
    //                 param += "&&tarea=" + form.tarea
    //             }
    //             if (form.tienda) {
    //                 param += "&&tienda=" + form.tienda
    //             }
    //             if (form.usuario) {
    //                 param += "&&usuario=" + form.usuario
    //             }
    //
    //             return new Promise((resolve, reject) => {
    //                 searchTaskScheduled(token, param).then((res) => {
    //                     setData(res.data.datos)
    //                 });
    //             });
    //         }, 60000);
    //     }
    //     // eslint-disable-next-line
    // }, [token, permisos]);

    const [modal, setModal] = useState(false);
    const [modalLocations, setModalLocations] = useState(false);
    const [modalFilters, setModalFilters] = useState(false);

    const [showSibarLeft, setShowSibarLeft] = useState(false);
    const [showSibarRight, setShowSibarRight] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);
    // const [report, setReport] = useState(false);

    const [count, setCount] = useState(0);
    const [HasMore, setHasMore] = useState(true);

    const loadFunc = () => {
        if (task_scheduled.data.datos) {
            if (task_scheduled.data.datos.length > count) {
                const ordenado = task_scheduled.data.datos.sort((a, b) => {
                    return new Date(b.fecha) - new Date(a.fecha);
                });
                setData([...data, ...ordenado.slice(count, count + 10)]);
                setCount(count + 10);
            }
            setHasMore(false);
        }
    };

    const [dataHistory, setDataHistory] = useState([]);

    const onItemClickListener = async (item) => {
        setIsLoading(true);
        let params = "?id=" + item.id + '&&tipo=' + item.tipo
        const response = await searchItemTaskScheduled(token, params)
        console.log('response.data.datos')
        console.log(response)

        if (response.data.datos.length > 0) {
            if (item.tipo === 'TAREA') {
                setItem(response.data.datos[0]);
            } else {
                let filter = response.data.datos.filter((x) => x.tipo_actividad === item.tipo_actividad);
                setItem(filter[0]);
            }
            if (response.data.datos[0].tipo === 'TAREA') {
                let result = "?id_tarea_programada=" + response.data.datos[0].id_tarea_horario;
                const respo = await getLogHistoryTaskScheduled(token, result);
                if (respo && respo.data && respo.data.cantidad > 0) {
                    setDataHistory(respo.data.datos);
                }
            }
            setModal(true);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }

    };

    const [directionsResponse, setDirectionsResponse] = useState(null);
    let directionsService;

    const calculateRoute = map => {
        directionsService = new window.google.maps.DirectionsService();
        const routesCopy = item.ubicaciones.map(route => {
            return {
                location: {lat: route.lat, lng: route.lng},
                stopover: true
            };
        });

        const origin =
            routesCopy.length === 1
                ? new window.google.maps.LatLng(
                    routesCopy[0].location.lat,
                    routesCopy[0].location.lng
                )
                : routesCopy.shift().location;
        const destination =
            routesCopy.length === 1
                ? new window.google.maps.LatLng(
                    routesCopy[0].location.lat,
                    routesCopy[0].location.lng
                )
                : routesCopy.pop().location;
        const waypoints = routesCopy;
        //call getDirection function
        getDirection(origin, destination, waypoints);
    };

    const getDirection = (origin, destination, waypoints) => {
        waypoints.length >= 1
            ? directionsService.route(
                {
                    origin: origin,
                    destination: destination,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                    waypoints: waypoints
                },
                (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        setDirectionsResponse(result)
                    } else {
                        console.log('error fetching directions1455 ' + result);
                    }
                }
            )
            : directionsService.route(
                {
                    origin: origin,
                    destination: destination,
                    travelMode: window.google.maps.TravelMode.DRIVING
                },
                (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        setDirectionsResponse(result)
                    } else {
                        console.log('error fetching directions ' + result);
                    }
                }
            );
    };

    function clearRoute() {
        setDirectionsResponse(null);
    }

    const {isLoaded} = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: KEY_MAPS,
    });

    const [filtros, setFiltros] = useState({
        fecha: false,
        tarea: true,
        tienda: false,
        usuario: false
    });

    const [form, setForm] = useState({
        fecha_inicio: '',
        fecha_fin: '',
        tarea: '',
        tienda: '',
        usuario: ''
    });

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    }

    const changeFilter = (e) => {
        if (e.target.value === '0') {
            setFiltros({
                fecha: false,
                tarea: false,
                tienda: false,
                usuario: false
            })
        }
        if (e.target.value === '1') {
            setFiltros({
                fecha: true,
                tarea: false,
                tienda: false,
                usuario: false
            })
        }
        if (e.target.value === '2') {
            setFiltros({
                fecha: false,
                tarea: true,
                tienda: false,
                usuario: false
            })
        }
        if (e.target.value === '3') {
            setFiltros({
                fecha: false,
                tarea: false,
                tienda: true,
                usuario: false
            })
        }
        if (e.target.value === '4') {
            setFiltros({
                fecha: false,
                tarea: false,
                tienda: false,
                usuario: true
            })
        }
    }

    const filter_options = [
        {name: "Fecha", id: 1},
        {name: "Tarea", id: 2},
        {name: "Tienda", id: 3},
        {name: "Usuario", id: 4},
    ];

    const add_filter = () => {
        setModalFilters(false);
        let param_filter = "?"
        if (form.fecha_inicio && form.fecha_fin) {
            param_filter += "fecha_inicio=" + form.fecha_inicio + "&&fecha_fin=" + form.fecha_fin
        }
        if (form.tarea) {
            param_filter += "&&tarea=" + form.tarea
        }
        if (form.tienda) {
            param_filter += "&&tienda=" + form.tienda
        }
        if (form.usuario) {
            param_filter += "&&usuario=" + form.usuario
        }

        return new Promise((resolve, reject) => {
            searchTaskScheduled(token, param_filter).then((res) => {
                setData(res.data.datos)
            });
        });
    }

    const _get_report = useSelector((store) => store.task_scheduled_module._get_report);

    useEffect(() => {
        if (_get_report.status === 200) {
            toast.success(_get_report.data.mensaje);
            dispatch(clear_task_scheduled(GET_REPORT));
            setIsLoading(false);
        } else if (_get_report.status !== 0) {
            toast.error(_get_report.message);
            dispatch(clear_task_scheduled(GET_REPORT));
            setIsLoading(false);
        }
    }, [_get_report, dispatch]);

    const handleGenerateReport = () => {
        setIsLoading(true);
        let param = "?"
        if (form.fecha_inicio && form.fecha_fin) {
            param += "fecha_inicio=" + form.fecha_inicio + "&&fecha_fin=" + form.fecha_fin;
        }
        if (form.tarea) {
            param += "&&tarea=" + form.tarea;
        }
        if (form.tienda) {
            param += "&&tienda=" + form.tienda;
        }
        if (form.usuario) {
            // eslint-disable-next-line
            param += "&&usuario=" + form.tarea;
        }

        dispatch(get_report(form, token));
    }

    const delete_filter = (value) => {
        let param = "?"

        if (value === 'fecha') {
            setForm({...form, fecha_inicio: '', fecha_fin: ''})
            param += "&&tarea=" + form.tarea + "&&tienda=" + form.tienda + "&&usuario=" + form.usuario
        }

        if (value === 'tarea') {
            setForm({...form, tarea: ''})
            param += "fecha_inicio=" + form.fecha_inicio + "&&fecha_fin=" + form.fecha_fin + "&&tienda=" + form.tienda + "&&usuario=" + form.usuario
        }
        if (value === 'tienda') {
            setForm({...form, tienda: ''})
            param += "fecha_inicio=" + form.fecha_inicio + "&&fecha_fin=" + form.fecha_fin + "&&tarea=" + form.tarea + "&&usuario=" + form.usuario
        }
        if (value === 'usuario') {
            setForm({...form, usuario: ''})
            param += "fecha_inicio=" + form.fecha_inicio + "&&fecha_fin=" + form.fecha_fin + "&&tarea=" + form.tarea + "&&tienda=" + form.tienda
        }

        return new Promise((resolve, reject) => {
            searchTaskScheduled(token, param).then((res) => {
                setData(res.data.datos)
            });
        });
    }

    return (
        <>
            <div className="app-content content">
                {isLoading && <LoadingSpinner/>}
                <ToastContainer/>
                <div className="content-wrapper">
                    <div className="content-header row">
                        <div className="content-header-left col-12 mb-2 ">
                            <Breadcrumbs
                                title="Línea de tiempo"
                                items={[{label: "Consulta de actividades", link: "/"}]}
                            />
                        </div>
                    </div>
                    <div className="content-body">
                        <div className="content-area-wrapper" style={{margin: 0}}>
                            <div className={`sidebar-left ${showSibarLeft ? 'show' : ''}`}>
                                <div className="sidebar">
                                    <div className="sidebar-content email-app-sidebar d-flex">
                                        <span className="sidebar-close-icon" onClick={
                                            () => {
                                                setShowSibarLeft(false)
                                                setShowOverlay(false)
                                            }
                                        }>
                                            <i className="bx bx-x"/>
                                        </span>
                                        <div className="email-app-menu">
                                            <div className="form-group form-group-compose">
                                                <div className="sidebar-menu-list mt-1">
                                                    <div className="list-group list-group-messages">
                                                        <Button
                                                            text="Agregar filtro"
                                                            theme="calendar"
                                                            type="button"
                                                            classes="btn-lg"
                                                            onClick={() => setModalFilters(true)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="sidebar-menu-list">
                                                {/* sidebar menu  */}
                                                <div className="list-group list-group-messages">
                                                    <ul className="list-unstyled mb-0">
                                                        {(form.fecha_inicio && form.fecha_fin) &&
                                                            <>
                                                                <li className="list-group-item d-flex justify-content-between align-items-center"
                                                                    style={{background: '#FFC912', marginBottom: 5}}>
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="ms-5 ml-1">
                                                                            <span
                                                                                className="text-bold-500">Desde {form.fecha_inicio}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="text-right">
                                                                        {/*eslint-disable-next-line*/}
                                                                        <a href="#" onClick={() => {
                                                                            delete_filter('fecha')
                                                                        }} className="btn-calendar" style={{
                                                                            paddingLeft: 10,
                                                                            paddingRight: 10,
                                                                            paddingTop: 5,
                                                                            paddingBottom: 5,
                                                                            borderRadius: 50,
                                                                            marginRight: 5
                                                                        }}>x</a>
                                                                    </div>
                                                                </li>
                                                                <li className="list-group-item d-flex justify-content-between align-items-center"
                                                                    style={{background: '#FFC912', marginBottom: 5}}>
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="ms-5 ml-1">
                                                                            <span
                                                                                className="text-bold-500">Hasta {form.fecha_fin}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="text-right">
                                                                        {/*eslint-disable-next-line*/}
                                                                        <a href="#" onClick={() => {
                                                                            delete_filter('fecha')
                                                                        }} className="btn-calendar" style={{
                                                                            paddingLeft: 10,
                                                                            paddingRight: 10,
                                                                            paddingTop: 5,
                                                                            paddingBottom: 5,
                                                                            borderRadius: 50,
                                                                            marginRight: 5
                                                                        }}>x</a>
                                                                    </div>
                                                                </li>
                                                            </>

                                                        }
                                                        {(form.tarea) &&
                                                            <li className="list-group-item d-flex justify-content-between align-items-center"
                                                                style={{background: '#FFC912', marginBottom: 5}}>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="ms-5 ml-1">
                                                                        <span
                                                                            className="text-bold-500">{form.tarea}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="text-right">
                                                                    {/*eslint-disable-next-line*/}
                                                                    <a href="#" onClick={() => {
                                                                        delete_filter('tarea')
                                                                    }} className="btn-calendar" style={{
                                                                        paddingLeft: 10,
                                                                        paddingRight: 10,
                                                                        paddingTop: 5,
                                                                        paddingBottom: 5,
                                                                        borderRadius: 50,
                                                                        marginRight: 5
                                                                    }}>x</a>
                                                                </div>
                                                            </li>
                                                        }
                                                        {(form.tienda) &&
                                                            <li className="list-group-item d-flex justify-content-between align-items-center"
                                                                style={{background: '#FFC912', marginBottom: 5}}>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="ms-5 ml-1">
                                                                        <span
                                                                            className="text-bold-500">Tienda {form.tienda}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="text-right">
                                                                    {/*eslint-disable-next-line*/}
                                                                    <a href="#" onClick={() => {
                                                                        delete_filter('tienda')
                                                                    }} className="btn-calendar" style={{
                                                                        paddingLeft: 10,
                                                                        paddingRight: 10,
                                                                        paddingTop: 5,
                                                                        paddingBottom: 5,
                                                                        borderRadius: 50,
                                                                        marginRight: 5
                                                                    }}>x</a>
                                                                </div>
                                                            </li>
                                                        }
                                                        {(form.usuario) &&
                                                            <li className="list-group-item d-flex justify-content-between align-items-center"
                                                                style={{background: '#FFC912', marginBottom: 5}}>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="ms-5 ml-1">
                                                                        <span
                                                                            className="text-bold-500">{form.usuario}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="text-right">
                                                                    {/*eslint-disable-next-line*/}
                                                                    <a href="#" onClick={() => {
                                                                        delete_filter('usuario')
                                                                    }} className="btn-calendar" style={{
                                                                        paddingLeft: 10,
                                                                        paddingRight: 10,
                                                                        paddingTop: 5,
                                                                        paddingBottom: 5,
                                                                        borderRadius: 50,
                                                                        marginRight: 5
                                                                    }}>x</a>
                                                                </div>
                                                            </li>
                                                        }
                                                    </ul>
                                                </div>
                                                {/* sidebar label end */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content-right">
                                <div className="content-overlay"/>
                                <div className="content-wrapper">
                                    <div className="content-header row"/>
                                    <div className="content-body">
                                        <div className={`app-content-overlay ${showOverlay ? 'show' : ''}`}
                                             onClick={
                                                 () => {
                                                     setShowSibarRight(!showSibarRight);
                                                     setShowSibarLeft(false);
                                                     setShowOverlay(!showOverlay);
                                                 }
                                             }
                                        />
                                        <div className="email-app-area">
                                            {/* Email list Area */}
                                            <div className="email-app-list-wrapper">
                                                <div className="email-app-list">
                                                    <div className="email-action">
                                                        <div
                                                            className="action-right d-flex flex-grow-1 align-items-center justify-content-around">
                                                            <div className="email-fixed-search flex-grow-1">
                                                                <div className="sidebar-toggle d-block d-lg-none"
                                                                     onClick={
                                                                         () => {
                                                                             setShowSibarLeft(!showSibarLeft);
                                                                             setShowOverlay(!showOverlay);
                                                                         }
                                                                     }>
                                                                    <i className="bx bx-menu"/>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12 text-right">
                                                                        <Button
                                                                            text="Exportar"
                                                                            theme="calendar"
                                                                            type="button"
                                                                            classes="btn-lg"
                                                                            onClick={() => {handleGenerateReport();}}
                                                                        />
                                                                        {/* {!report ?
                                                                            <Button
                                                                                text="Exportar"
                                                                                theme="calendar"
                                                                                type="button"
                                                                                classes="btn-lg"
                                                                                onClick={() => handleGenerateReport()}
                                                                            /> :
                                                                            <div className="spinner-border text-success"
                                                                                 role="status">
                                                                                <span
                                                                                    className="sr-only">Loading...</span>
                                                                            </div>
                                                                        } */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{marginTop: 10, marginBottom: 5}}>
                                                        <div className="col-4">
                                                            <label className="ml-1">Página {page}</label>
                                                        </div>
                                                        <div className="col-8 text-right">
                                                            {(page > 1 && page <= n_page) && 
                                                                <button className="btn btn-primary mr-1" type="button" onClick={() => {less_data();}}><i class="bx bx-arrow-back"></i></button>
                                                            }
                                                            {(page >= 1 && page < n_page) && 
                                                                <button className="btn btn-success" type="button" onClick={() => {more_data();}}><i class="bx bx-right-arrow-alt"></i></button>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="email-user-list list-group">
                                                        <ul className="users-list-wrapper media-list" style={{
                                                            overflowY: 'scroll',
                                                        }}>
                                                            <InfiniteScroll
                                                                pageStart={0}
                                                                loadMore={() => loadFunc()}
                                                                hasMore={HasMore}
                                                                useWindow={false}

                                                                loader={
                                                                    <div
                                                                        className="col-12 d-flex justify-content-center">
                                                                        <div
                                                                            className="spinner-border text-success"
                                                                            role="status"
                                                                        >
                                                                            <span className="sr-only">Loading...</span>
                                                                        </div>
                                                                        {" "}
                                                                    </div>
                                                                }
                                                            >
                                                                {data.map((item) => (
                                                                    <ItemList1
                                                                        item={item}
                                                                        title={item.nombres + ' ' + item.apellidos}
                                                                        subtitle={(item.tipo && item.tipo === 'TIENDA') ? <>
                                                                            <span>{item.descripcion}: </span>
                                                                            <strong>{item.tienda}</strong></> : (item.tipo && item.tipo === 'TAREA') ? <>
                                                                            <span>{item.descripcion}: </span><strong>{item.tarea}</strong></> : <>
                                                                            <span>{item.descripcion}: </span><strong>{item.tipo_actividad}</strong></>}
                                                                        photo={item.foto}
                                                                        rightDescription={item.fecha}
                                                                        rightStore={(item.tienda) ? item.tienda : ''}
                                                                        onClick={onItemClickListener}
                                                                    />
                                                                ))}
                                                            </InfiniteScroll>
                                                        </ul>
                                                        {/* email user list end */}

                                                        {/* no result when nothing to show on list */}
                                                        <div className="no-results">
                                                            <i className="bx bx-error-circle font-large-2"/>
                                                            <h5>No Items Found</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal className="modal-main" show={modal} style={{width: "40%"}}>
                        <div className="modal-header">
                            <h5 className="text-bold-400">{(item && item.tipo !== 'TAREA') &&
                                <>{item.descripcion}</>
                            }</h5>
                            <button type="button" style={{marginRight: -24}}
                                    className="btn btn-calendar badge-circle" onClick={() => {
                                setModal(!modal);
                                setItem({})
                            }}> X
                            </button>
                        </div>
                        <div className="card-body">
                            <div className="row mb-1">
                                <p>{item.tipo}</p>
                                {(item && item.tipo === 'TAREA') ?
                                    <>
                                        <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                            <div style={{marginTop: 5, marginBottom: 5}}>
                                                <strong>{item.tarea}</strong>
                                            </div>
                                            <span>{item.lista_tarea}</span><br/>
                                            <span>{(item.realizado === 1) ? 'Realizada el ' + item.fecha : 'Fecha: ' + item.fecha}</span>
                                            {(item.ubicaciones.length > 0) &&
                                                <div className="d-flex">
                                                    <img
                                                        src={window.location.origin + "/base/app-assets/images/logo/geolocation.png"}
                                                        alt="" height="20" width="20"
                                                        className="rounded-circle mr-1"/>
                                                    {/*eslint-disable-next-line*/}
                                                    <a href="#" onClick={() => {
                                                        setModalLocations(true);
                                                        calculateRoute();
                                                    }}
                                                    >Ver ubicación</a>
                                                </div>
                                            }
                                        </div>
                                        <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                            <div style={{marginTop: 5, marginBottom: 5}}>
                                                <strong>{item.tienda}</strong>
                                            </div>
                                            <span>{item.observacion_inicial}</span>
                                        </div>
                                        <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                            <div style={{marginTop: 5, marginBottom: 5}}>
                                                <strong>Recursos de la tarea</strong>
                                            </div>
                                            {item.recursos.map((re) => (
                                                <div className="d-flex">
                                                    <img
                                                        src={window.location.origin + "/base/app-assets/images/logo/download.png"}
                                                        alt="" height="20" width="20"
                                                        className="rounded-circle mr-1"/>
                                                    {/*eslint-disable-next-line*/}
                                                    <a href={URLAPI + re.archivo} target="_blank">{re.nombre}</a>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                            <div style={{marginTop: 5, marginBottom: 5}}>
                                                <strong>Resultados</strong>
                                            </div>
                                            {item.resultados.map((re) => (
                                                re.nombre &&
                                                    <div className="d-flex">
                                                        <img
                                                            src={window.location.origin + "/base/app-assets/images/logo/download.png"}
                                                            alt="" height="20" width="20"
                                                            className="rounded-circle mr-1"/>
                                                        {/*eslint-disable-next-line*/}
                                                        <a href={URLAPI + re.archivo} target="_blank">{re.nombre}</a>
                                                    </div>
                                            ))}
                                        </div>
                                        <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                            <div style={{marginTop: 5, marginBottom: 5}}>
                                                <strong>Fotografías de evidencia</strong>
                                            </div>
                                            <div className="row mb-1">
                                                {item.imagenes.map((re) => (
                                                    <div className="col-4 text-center">
                                                        {/*eslint-disable-next-line*/}
                                                        <a href={URLAPI + re.archivo} target="_blank">
                                                            <img
                                                                src={URLAPI + re.archivo}
                                                                alt="" height="80" width="100"/>
                                                        </a>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                            <div style={{marginTop: 5, marginBottom: 5}}>
                                                <strong>Observaciones finales</strong>
                                            </div>
                                            {item.observacion && 
                                                <div style={{marginBottom: 10}}>
                                                    <span>{item.observacion}</span><br/>
                                                </div>
                                            }
                                            <div style={{marginBottom: 10}}>
                                                <span>{item.observacion_final}</span><br/>
                                            </div>
                                        </div>
                                        {(dataHistory.length > 0) && (
                                            <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                                <div style={{marginTop: 5, marginBottom: 5}}>
                                                    <strong>Historias</strong>
                                                </div>
                                                <div className="mb-1">
                                                        {dataHistory.map((item) => (
                                                            item.logs.map((x) => (
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <div><span>{x.nombre_tipo}</span></div>
                                                                    <div className="text-right"><span className='text-right'>{x.fecha}</span></div>
                                                                </div>
                                                            ))
                                                        ))}
                                                </div>
                                            </div>
                                        )}
                                    </>
                                    :
                                    <>
                                        <div className="col-12 mb-1">
                                            <span>Fecha y hora: {item.fecha}</span>
                                        </div>
                                        <div className="col-12 mb-1">
                                            <div className="d-flex">
                                                <img
                                                    src={window.location.origin + "/base/app-assets/images/logo/geolocation.png"}
                                                    alt="" height="20" width="20"
                                                    className="rounded-circle mr-1"/>
                                                <span>Ubicación</span>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-1 text-center">
                                            {(item && item.latitud && item.longitud && isLoaded) &&
                                                <GoogleMap
                                                    center={{
                                                        lat: parseFloat(item.latitud),
                                                        lng: parseFloat(item.longitud)
                                                    }}
                                                    zoom={11}
                                                    options={{streetViewControl: false}}
                                                    mapContainerStyle={{width: "100%", height: "50vh"}}
                                                >
                                                    <MarkerF
                                                        position={{
                                                            lat: parseFloat(item.latitud),
                                                            lng: parseFloat(item.longitud)
                                                        }}
                                                    />
                                                </GoogleMap>
                                            }
                                        </div>
                                        {item.imagen &&
                                            <>
                                                <div className="col-12 mb-1">
                                                    <div className="d-flex">
                                                        <img
                                                            src={window.location.origin + "/base/app-assets/images/logo/image.png"}
                                                            alt="" height="20" width="20"
                                                            className="rounded-circle mr-1"/>
                                                        <span>Foto</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 mb-1 text-center">
                                                    {/*eslint-disable-next-line*/}
                                                    <a href={URLAPI + item.imagen} target="_blank">
                                                        <img
                                                            src={item.imagen ? URLAPI + item.imagen : window.location.origin + "/base/app-assets/images/logo/image.png"}
                                                            alt="" height="180" width="50%"/>
                                                    </a>
                                                </div>
                                            </>
                                        }
                                    </>

                                }
                            </div>
                        </div>
                    </Modal>

                    <Modal className="modal-main" show={modalLocations} style={{width: "40%"}}>
                        <div>
                            <button type="button" className="close btn btn-calendar badge-circle" onClick={() => {
                                setModalLocations(!modalLocations);
                                clearRoute();
                            }}> X
                            </button>
                        </div>
                        <div className="card-body">
                            <div className="row mb-1">
                                {(isLoaded && item && item.ubicaciones) &&
                                    <GoogleMap
                                        center={item.ubicaciones[0]}
                                        zoom={11}
                                        options={{streetViewControl: false}}
                                        mapContainerStyle={{width: "100%", height: "50vh"}}
                                    >
                                        {/*{item.ubicaciones.map((co) => (*/}
                                        {/*    <MarkerF*/}
                                        {/*        position={co}*/}
                                        {/*    />*/}
                                        {/*))}*/}
                                        {directionsResponse && (
                                            <DirectionsRenderer directions={directionsResponse}/>
                                        )}

                                    </GoogleMap>
                                }
                            </div>
                        </div>
                    </Modal>

                    <Modal className="modal-main" show={modalFilters} style={{width: "40%"}}>
                        <div className="modal-header">
                            <h5 className="text-bold-400">Agregar filtro</h5>
                            <button type="button" style={{marginRight: -24}}
                                    className="btn btn-calendar badge-circle" onClick={() => {
                                setModalFilters(!modalFilters);
                            }}> X
                            </button>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <SelectInput
                                        id="filtro"
                                        name="filtro"
                                        options={filter_options}
                                        onChange={changeFilter}
                                    />
                                </div>
                                {
                                    filtros.fecha && <>
                                        <div className="col-12">
                                            <Input
                                                type="date"
                                                label="Fecha Inicio"
                                                onChange={handleChange}
                                                name='fecha_inicio'
                                                id='fecha_inicio'
                                            />
                                            <Input
                                                type="date"
                                                label="Fecha Fin"
                                                onChange={handleChange}
                                                name='fecha_fin'
                                                id='fecha_fin'
                                            />
                                        </div>
                                    </>
                                }

                                {
                                    filtros.tarea &&
                                    <div className="col-12">
                                        <Input
                                            type="text"
                                            name="tarea"
                                            id="tarea"
                                            label="Tarea"
                                            onChange={handleChange}
                                            placeholder="Tarea"
                                            value={form.tarea}
                                        />
                                    </div>
                                }
                                {
                                    filtros.tienda &&
                                    <div className="col-12">
                                        <Input
                                            type="text"
                                            name="tienda"
                                            id="tienda"
                                            label="Tienda"
                                            onChange={handleChange}
                                            placeholder="Tienda"
                                            value={form.tienda}
                                        />
                                    </div>
                                }
                                {
                                    filtros.usuario &&
                                    <div className="col-12">
                                        <Input
                                            type="text"
                                            name="usuario"
                                            id="usuario"
                                            label="Usuario"
                                            onChange={handleChange}
                                            placeholder="Usuario"
                                            value={form.usuario}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="card-footer text-center">
                            <button type="button" className="btn btn-calendar" onClick={() => {
                                add_filter()
                            }}>Continuar
                            </button>
                        </div>
                    </Modal>
                </div>
            </div>
            <div className="app-content content">
                <Footer/>
            </div>
        </>
    );
};

export default TaskScheduled;
