import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import {
    updateCity,
    clear_cities,
    UPDATE_CITY,
    getAllCities
} from "../../../redux/cities";
import SelectInput from "../../../components/select";
import Footer from "../../../components/footer";

const UpdateCity = () => {
    let history = useHistory();
    const {id} = useParams();

    const dispatch = useDispatch();
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const _get_all_cities = useSelector(
        (state) => state.cities_module._get_all_cities
    );

    const _get_all_provinces = useSelector(
        (state) => state.provinces_module._get_all_provinces
    );

    const [provinces, setProvinces] = useState([]);

    useEffect(() => {
        if (_get_all_provinces.data.datos) {
            setProvinces(_get_all_provinces.data.datos);
        }
    }, [_get_all_provinces]);

    const _update_city = useSelector(
        (state) => state.cities_module._update_city
    );
    const [form, setForm] = useState({
        nombre: "",
        id_provincia: 0,
        provincia: "",
    });

    useEffect(() => {
        if (_get_all_cities.data.datos) {
            let item = _get_all_cities.data.datos.filter((x) => x.id === parseInt(id))
            if (item.length > 0) {
                setForm(item[0]);
            }
        }
    }, [_get_all_cities, id]);

    useEffect(() => {
        if (_update_city.status === 200) {
            dispatch(clear_cities(UPDATE_CITY));
            history.push("/ciudades");
            dispatch(getAllCities(token));
        } else if (_update_city.status !== 0) {
            dispatch(clear_cities(UPDATE_CITY));
            toast.error(_update_city.message, {position: toast.POSITION.TOP_RIGHT});
        }
    }, [_update_city, dispatch, history, token]);

    const handleChange = (e) => {
        if (e.target.name === "id_provincia") {
            setForm({
                ...form,
                [e.target.name]: parseInt(e.target.value),
            });
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        //validate data
        if (form.nombre === "") {
            toast.error("Debe ingresar el nombre", {position: toast.POSITION.TOP_RIGHT});
            return;
        }

        if (form.id_provincia === 0) {
            toast.error("Debe seleccionar una provincia", {position: toast.POSITION.TOP_RIGHT});
            return;
        }

        dispatch(updateCity(token, id, form));
    };

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Ciudad"
                            items={[
                                {label: "Lista de ciudades", link: "/ciudades"},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Actualizar Ciudad</h4>
                                </div>
                                <div className="card-body">
                                    <div><ToastContainer/></div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input
                                                name="nombre"
                                                label="Nombre"
                                                value={form.nombre}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Provincia</label>
                                                <SelectInput
                                                    label="provincia"
                                                    name="id_provincia"
                                                    value={form.id_provincia}
                                                    select={form.provincia}
                                                    onChange={handleChange}
                                                    options={provinces ? provinces.map((item) => ({
                                                        name: item.nombre,
                                                        id: item.id,
                                                    })) : []}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to="/ciudades" className="btn btn-outline-dark m-1">
                                        Cancelar
                                    </Link>
                                    <Button type="submit" text="Guardar" theme="calendar" classes="m-1"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default UpdateCity;
