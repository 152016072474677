import React, {useEffect, useState} from 'react';
import Breadcrumbs from "../../../components/breadcrumbs";
import {useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import Footer from "../../../components/footer";
import CardItem from "../../../components/card-item";
import {URLAPI} from "../../../config";
import {DirectionsRenderer, GoogleMap, MarkerF, useJsApiLoader} from "@react-google-maps/api";
import {KEY_MAPS} from "../../../config";
import {searchActivitiesDetail, getLogHistoryTaskScheduled} from "../../../redux/task_scheduled";
import InfiniteScroll from "react-infinite-scroller";
import Modal from "../../../components/modal";
import SelectInput from "../../../components/select";
import Chart from "react-apexcharts";
import {ReactComponent as ReactLogo} from '../../../button.svg';

const DeatilActivities = (props) => {
    let history = useHistory();
    const {token} = useSelector((state) => state.users_module.login.data) || null;
    const {username, date} = useParams();

    const _get_all_activities = useSelector(
        (state) => state.task_scheduled_module._get_all_activities
    );

    const [data, setData] = useState([]);
    const [taskScheduled, setTaskScheduled] = useState([]);
    const [store_x, setStore_x] = useState([]);
    const [store_y, setStore_y] = useState([]);
    const [task_x, setTask_x] = useState([]);
    const [task_y, setTask_y] = useState([]);
    const [markings, setMarkings] = useState([]);
    const [locations, setLocations] = useState([]);
    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
        if (_get_all_activities.data.datos) {
            // eslint-disable-next-line
            let filtro = _get_all_activities.data.datos.filter(element => {
                if (element.username === username) {
                    return element;
                }
            })
            setData(filtro[0]);
            let params = "?usuario=" + username + '&&fecha_inicio=' + date + '&&fecha_fin=' + date;
            activitiesDetail(params);
        }
        // eslint-disable-next-line
    }, [_get_all_activities, username, date]);

    const activitiesDetail = async (params) => {
        const response = await searchActivitiesDetail(token, params);
        if (response && response.data && response.data.cantidad > 0) {
            setTaskScheduled(response.data.datos);
            setStore_x(response.data.tiendas_x);
            setStore_y(response.data.tiendas_y);
            setTask_x(response.data.tareas_x);
            setTask_y(response.data.tareas_y);
            setMarkings(response.data.marcaciones);
            if (response.data.ubicaciones.length > 0) {
                calculateLocations(response.data.ubicaciones);
            }
            setLocations(response.data.ubicaciones);
            // eslint-disable-next-line
            let realizado = response.data.datos.filter(element => {
                if (parseInt(element.realizado) === 1) {
                    return element;
                }
            })
            if (realizado.length > 0){
                const porcentaje = (parseFloat(realizado.length * 100) / parseFloat(response.data.cantidad)).toFixed(2);
                setPercentage(porcentaje);
            } else{
                setPercentage(0);
            }
            

        } else {
            setTaskScheduled([]);
            setStore_x([]);
            setStore_y([]);
            setTask_x([]);
            setTask_y([]);
            setMarkings([]);
            // calculateLocations([]);
            setLocations([]);
            setPercentage(0);
        }
    };

    const [count, setCount] = useState(0);
    const [HasMore, setHasMore] = useState(true);

    const loadFunc = () => {
        if (taskScheduled) {
            if (taskScheduled.length > count) {
                const ordenado = taskScheduled.sort((a, b) => {
                    return new Date(b.fecha) - new Date(a.fecha);
                });
                setTaskScheduled([...data, ...ordenado.slice(count, count + 10)]);
                setCount(count + 10);
            }
            setHasMore(false);
        }
    };

    const [modal, setModal] = useState(false);
    const [modalLocations, setModalLocations] = useState(false);
    const [modalMarking, setModalMarking] = useState(false);
    const [item, setItem] = useState({});
    const [dataHistory, setDataHistory] = useState([]);

    const onItemClickListener = async (item) => {
        console.log('item');
        console.log(item);
        setItem(item);
        let params = "?id_tarea_programada=" + item.id_tarea_horario;
        const response = await getLogHistoryTaskScheduled(token, params);
        console.log(response);
        if (response && response.data && response.data.cantidad > 0) {
            setDataHistory(response.data.datos);
        }
        setModal(true);
    };

    const {isLoaded} = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: KEY_MAPS,
    });

    const [directionsResponse, setDirectionsResponse] = useState(null);
    const [directionsResponseLocations, setdirectionsResponseLocations] = useState(null);

    let directionsService1;
    const calculateLocations = (locations) => {
        directionsService1 = new window.google.maps.DirectionsService()
        const routesCopy = locations.map(route => {
            return {
                location: {lat: route.latitud, lng: route.longitud},
                stopover: true
            };
        });

        const origin =
            routesCopy.length === 1
                ? new window.google.maps.LatLng(
                    routesCopy[0].location.lat,
                    routesCopy[0].location.lng
                )
                : routesCopy.shift().location;
        const destination =
            routesCopy.length === 1
                ? new window.google.maps.LatLng(
                    routesCopy[0].location.lat,
                    routesCopy[0].location.lng
                )
                : routesCopy.pop().location;
        const waypoints = routesCopy;

        //call getDirection function
        getDirectionLocations(origin, destination, waypoints);
    };

    const getDirectionLocations = (origin, destination, waypoints) => {
        waypoints.length >= 1
            ? directionsService1.route(
                {
                    origin: origin,
                    destination: destination,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                    waypoints: waypoints
                },
                (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        setdirectionsResponseLocations(result)
                    } else {
                        console.error('error fetching directions1455 ' + result.toString());
                    }
                }
            )
            : directionsService1.route(
                {
                    origin: origin,
                    destination: destination,
                    travelMode: window.google.maps.TravelMode.DRIVING
                },
                (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        setdirectionsResponseLocations(result)
                    } else {
                        console.error('error fetching directions ' + result);
                    }
                }
            );
    };

    function clearRoute() {
        setDirectionsResponse(null);
    }

    let directionsService;
    const calculateRoute = () => {
        directionsService = new window.google.maps.DirectionsService()
        const routesCopy = item.ubicaciones.map(route => {
            return {
                location: {lat: route.lat, lng: route.lng},
                stopover: true
            };
        });

        const origin =
            routesCopy.length === 1
                ? new window.google.maps.LatLng(
                    routesCopy[0].location.lat,
                    routesCopy[0].location.lng
                )
                : routesCopy.shift().location;
        const destination =
            routesCopy.length === 1
                ? new window.google.maps.LatLng(
                    routesCopy[0].location.lat,
                    routesCopy[0].location.lng
                )
                : routesCopy.pop().location;
        const waypoints = routesCopy;

        //call getDirection function
        getDirection(origin, destination, waypoints);
    };

    const getDirection = (origin, destination, waypoints) => {
        waypoints.length >= 1
            ? directionsService.route(
                {
                    origin: origin,
                    destination: destination,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                    waypoints: waypoints
                },
                (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        setDirectionsResponse(result)
                    } else {
                        console.log('error fetching directions1455 ' + result);
                    }
                }
            )
            : directionsService.route(
                {
                    origin: origin,
                    destination: destination,
                    travelMode: window.google.maps.TravelMode.DRIVING
                },
                (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        setDirectionsResponse(result)
                    } else {
                        console.log('error fetching directions ' + result);
                    }
                }
            );
    };

    const filter_options = [
        {name: "HOY", id: 1},
        {name: "LOS ÚLTIMOS 7 DÍAS", id: 2},
        {name: "ESTE MES", id: 3},
        {name: "MES ANTERIOR", id: 4}
    ];

    const filterToday = (e) => {
        let params = '?'
        if (e.target.value === '0' || e.target.value === '1') {
            params += "usuario=" + username + '&&fecha_inicio=' + date + '&&fecha_fin=' + date;
        }
        if (e.target.value === '2') {
            let today = new Date();
            today.setDate(today.getDate() - 7)
            let month = today.getMonth() + 1;
            if (month.toString().length === 1) {
                month = '0' + month;
            }
            let day = today.getDate();
            if (day.toString().length === 1) {
                day = '0' + day;
            }
            const date_star = today.getFullYear() + '-' + month + '-' + day;
            params += "usuario=" + username + '&&fecha_inicio=' + date_star + '&&fecha_fin=' + date;
        }
        if (e.target.value === '3') {
            const year = new Date().getFullYear();
            let month = new Date().getMonth() + 1;
            if (month.toString().length === 1) {
                month = '0' + month;
            }
            const today = new Date(year, new Date().getMonth() + 1, 0);
            const date_star = year.toString() + '-' + month.toString() + '-01'
            const date_end = year.toString() + '-' + month.toString() + '-' + today.getDate()
            params += "usuario=" + username + '&&fecha_inicio=' + date_star + '&&fecha_fin=' + date_end;
        }
        if (e.target.value === '4') {
            const year = new Date().getFullYear();
            let month = new Date().getMonth();
            if (month === 1) {
                month = 12
            } else {
                if (month.toString().length === 1) {
                    month = '0' + month;
                }
            }
            const today = new Date(year, new Date().getMonth(), 0);
            let day = today.getDate();
            if (day.toString().length === 1) {
                day = '0' + day;
            }
            const date_star = year.toString() + '-' + month.toString() + '-01'
            const date_end = year.toString() + '-' + month.toString() + '-' + day
            params += "usuario=" + username + '&&fecha_inicio=' + date_star + '&&fecha_fin=' + date_end;
        }
        activitiesDetail(params);
    }

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Actividades"
                            items={[{label: "Lista de actividades", link: "/actividades"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="border_card d-flex">
                                            <div style={{cursor: 'pointer'}} onClick={() => {history.push(`/actividades`);}}>
                                                <ReactLogo width={50} heght={40}/>
                                            </div>
                                            <p className="ml-1" style={{marginTop: 10, fontSize: 18, color: '#727E8C'}}>Actividades de {data.usuario}</p>
                                        </div>
                                        <CardItem>
                                            <div className="row">
                                                <div className="col-9">
                                                    <div className="d-flex">
                                                        <div className="list-icon mr-1">
                                                            <div
                                                                className="avatar bg-rgba-primary m-0">
                                                                <img
                                                                    src={data.foto ? URLAPI + data.foto : window.location.origin + "/base/app-assets/images/profile/no_perfil.png"
                                                                    }
                                                                    alt="img"
                                                                    height="70"
                                                                    width="70"/>
                                                            </div>
                                                        </div>
                                                        <div className="list-content" style={{width: '30%'}}>
                                                            <p className="text-bold-700"
                                                               style={{fontSize: 18}}>{data.usuario}</p>
                                                            <p className="text-bold-700" style={{
                                                                color: '#FFC912',
                                                                fontSize: 40
                                                            }}>{parseFloat(percentage).toFixed(2)} %</p>
                                                            <div className="progress mt-1">
                                                                <div className="progress-bar bg-calendar"
                                                                     role="progressbar"
                                                                     style={{width: percentage + '%'}}
                                                                     aria-valuenow="100" aria-valuemin="0"
                                                                     aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-3 mt-2">
                                                    <SelectInput
                                                        id="filtro"
                                                        name="filtro"
                                                        options={filter_options}
                                                        onChange={filterToday}
                                                        select="HOY"
                                                    />
                                                </div>
                                            </div>
                                        </CardItem>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-content">
                                        <div className="row card-body">
                                            <div className="col-3"
                                                 style={{boxShadow: "0px 0px 5px #888888", padding: 10}}>
                                                <div className="mb-1">
                                                    <h5>Visitas por tiendas</h5>
                                                    {(store_x.length > 0 && store_y.length > 0) &&
                                                        <Chart
                                                            options={{
                                                                chart: {
                                                                    type: 'bar',
                                                                },
                                                                colors: ['#FFC912'],
                                                                plotOptions: {
                                                                    bar: {
                                                                        horizontal: true,
                                                                    }
                                                                },
                                                                xaxis: {
                                                                    categories: store_x
                                                                }
                                                            }}
                                                            series={[
                                                                {
                                                                    data: store_y
                                                                }
                                                            ]}
                                                            type="bar"
                                                            height={300}
                                                        />
                                                    }
                                                </div>
                                                <div className="mb-1">
                                                    <h5>Tareas por tiendas</h5>
                                                    {(task_x.length > 0 && task_y.length > 0) &&
                                                        <Chart
                                                            options={{
                                                                chart: {
                                                                    type: 'bar',
                                                                },
                                                                colors: ['#FFC912'],
                                                                plotOptions: {
                                                                    bar: {
                                                                        horizontal: true,
                                                                    }
                                                                },
                                                                xaxis: {
                                                                    categories: task_x
                                                                }
                                                            }}
                                                            series={[
                                                                {
                                                                    data: task_y
                                                                }
                                                            ]}
                                                            type="bar"
                                                            height={300}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-6"
                                                 style={{boxShadow: "0px 0px 5px #888888", padding: 10}}>
                                                <div className="mt-1 mb-1">
                                                    <div className=" d-flex">
                                                        <div className="ml-3 mr-5">
                                                            <h5 className="text-bold-700">Tareas</h5>
                                                        </div>
                                                        <div className="mr-2">
                                                            <div className=" d-flex">
                                                                <div style={{
                                                                    width: 20,
                                                                    height: 20,
                                                                    borderRadius: '50%',
                                                                    background: '#48D7A4',
                                                                    display: 'flex',
                                                                    marginRight: 10
                                                                }}/>
                                                                <h5 style={{color: '#48D7A4'}}>Realizadas</h5>
                                                            </div>
                                                        </div>
                                                        <div className="mr-2">
                                                            <div className=" d-flex">
                                                                <div style={{
                                                                    width: 20,
                                                                    height: 20,
                                                                    borderRadius: '50%',
                                                                    background: '#FFC912',
                                                                    display: 'flex',
                                                                    marginRight: 10
                                                                }}/>
                                                                <h5 style={{color: '#FFC912'}}>Pendientes</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="email-app-area mt-2">
                                                        <div className="email-app-list-wrapper">
                                                            <div className="email-app-list">
                                                                <div className="email-user-list list-group">
                                                                    <ul className="users-list-wrapper media-list"
                                                                        style={{
                                                                            overflowY: 'scroll',
                                                                        }}>
                                                                        <InfiniteScroll
                                                                            pageStart={0}
                                                                            loadMore={() => loadFunc()}
                                                                            hasMore={HasMore}
                                                                            useWindow={false}

                                                                            loader={
                                                                                <div
                                                                                    className="col-12 d-flex justify-content-center">
                                                                                    <div
                                                                                        className="spinner-border text-success"
                                                                                        role="status"
                                                                                    >
                                                                                        <span
                                                                                            className="sr-only">Loading...</span>
                                                                                    </div>
                                                                                    {" "}
                                                                                </div>
                                                                            }
                                                                        >
                                                                            {taskScheduled.map((item) => (
                                                                                <li className="list-group-item d-flex justify-content-between align-items-center"
                                                                                    onClick={() => onItemClickListener(item)}
                                                                                    style={{cursor: 'pointer'}}>
                                                                                    <div
                                                                                        className="d-flex align-items-center">
                                                                                        <div className="ml-1">
                                                                                            <span
                                                                                                className="fw-bold mb-0">Fecha</span><br/>
                                                                                            <strong
                                                                                                className="text-muted mb-0">{item.fecha}</strong>
                                                                                        </div>
                                                                                        <div className="ml-3">
                                                                                            <span
                                                                                                className="fw-bold mb-0">Tienda</span><br/>
                                                                                            <strong
                                                                                                className="text-muted mb-0">{item.tienda}</strong>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="text-right">
                                                                                        {item.realizado === 1 ?
                                                                                            <div style={{
                                                                                                width: 20,
                                                                                                height: 20,
                                                                                                borderRadius: '50%',
                                                                                                background: '#48D7A4',
                                                                                                display: 'flex',
                                                                                                marginRight: 10
                                                                                            }}/>
                                                                                            :
                                                                                            <div style={{
                                                                                                width: 20,
                                                                                                height: 20,
                                                                                                borderRadius: '50%',
                                                                                                background: '#FFC912',
                                                                                                display: 'flex',
                                                                                                marginRight: 10
                                                                                            }}/>

                                                                                        }

                                                                                    </div>
                                                                                </li>
                                                                            ))}
                                                                        </InfiniteScroll>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-3"
                                                 style={{boxShadow: "0px 0px 5px #888888", padding: 10}}>
                                                <div className="mb-1">
                                                    <h5>Marcaciones</h5>
                                                    {(isLoaded && locations.length > 0) &&
                                                        <GoogleMap
                                                            center={{
                                                                lat: locations[0].latitud,
                                                                lng: locations[0].longitud
                                                            }}
                                                            zoom={10}
                                                            options={{streetViewControl: false}}
                                                            mapContainerStyle={{width: "100%", height: "45vh"}}
                                                        >
                                                            {/*{locations.map((co) => (*/}
                                                            {/*    <MarkerF*/}
                                                            {/*        position={{lat: parseFloat(co.latitud), lng: parseFloat(co.longitud)}}*/}
                                                            {/*    />*/}
                                                            {/*))}*/}
                                                            {directionsResponseLocations && (
                                                                <DirectionsRenderer
                                                                    directions={directionsResponseLocations}/>
                                                            )}
                                                        </GoogleMap>
                                                    }
                                                    <div className="mt-3 text-center">
                                                        <button type="button" className="btn btn-calendar"
                                                                onClick={() => {
                                                                    setModalMarking(true)
                                                                }}>Ver detalles
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Modal className="modal-main" show={modalMarking} style={{width: "30%"}}>
                    <div className="modal-header">
                        <h5 className="text-bold-400">Detalles de marcaciones</h5>
                        <button type="button" style={{marginRight: -24}}
                                className="btn btn-calendar badge-circle" onClick={() => {
                            setModalMarking(!modalMarking);
                            setItem({})
                        }}> X
                        </button>
                    </div>
                    <div className="card-body">
                        <ul className="timeline">
                            {markings.map(_ => (
                                <li className="d-flex justify-content-between align-items-center">
                                    <div
                                        className="d-flex align-items-center">
                                        <div className="ml-1">
                                            <strong>{_.descripcion}</strong><br/>
                                            <span>{_.fecha}</span>
                                        </div>
                                    </div>
                                    <div className="text-right">
                                        <button type="button" className="btn btn-calendar" onClick={() => {
                                            setItem(_);
                                            setModal(true);
                                        }}>Ver
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </Modal>

                <Modal className="modal-main" show={modal} style={{width: "40%"}}>
                    <div className="modal-header">
                        <h5 className="text-bold-400">{(item && item.tipo !== 'TAREA') &&
                            <>{item.descripcion}</>
                        }</h5>
                        <button type="button" style={{marginRight: -24}}
                                className="btn btn-calendar badge-circle" onClick={() => {
                            setModal(!modal);
                            setItem({});
                            setDataHistory([]);
                        }}> X
                        </button>
                    </div>
                    <div className="card-body">
                        <div className="row mb-1">
                            {(item && item.tipo === 'TAREA') ?
                                <>
                                    <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                        <div style={{marginTop: 5, marginBottom: 5}}>
                                            <strong>{item.tarea}</strong>
                                        </div>
                                        <span>{item.lista_tarea}</span><br/>
                                        <span>{(item.realizado === 1) ? 'Realizada el ' + item.fecha : 'Fecha: ' + item.fecha}</span>
                                        {(item.ubicaciones.length > 0) &&
                                            <div className="d-flex">
                                                <img
                                                    src={window.location.origin + "/base/app-assets/images/logo/geolocation.png"}
                                                    alt="" height="20" width="20"
                                                    className="rounded-circle mr-1"/>
                                                {/*eslint-disable-next-line*/}
                                                <a href="#" onClick={() => {
                                                    setModalLocations(true);
                                                    calculateRoute();
                                                }}>Ver ubicación</a>
                                            </div>
                                        }
                                    </div>
                                    <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                        <div style={{marginTop: 5, marginBottom: 5}}>
                                            <strong>{item.tienda}</strong>
                                        </div>
                                        <span>{item.observacion_inicial}</span>
                                    </div>
                                    <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                        <div style={{marginTop: 5, marginBottom: 5}}>
                                            <strong>Recursos de la tarea</strong>
                                        </div>
                                        {item.recursos.map((re) => (
                                            <div className="d-flex">
                                                <p>{re.archivo}</p>
                                                <img
                                                    src={window.location.origin + "/base/app-assets/images/logo/download.png"}
                                                    alt="" height="20" width="20"
                                                    className="rounded-circle mr-1"/>
                                                {/*eslint-disable-next-line*/}
                                                <a href={URLAPI + re.archivo} target="_blank">{re.nombre}</a>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                        <div style={{marginTop: 5, marginBottom: 5}}>
                                            <strong>Resultados</strong>
                                        </div>
                                        {item.resultados.map((re) => (
                                            <div className="d-flex">
                                                <img
                                                    src={window.location.origin + "/base/app-assets/images/logo/download.png"}
                                                    alt="" height="20" width="20"
                                                    className="rounded-circle mr-1"/>
                                                {/*eslint-disable-next-line*/}
                                                <a href={URLAPI + re.archivo} target="_blank">{re.nombre}</a>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                        <div style={{marginTop: 5, marginBottom: 5}}>
                                            <strong>Fotografías de evidencia</strong>
                                        </div>
                                        <div className="row mb-1">
                                            {item.imagenes.map((re) => (
                                                <div className="col-4 text-center">
                                                    {/*eslint-disable-next-line*/}
                                                    <a href={URLAPI + re.archivo} target="_blank">
                                                        <img
                                                            src={URLAPI + re.archivo}
                                                            alt="" height="80" width="100"/>
                                                    </a>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                        <div style={{marginTop: 5, marginBottom: 5}}>
                                            <strong>Observaciones finales</strong>
                                        </div>
                                        {item.observacion && 
                                            <div style={{marginBottom: 10}}>
                                                <span>{item.observacion}</span><br/>
                                            </div>
                                        }
                                        <div style={{marginBottom: 10}}>
                                            <span>{item.observacion_final}</span><br/>
                                        </div>
                                    </div>
                                    {(dataHistory.length > 0) && (
                                        <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                            <div style={{marginTop: 5, marginBottom: 5}}>
                                                <strong>Historias</strong>
                                            </div>
                                            <div className="mb-1">
                                                    {dataHistory.map((item) => (
                                                        item.logs.map((x) => (
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <div><span>{x.nombre_tipo}</span></div>
                                                                <div className="text-right"><span className='text-right'>{x.fecha}</span></div>
                                                            </div>
                                                        ))
                                                    ))}
                                            </div>
                                        </div>
                                    )}
                                </>
                                :
                                <>
                                    <div className="col-12 mb-1">
                                        <span>Fecha y hora: {item.fecha}</span>
                                    </div>
                                    <div className="col-12 mb-1">
                                        <div className="d-flex">
                                            <img
                                                src={window.location.origin + "/base/app-assets/images/logo/geolocation.png"}
                                                alt="" height="20" width="20"
                                                className="rounded-circle mr-1"/>
                                            <span>Ubicación</span>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-1 text-center">
                                        {(item && item.latitud && item.longitud && isLoaded) &&
                                            <GoogleMap
                                                center={{
                                                    lat: parseFloat(item.latitud),
                                                    lng: parseFloat(item.longitud)
                                                }}
                                                zoom={11}
                                                options={{streetViewControl: false}}
                                                mapContainerStyle={{width: "100%", height: "50vh"}}
                                            >
                                                <MarkerF
                                                    position={{
                                                        lat: parseFloat(item.latitud),
                                                        lng: parseFloat(item.longitud)
                                                    }}
                                                />
                                            </GoogleMap>
                                        }
                                    </div>
                                    <div className="col-12 mb-1">
                                        <div className="d-flex">
                                            <img
                                                src={window.location.origin + "/base/app-assets/images/logo/image.png"}
                                                alt="" height="20" width="20"
                                                className="rounded-circle mr-1"/>
                                            <span>Foto</span>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-1 text-center">
                                        {/*eslint-disable-next-line*/}
                                        <a href={URLAPI + item.imagen} target="_blank">
                                            <img
                                                src={item.imagen ? URLAPI + item.imagen : window.location.origin + "/base/app-assets/images/logo/image.png"}
                                                alt="" height="180" width="50%"/>
                                        </a>
                                    </div>
                                </>

                            }
                        </div>
                    </div>
                </Modal>

                <Modal className="modal-main" show={modalLocations} style={{width: "40%"}}>
                    <div>
                        <button type="button" className="close btn btn-calendar badge-circle" onClick={() => {
                            setModalLocations(!modalLocations);
                            clearRoute();
                        }}> X
                        </button>
                    </div>
                    <div className="card-body">
                        <div className="row mb-1">
                            {(isLoaded && item && item.ubicaciones) &&
                                <GoogleMap
                                    center={item.ubicaciones[0]}
                                    zoom={11}
                                    options={{streetViewControl: false}}
                                    mapContainerStyle={{width: "100%", height: "50vh"}}
                                >
                                    {/*{item.ubicaciones.map((co) => (*/}
                                    {/*    <MarkerF*/}
                                    {/*        position={co}*/}
                                    {/*    />*/}
                                    {/*))}*/}
                                    {directionsResponse && (
                                        <DirectionsRenderer directions={directionsResponse}/>
                                    )}
                                </GoogleMap>
                            }
                        </div>
                    </div>
                </Modal>

            </div>
            <Footer/>
        </div>
    )

}

export default DeatilActivities;
