import {
    URLAPI,
    SCHEDULES_PATH,
    SCHEDULE_CREATE_PATH,
    SCHEDULE_UPDATE_PATH,
} from "../config/index";
import {LOGOUT} from './users'
import axios from "axios";

const init = {
    _get_all_schedules: {
        data: [],

        status: 0,
        message: {},
    },

    _create_schedule: {
        data: {},
        status: 0,
        message: {},
    },
    _update_schedule: {
        data: {},
        status: 0,
        message: {},
    },
};

const GET_ALL_SCHEDULES = "GET_ALL_SCHEDULES";
export const CREATE_SCHEDULE = "CREATE_SCHEDULE";
export const UPDATE_SCHEDULE = "UPDATE_SCHEDULE";

export const schedules_module = (state = init, action) => {
    switch (action.type) {
        case GET_ALL_SCHEDULES:
            return {
                ...state,
                _get_all_schedules: action.payload,
            };
        case CREATE_SCHEDULE:
            return {
                ...state,
                _create_schedule: action.payload,
            };
        case UPDATE_SCHEDULE:
            return {
                ...state,
                _update_schedule: action.payload,
            };
        case LOGOUT:
            return init;
        default:
            return state;
    }
};

export const getAllSchedules = (token) => async (dispatch) => {
    try {
        const response = await axios.get(`${URLAPI}${SCHEDULES_PATH}`, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: GET_ALL_SCHEDULES,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: GET_ALL_SCHEDULES,
                payload: {
                    data: [],
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: GET_ALL_SCHEDULES,
                payload: {
                    data: [],
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const createSchedule = (token, data) => async (dispatch) => {
    try {
        let form = new FormData();

        for (var key in data) {
            form.append(key, data[key]);
        }
        const response = await axios.post(
            `${URLAPI}${SCHEDULE_CREATE_PATH}`,
            form,
            {
                headers: {
                    Authorization: `Basic ${token}`,
                },
            }
        );
        dispatch({
            type: CREATE_SCHEDULE,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: CREATE_SCHEDULE,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CREATE_SCHEDULE,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const updateSchedule = (token, id, data) => async (dispatch) => {
    try {
        let form = new FormData();

        for (var key in data) {
            form.append(key, data[key]);
        }
        const response = await axios.patch(
            `${URLAPI}${SCHEDULE_UPDATE_PATH}${id}/`,
            form,
            {
                headers: {
                    Authorization: `Basic ${token}`,
                },
            }
        );
        dispatch({
            type: UPDATE_SCHEDULE,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: UPDATE_SCHEDULE,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: UPDATE_SCHEDULE,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const clear_schedules = (type) => async (dispatch) => {
    dispatch({
        type: type,
        payload: {
            data: [],
            status: 0,
            message: {},
        },
    });
};
