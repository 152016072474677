import React, {useEffect, useState} from "react";
import {TYPE_REPORT_GENERATE} from "../config/ws-client";
import {
    searchItemTaskScheduled,
} from '../redux/task_scheduled';
import {logout} from '../redux/users';
import {useDispatch, useSelector} from "react-redux";
import {KEY_MAPS, URLAPI} from '../config/index'
import Modal from "./modal";
import {DirectionsRenderer, GoogleMap, useJsApiLoader} from "@react-google-maps/api";
import TextArea from "./text-area";
import {useHistory} from "react-router-dom";
import {
    clear_notification,
    getAllNotifications,
    UPDATE_NOTIFICATIONS,
    updateNotifications
} from "../redux/notifications";
import { clear_user_module, update_password_user, UPDATE_PASSWORD_USER } from "../redux/users";
import InputPassword from "./input_password";
import LoadingSpinner from "./loading-spinner";
import {ToastContainer, toast} from "react-toastify";

const Navbar = (props) => {
    let history = useHistory();
    const dispatch = useDispatch();
    const [notification, setNotification] = useState([]);
    const [notificationTask, setNotificationTask] = useState([]);
    const [itemNotificationTask, setItemNotificationTask] = useState({});
    const _get_notification = useSelector((state) => state.task_scheduled_module._get_notification);
    const _get_all_notifications = useSelector((state) => state.notifications_module._get_all_notifications);
    const _session_data = useSelector((state) => state.users_module._session_data);
    const [open, setOpen] = useState(false);
    const [user_data, setUserData] = useState({
        id: 0,
        usuario: "",
        nombres: "",
        apellidos: "",
        correo: "",
        foto: "",
        grupo_permiso: {
            nombre: ""
        }
    });
    const {token, user} = useSelector((state) => state.users_module.login.data) || null;
    const [modalPasssword, setModalPasssword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [new_password1, setNewPassword1] = useState('');
    const [new_password2, setNewPassword2] = useState('');
    const [old_password, setOldPassword] = useState('');

    const _update_password_user = useSelector((state) => state.users_module._update_password_user);

    useEffect(() => {
        if (_update_password_user.status === 200) {
            dispatch(clear_user_module(UPDATE_PASSWORD_USER));
            history.push("/");
            setModalPasssword(false);
            setIsLoading(false);
            setOldPassword('');
            setNewPassword1('');
            setNewPassword2('');
            dispatch(logout(token));
        } else if (_update_password_user.status !== 0) {
            dispatch(clear_user_module(UPDATE_PASSWORD_USER));
            toast.error(_update_password_user.message, {position: toast.POSITION.TOP_RIGHT});
            //setModalPasssword(false);
            setIsLoading(false);
        }
    }, [_update_password_user, dispatch, history, token]);

    const [permisos, setPermisos] = useState([]);

    useEffect(() => {
        if (_session_data && _session_data.data) {
            setUserData(_session_data.data);
            if (_session_data.data.grupo_permiso && _session_data.data.grupo_permiso.permisos) {
                setPermisos(_session_data.data.grupo_permiso.permisos);
            }
        }
    }, [_session_data]);


    useEffect(() => {
        if (_get_notification && _get_notification.data) {
            setNotification(_get_notification.data);
        }
    }, [_get_notification]);

    useEffect(() => {
        if (permisos && permisos.filter(x => x.codigo === 'view_notifications').length > 0) {
            if (_get_all_notifications && _get_all_notifications.data && _get_all_notifications.data.datos) {
                // eslint-disable-next-line
                let filtro = _get_all_notifications.data.datos.filter(element => {
                    if (element.check === false) {
                        return element;
                    }
                })
                setNotificationTask(filtro)
            }
        }
    }, [_get_all_notifications, permisos]);

    useEffect(() => {
        // eslint-disable-next-line
        if (permisos.filter(x => x.codigo === 'view_taskscheduled').length > 0) {
            // setInterval(function () {
            dispatch(getAllNotifications(token, user));
            // }, 60000);
        }
        // eslint-disable-next-line
    }, [token, permisos]);


    useEffect(() => {
        if (open) {
            openMenu();
        } else {
            hideMenu();
        }
    }, [open])


    const handleClick = () => {
        dispatch(logout(token));
    }

    const download = (url, nombre) => {
        if (url && nombre) {
            var element = document.createElement('a');
            element.target = '_BLANK';
            document.body.appendChild(element);
            element.style = "display: none";

            element.href = url
            element.download = nombre

            element.click();
            document.body.removeChild(element)
        }

    }

    const openMenu = () => {
        document.body.classList.remove('menu-hide', 'menu-collapsed')
        document.body.classList.add('menu-open')
        document.body.classList.add('menu-expanded')

        if (document.body.classList.contains('vertical-overlay-menu')) {

            document.body.style = "overflow:hidden;"
        }
    }

    const hideMenu = () => {
        document.body.classList.remove('menu-open', 'menu-expanded')
        document.body.classList.add('menu-hide')

        document.body.style = "overflow:auto;"

    }

    const handleClickNotification = (data) => {

        if (data.type === TYPE_REPORT_GENERATE) {
            if (data.result) {

                download(URLAPI + data.result.url, data.result.nombre)
            }
        }

        let filter = notification
        let final = []
        filter.forEach(x => {
            let d = x;
            if (data.id === x.id) {
                if (d.read === false) {
                    d.read = true;
                }
            }
            final.push(d)
        })
        return;

    }


    const handleClickDropNotification = () => {
        let filter = notification
        let final = []
        filter.forEach(x => {
            let data = x;
            if (data.show === true) {
                data.show = false;
            }
            final.push(data)
        })
        setNotification(final)

    }

    async function itemClickNav(item) {
        setItemNotificationTask(item)
        let params = "?id=" + item.tarea.id_task_scheduled + '&&tipo=TAREA';
        const response = await searchItemTaskScheduled(token, params);
        if (response.data.datos.length > 0) {
            setItem(response.data.datos[0])
        }
        setModalNav(true);
    }

    const [modalNav, setModalNav] = useState(false);
    const [modalLocationsNav, setModalLocationsNav] = useState(false);
    const [item, setItem] = useState({});
    const [directionsResponse, setDirectionsResponse] = useState(null);

    let directionsService;
    const calculateRoute = () => {
        directionsService = new window.google.maps.DirectionsService()
        const routesCopy = item.ubicaciones.map(route => {
            return {
                location: {lat: route.lat, lng: route.lng},
                stopover: true
            };
        });

        const origin =
            routesCopy.length === 1
                ? new window.google.maps.LatLng(
                    routesCopy[0].location.lat,
                    routesCopy[0].location.lng
                )
                : routesCopy.shift().location;
        const destination =
            routesCopy.length === 1
                ? new window.google.maps.LatLng(
                    routesCopy[0].location.lat,
                    routesCopy[0].location.lng
                )
                : routesCopy.pop().location;
        const waypoints = routesCopy;

        //call getDirection function
        getDirection(origin, destination, waypoints);
    };

    const getDirection = (origin, destination, waypoints) => {
        waypoints.length >= 1
            ? directionsService.route(
                {
                    origin: origin,
                    destination: destination,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                    waypoints: waypoints
                },
                (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        setDirectionsResponse(result)
                    } else {
                        console.error('error fetching directions1455 ' + result);
                    }
                }
            )
            : directionsService.route(
                {
                    origin: origin,
                    destination: destination,
                    travelMode: window.google.maps.TravelMode.DRIVING
                },
                (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        setDirectionsResponse(result)
                    } else {
                        console.error('error fetching directions ' + result);
                    }
                }
            );
    };

    const {isLoaded} = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: KEY_MAPS,
    });

    function clearRoute() {
        setDirectionsResponse(null);
    }

    const [observationAdmin, setObservationAdmin] = useState(null);

    const handleChange = (e) => {
        setObservationAdmin(e.target.value);
    }

    const _update_notification = useSelector(
        (state) => state.notifications_module._update_notifications
    );

    useEffect(() => {
        if (_update_notification && _update_notification.status === 200) {
            dispatch(clear_notification(UPDATE_NOTIFICATIONS));
            setModalNav(false);
            toast.success('Notificación actualizada correctamente', {position: toast.POSITION.TOP_RIGHT});
            setNotificationTask(_update_notification.data.datos);
        } else if (_update_notification.status !== 0) {
            dispatch(clear_notification(UPDATE_NOTIFICATIONS));
            toast.error(_update_notification.message, {position: toast.POSITION.TOP_RIGHT});
        }
    }, [_update_notification, dispatch, history, token]);

    const handleSubmit = (id, type) => {
        const form = {
            observacion: observationAdmin,
            tipo: type
        }
        dispatch(updateNotifications(token, id, form));
    };

    const handleSubmitPassword = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (old_password === "") {
            toast.error("Debe ingresar la contraseña actual", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (new_password1 === "") {
            toast.error("Debe ingresar la nueva contraseña", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (new_password2 === "") {
            toast.error("Debe ingresar la confirmación de la nueva contraseña", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }

        if (new_password1 !== new_password2) {
            toast.error("Las contraseña nueva y su confirmación no coinciden", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        dispatch(update_password_user(token, user_data.id, {'old_password': old_password, 'new_password1': new_password1, 'new_password2': new_password2}));
    }

    return (
        <>
            <div className="header-navbar-shadow"/>
            <nav className="header-navbar main-header-navbar navbar-expand-lg navbar navbar-with-menu fixed-top ">
                <div className="navbar-wrapper">
                    <div className="navbar-container content">
                        <div className="navbar-collapse" id="navbar-mobile">
                            <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                                <ul className="nav navbar-nav">
                                    <li className="nav-item mobile-menu d-xl-none mr-auto"
                                        onClick={() => setOpen(!open)}
                                    >
                                        {/*eslint-disable-next-line*/}
                                        <a
                                            className="nav-link nav-menu-main menu-toggle hidden-xs"
                                            href="#"
                                            onClick={() => setOpen(!open)}
                                        >
                                            <i className="ficon bx bx-menu"/>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <ul className="nav navbar-nav float-right">
                                {/*eslint-disable-next-line*/}
                                {permisos.filter(x => x.codigo === 'view_notifications').length > 0 &&
                                    <li className="dropdown dropdown-notification nav-item">
                                        {/*eslint-disable-next-line*/}
                                        <a
                                            className="nav-link nav-link-label"
                                            data-toggle="dropdown"
                                        >
                                            <i className="ficon bx bx-bell bx-tada bx-flip-horizontal"></i>
                                            {/*eslint-disable-next-line*/}
                                            {notificationTask.filter(x => x.check === false).length > 0 && (
                                                <span className="badge badge-pill badge-danger badge-up">
                            {notificationTask.filter(x => x.check === false).length}
                          </span>
                                            )}
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-media dropdown-menu-right">
                                            <li className="dropdown-menu-header">
                                                <div
                                                    className="dropdown-header px-1 py-75 d-flex justify-content-between">
                            <span className="notification-title">
                              {notificationTask.length} Nuevas notificaciones
                            </span>
                                                </div>
                                            </li>
                                            <li className="scrollable-container media-list read-notification cursor-pointer"
                                                style={{overflow: 'scroll', '-webkit-overflow-scrolling': 'touch'}}>

                                                {notificationTask.length !== 0 ? notificationTask.reverse().map((item, index) => (
                                                    // eslint-disable-next-line
                                                    <a className={`d-flex justify-content-between ${item.check === true ? '' : 'read-notification'}`}
                                                       href="#" onClick={() => {
                                                        itemClickNav(item)
                                                    }}>
                                                        <div className="media d-flex align-items-center">
                                                            <div className="media-left pr-0">
                                                                <div className="avatar mr-1 m-0">
                                                                </div>
                                                            </div>
                                                            <div className="media-body">
                                                                <div className="row">
                                                                    <div className="4">
                                                                        <h6 className="media-heading mr-5">
                                                                            <span className="text-bold-300">Tarea</span>
                                                                        </h6>
                                                                        <h6 className="media-heading mr-5">
                                                                            <span
                                                                                className="text-bold-300">{item.tarea.tienda}</span>
                                                                        </h6>
                                                                    </div>
                                                                    <div className="8">
                                                                        <h6 className="media-heading">
                                                                            <span
                                                                                className="text-bold-500">{item.tarea.nombre}</span>
                                                                        </h6>
                                                                        <h6 className="media-heading">
                                                                            <span
                                                                                className="text-bold-300">{item.tarea.fecha}</span>
                                                                        </h6>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                )) : null}
                                            </li>

                                            <li className="dropdown-menu-footer">
                                                {/*eslint-disable-next-line*/}
                                                <a
                                                    className="dropdown-item p-50 text-primary justify-content-center"
                                                    href="#" onClick={() => {
                                                    history.push(`/notificaciones`)
                                                }}
                                                >
                                                    Ver todas
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                }
                                <li className="dropdown dropdown-notification nav-item">
                                    {/*eslint-disable-next-line*/}
                                    <a
                                        className="nav-link nav-link-label"
                                        href="#"
                                        data-toggle="dropdown"
                                        onClick={handleClickDropNotification}
                                    >
                                        <i className="ficon bx bxs-download bx-tada bx-flip-horizontal"></i>
                                        {notification.filter(x => x.show === true).length > 0 && (
                                            <span className="badge badge-pill badge-danger badge-up">
                        {notification.filter(x => x.show === true).length}
                      </span>
                                        )}
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-media dropdown-menu-right">
                                        <li className="dropdown-menu-header">
                                            <div className="dropdown-header px-1 py-75 d-flex justify-content-between">
                        <span className="notification-title">
                          {notification.length} Reportes
                        </span>
                                                {/*<span className="text-bold-400 cursor-pointer">*/}
                                                {/*  Ver todas*/}
                                                {/*</span>*/}
                                            </div>
                                        </li>
                                        <li className="scrollable-container media-list read-notification cursor-pointer"
                                            style={{overflow: 'scroll', '-webkit-overflow-scrolling': 'touch'}}>
                                            {/*eslint-disable-next-line*/}
                                            {notification.length != 0 ? notification.reverse().map((notification, index) => (
                                                // eslint-disable-next-line
                                                <a className={`d-flex justify-content-between ${notification.read == true ? '' : 'read-notification'}`}
                                                   href="#" onClick={() => handleClickNotification(notification)}>
                                                    <div className="media d-flex align-items-center">
                                                        <div className="media-left pr-0">
                                                            <div className="avatar mr-1 m-0">
                                                            </div>
                                                        </div>
                                                        <div className="media-body">
                                                            <h6 className="media-heading">

                                                                <span
                                                                    className="text-bold-500">{notification.title}</span>
                                                                {notification.message}
                                                            </h6>
                                                            <small className="notification-text">
                                                                {notification.date}
                                                            </small>
                                                        </div>
                                                    </div>
                                                </a>
                                            )) : null}
                                        </li>

                                        {/*<li className="dropdown-menu-footer">*/}
                                        {/*  <a*/}
                                        {/*      className="dropdown-item p-50 text-primary justify-content-center"*/}
                                        {/*      href="#"*/}
                                        {/*  >*/}
                                        {/*    Lee todas las notificaciones*/}
                                        {/*  </a>*/}
                                        {/*</li>*/}
                                    </ul>
                                </li>
                                <li className="dropdown dropdown-user nav-item">
                                    {/*eslint-disable-next-line*/}
                                    <a
                                        className="dropdown-toggle nav-link dropdown-user-link"
                                        href="#"
                                        data-toggle="dropdown"
                                    >
                                        <div className="user-nav d-sm-flex d-none">
                                            <span
                                                className="user-name">{user_data.nombres + " " + user_data.apellidos}</span>
                                            <span
                                                className="user-status text-muted">{user_data.grupo_permiso !== undefined ? user_data.grupo_permiso.nombre : ""}</span>
                                        </div>
                                        <span>
                      <img
                          className="round"
                          src={user_data.foto ? URLAPI + "/media/" + user_data.foto : window.location.origin + "/base/app-assets/images/icon/user.png"}
                          alt="avatar"
                          height="35"
                          width="35"
                      />
                                            {/*<i className="bx bx-user"/>*/}
                    </span>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right pb-0">

                                        <div className="dropdown-divider mb-0"/>
                                        {/*eslint-disable-next-line*/}
                                        <a className="dropdown-item" href="#" onClick={() => {
                                            setOldPassword('');
                                            setNewPassword1('');
                                            setNewPassword2('');
                                            setModalPasssword(true);
                                        }}>
                                            <i className="bx bxs-key mr-50"></i> Cambiar contraseña
                                        </a>
                                        {/*eslint-disable-next-line*/}
                                        <a className="dropdown-item" href="#" onClick={handleClick}>
                                            <i className="bx bx-power-off mr-50"/> Logout
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <Modal className="modal-main div-modal" show={modalNav}>
                    <div className="card">
                        <div className="modal-header">
                            <h5 className="text-bold-400">
                                {(item && item.tipo !== 'TAREA') &&
                                    <>{item.descripcion}</>
                                }
                            </h5>
                            <button type="button" style={{marginRight: -24}}
                                    className="btn btn-calendar badge-circle" onClick={() => {
                                setModalNav(!modalNav);
                                setItem({})
                            }}> X
                            </button>
                        </div>
                        <div className="card-body">
                            <div className="row mb-1">
                                {(item && item.tipo === 'TAREA') &&
                                    <>
                                        <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                            <div style={{marginTop: 5, marginBottom: 5}}>
                                                <strong>{item.tarea}</strong>
                                            </div>
                                            <span>{item.lista_tarea}</span><br/>
                                            <span>{(item.realizado === 1) ? 'Realizada el ' + item.fecha : 'Fecha: ' + item.fecha}</span>
                                            {(item.ubicaciones.length > 0) &&
                                                <div className="d-flex">
                                                    <img
                                                        src={window.location.origin + "/base/app-assets/images/logo/geolocation.png"}
                                                        alt="" height="20" width="20"
                                                        className="rounded-circle mr-1"/>
                                                    {/*eslint-disable-next-line*/}
                                                    <a href="#" onClick={() => {
                                                        setModalLocationsNav(true);
                                                        calculateRoute();
                                                    }}>Ver ubicación</a>
                                                </div>
                                            }
                                        </div>
                                        <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                            <div style={{marginTop: 5, marginBottom: 5}}>
                                                <strong>{item.tienda}</strong>
                                            </div>
                                            <span>{item.observacion_inicial}</span>
                                        </div>
                                        <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                            <div style={{marginTop: 5, marginBottom: 5}}>
                                                <strong>Recursos de la tarea</strong>
                                            </div>
                                            {item.recursos.map((re) => (
                                                <div className="d-flex">
                                                    <img
                                                        src={window.location.origin + "/base/app-assets/images/logo/download.png"}
                                                        alt="" height="20" width="20"
                                                        className="rounded-circle mr-1"/>
                                                    {/*eslint-disable-next-line*/}
                                                    <a href={URLAPI + re.archivo} target="_blank">{re.nombre}</a>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                            <div style={{marginTop: 5, marginBottom: 5}}>
                                                <strong>Resultados</strong>
                                            </div>
                                            {item.resultados.map((re) => (
                                                <div className="d-flex">
                                                    <img
                                                        src={window.location.origin + "/base/app-assets/images/logo/download.png"}
                                                        alt="" height="20" width="20"
                                                        className="rounded-circle mr-1"/>
                                                    {/*eslint-disable-next-line*/}
                                                    <a href={URLAPI + re.archivo} target="_blank">{re.nombre}</a>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                            <div style={{marginTop: 5, marginBottom: 5}}>
                                                <strong>Fotografías de evidencia</strong>
                                            </div>
                                            <div className="row mb-1">
                                                {item.imagenes.map((re) => (
                                                    <div className="col-4 text-center">
                                                        {/*eslint-disable-next-line*/}
                                                        <a href={URLAPI + re.archivo} target="_blank">
                                                            <img
                                                                src={URLAPI + re.archivo}
                                                                alt="" height="80" width="100"/>
                                                        </a>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="col-12 mb-1" style={{background: '#F4F4F4'}}>
                                            <div style={{marginTop: 5, marginBottom: 5}}>
                                                <strong>Observaciones finales</strong>
                                            </div>
                                            <span>{item.observacion_final}</span>
                                        </div>
                                        <div className="col-12 mb-1 pt-1" style={{background: '#F4F4F4'}}>
                                            <TextArea
                                                label="Observaciones auditoría"
                                                name="observationAdmin"
                                                id="observationAdmin"
                                                onChange={handleChange}
                                                value={observationAdmin}
                                                rows={5}
                                                placeholder="Observaciones auditoría"
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                            {itemNotificationTask.check === false &&
                                <div className="text-center">
                                    <button className="btn btn-calendar mr-5" onClick={() => {
                                        handleSubmit(itemNotificationTask.id, 1)
                                    }}>Omitir
                                    </button>
                                    <button className="btn btn-calendar" onClick={() => {
                                        handleSubmit(itemNotificationTask.id, 2)
                                    }}>Notificar a zonal
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                </Modal>

                <Modal className="modal-main div-modal" show={modalLocationsNav}>
                    <div className="card">
                        <div>
                            <button type="button" className="close btn btn-calendar badge-circle" onClick={() => {
                                setModalLocationsNav(!modalLocationsNav);
                                clearRoute();
                            }}> X
                            </button>
                        </div>
                        <div className="card-body">
                            <div className="row mb-1">
                                {(isLoaded && item && item.ubicaciones) &&
                                    <GoogleMap
                                        center={item.ubicaciones[0]}
                                        zoom={11}
                                        options={{streetViewControl: false}}
                                        mapContainerStyle={{width: "100%", height: "50vh"}}
                                    >
                                        {directionsResponse && (
                                            <DirectionsRenderer directions={directionsResponse}/>
                                        )}
                                    </GoogleMap>
                                }
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal className="modal-main div-modal" show={modalPasssword}>
                    <form onSubmit={handleSubmitPassword}>
                        <div><ToastContainer/></div>
                        {isLoading && <LoadingSpinner/>}
                        <div className="card-body">
                        <h5 className="titulo_modal type_text mb-2">Cambiar contraseña</h5>
                            <div className="form-group">
                            <InputPassword
                                type="password"
                                name="old_password"
                                id="old_password"
                                id_div1="show_hide_password_old"
                                id_div2="password_old"
                                label="Contraseña actual"
                                value={old_password}
                                onChange={(e) => setOldPassword(e.target.value)}
                            />
                            <InputPassword
                                type="password"
                                name="new_password1"
                                id="new_password1"
                                id_div1="show_hide_password"
                                id_div2="password"
                                label="Nueva contraseña"
                                value={new_password1}
                                onChange={(e) => setNewPassword1(e.target.value)}
                            />
                            <InputPassword
                                type="password"
                                name="new_password2"
                                id="new_password2"
                                id_div1="show_hide_password1"
                                id_div2="password1"
                                label="Confirmar contraseña"
                                value={new_password2}
                                onChange={(e) => setNewPassword2(e.target.value)}
                            />
                            </div>
                        </div>
                        <div className="card-footer text-center">
                            <button onClick={() => {}} className={`btn btn-calendar mb-1 button_div`}>Guardar</button>
                            <button type="button" onClick={() => {setModalPasssword(false);}} className={`btn btn-outline-calendar button_div`}>Cancelar</button>
                        </div>
                    </form>
                </Modal>
            </nav>

        </>

    );
};

export default Navbar;
