import {
    URLAPI,
    TASK_SCHEDULED_ADMIN_PATH,
    TASK_SCHEDULED_PATH,
    TASK_SCHEDULED_EXPORT_PATH,
    TASK_SCHEDULED_REPORT_CHECK_PATH,
    TASK_SCHEDULED_ITEM_PATH,
    ACTIVITIES_PATH,
    ACTIVITIES_DETAIL_PATH,
    ACTIVITIES_AGENDA_PATH,
    ACTIVITIES_MONTHLY_PATH,
    CREATE_TASK_SCHEDULED_PATH,
    UPDATE_TASK_SCHEDULED_PATH,
    RESCHEDULED_TASK_SCHEDULED_PATH,
    CANCEL_TASK_SCHEDULED_PATH,
    GET_LOG_TASK_SCHEDULED_PATH,
    GET_LOG_HISTORY_TASK_SCHEDULED_PATH,
    CREATE_LOG_TASK_SCHEDULED_PATH,
    CONFIRM_TASK_SCHEDULED_PATH,
    CONFIRM_SKIP_TASK_SCHEDULED_PATH,
    CREATE_LOCATION_TASK_SCHEDULED_PATH,
    TASK_RESOURCES_PATH
} from "../config/index";
import { LOGOUT } from './users'
import axios from "axios";

const init = {
    _get_all_task_scheduled: {
        data: [],

        status: 0,
        message: {},
    },
    _get_all_task_scheduled_user: {
        data: [],

        status: 0,
        message: {},
    },
    _get_all_activities: {
        data: [],

        status: 0,
        message: {},
    },
    _get_notification: {
        data: [],
    },
    _get_report: {
        data: {},
        status: 0,
        message: ""
    },
    _create_task_scheduled: {
        data: {},
        status: 0,
        message: {},
    },
    _update_task_scheduled: {
        data: {},
        status: 0,
        message: {},
    },
    _cancel_task_scheduled: {
        data: {},
        status: 0,
        message: {},
    },
    _rescheduled_task_scheduled: {
        data: {},
        status: 0,
        message: {},
    },
    _confirm_task_scheduled: {
        data: {},
        status: 0,
        message: {},
    },
    _confirm_skip_task_scheduled: {
        data: {},
        status: 0,
        message: {},
    },
    _create_location_task_scheduled: {
        data: {},
        status: 0,
        message: {},
    },
};

const GET_ALL_TASK_SCHEDULED = "GET_ALL_TASK_SCHEDULED";
const GET_ALL_TASK_SCHEDULED_USER = "GET_ALL_TASK_SCHEDULED_USER";
const GET_ALL_ACTIVITIES = "GET_ALL_ACTIVITIES";
export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const GET_REPORT = "GET_REPORT"
export const CREATE_TASK_SCHEDULED = "CREATE_TASK_SCHEDULED";
export const UPDATE_TASK_SCHEDULED = "UPDATE_TASK_SCHEDULED";
export const RESCHEDULED_TASK_SCHEDULED = "RESCHEDULED_TASK_SCHEDULED";
export const CONFIRM_TASK_SCHEDULED = "CONFIRM_TASK_SCHEDULED";
export const CONFIRM_SKIP_TASK_SCHEDULED = "CONFIRM_SKIP_TASK_SCHEDULED";
export const CANCEL_TASK_SCHEDULED = "CANCEL_TASK_SCHEDULED";
export const CREATE_LOCATION_TASK_SCHEDULED = "CREATE_LOCATION_TASK_SCHEDULED";

export const task_scheduled_module = (state = init, action) => {
    switch (action.type) {
        case GET_ALL_TASK_SCHEDULED:
            return {
                ...state,
                _get_all_task_scheduled: action.payload,
            };
        case GET_ALL_TASK_SCHEDULED_USER:
            return {
                ...state,
                _get_all_task_scheduled_user: action.payload,
            };
        case GET_ALL_ACTIVITIES:
            return {
                ...state,
                _get_all_activities: action.payload,
            };
        case GET_NOTIFICATION:
            return {
                ...state,
                _get_notification: action.payload,
            };

        case GET_REPORT:
            return {
                ...state,
                _get_report: action.payload
            };
        case CREATE_TASK_SCHEDULED:
            return {
                ...state,
                _create_task_scheduled: action.payload,
            };
        case UPDATE_TASK_SCHEDULED:
            return {
                ...state,
                _update_task_scheduled: action.payload,
            };
        case RESCHEDULED_TASK_SCHEDULED:
            return {
                ...state,
                _rescheduled_task_scheduled: action.payload,
            };
        case CONFIRM_TASK_SCHEDULED:
            return {
                ...state,
                _confirm_task_scheduled: action.payload,
            };
        case CONFIRM_SKIP_TASK_SCHEDULED:
            return {
                ...state,
                _confirm_skip_task_scheduled: action.payload,
            };
        case CANCEL_TASK_SCHEDULED:
            return {
                ...state,
                _cancel_task_scheduled: action.payload,
            };
        case CREATE_LOCATION_TASK_SCHEDULED:
            return {
                ...state,
                _create_location_task_scheduled: action.payload,
            };
        case LOGOUT:
            return init;
        default:
            return state;
    }
};

export const getAllTaskScheduled = (token, page) => async (dispatch) => {
    try {
        const response = await axios.get(`${URLAPI}${TASK_SCHEDULED_ADMIN_PATH}?page=${page}`, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: GET_ALL_TASK_SCHEDULED,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: GET_ALL_TASK_SCHEDULED,
                payload: {
                    data: [],
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: GET_ALL_TASK_SCHEDULED,
                payload: {
                    data: [],
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};


export const getAllTaskScheduledUser = (token, user, date) => async (dispatch) => {
    try {
        const paramsUrl = '?usuario=' + user + '&&fecha=' + date
        const response = await axios.get(`${URLAPI}${TASK_SCHEDULED_PATH}${paramsUrl}`, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: GET_ALL_TASK_SCHEDULED_USER,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: GET_ALL_TASK_SCHEDULED_USER,
                payload: {
                    data: [],
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: GET_ALL_TASK_SCHEDULED_USER,
                payload: {
                    data: [],
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const searchTaskScheduledUser = async (token, paramsUrl) => {
    return await axios.get(`${URLAPI}${TASK_SCHEDULED_PATH}${paramsUrl}`, {
        headers: {
            Authorization: `Basic ${token}`,
        },
    });
}

export const createTaskScheduled = (token, data) => async (dispatch) => {
    try {
        const response = await axios.post(
            `${URLAPI}${CREATE_TASK_SCHEDULED_PATH}`,
            data,
            {
                headers: {
                    Authorization: `Basic ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
        dispatch({
            type: CREATE_TASK_SCHEDULED,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: CREATE_TASK_SCHEDULED,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CREATE_TASK_SCHEDULED,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const updateTaskScheduled = (token, id, data) => async (dispatch) => {
    try {
        const response = await axios.patch(
            `${URLAPI}${UPDATE_TASK_SCHEDULED_PATH}${id}/`,
            data,
            {
                headers: {
                    Authorization: `Basic ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
        dispatch({
            type: UPDATE_TASK_SCHEDULED,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: UPDATE_TASK_SCHEDULED,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: UPDATE_TASK_SCHEDULED,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const rescheduledTaskScheduled = (token, id, data) => async (dispatch) => {
    try {
        // let form = new FormData();
        //
        // for (var key in data) {
        //     form.append(key, data[key]);
        // }
        const response = await axios.patch(
            `${URLAPI}${RESCHEDULED_TASK_SCHEDULED_PATH}${id}/`,
            data,
            {
                headers: {
                    Authorization: `Basic ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
        dispatch({
            type: RESCHEDULED_TASK_SCHEDULED,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: RESCHEDULED_TASK_SCHEDULED,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: RESCHEDULED_TASK_SCHEDULED,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const confirmTaskScheduled = (token, id, data) => async (dispatch) => {
    try {
        const response = await axios.patch(
            `${URLAPI}${CONFIRM_TASK_SCHEDULED_PATH}${id}/`,
            data,
            {
                headers: {
                    Authorization: `Basic ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
        dispatch({
            type: CONFIRM_TASK_SCHEDULED,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: CONFIRM_TASK_SCHEDULED,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CONFIRM_TASK_SCHEDULED,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const confirmSkipTaskScheduled = (token, id, data) => async (dispatch) => {
    try {
        const response = await axios.patch(
            `${URLAPI}${CONFIRM_SKIP_TASK_SCHEDULED_PATH}${id}/`,
            data,
            {
                headers: {
                    Authorization: `Basic ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
        dispatch({
            type: CONFIRM_SKIP_TASK_SCHEDULED,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: CONFIRM_SKIP_TASK_SCHEDULED,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CONFIRM_SKIP_TASK_SCHEDULED,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const cancelTaskScheduled = (token, id, data) => async (dispatch) => {
    try {
        const response = await axios.patch(
            `${URLAPI}${CANCEL_TASK_SCHEDULED_PATH}${id}/`,
            data,
            {
                headers: {
                    Authorization: `Basic ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
        dispatch({
            type: CANCEL_TASK_SCHEDULED,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: CANCEL_TASK_SCHEDULED,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CANCEL_TASK_SCHEDULED,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const createLocationTaskScheduled = (token, data) => async (dispatch) => {
    try {
        const response = await axios.post(
            `${URLAPI}${CREATE_LOCATION_TASK_SCHEDULED_PATH}`,
            data,
            {
                headers: {
                    Authorization: `Basic ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
        dispatch({
            type: CREATE_LOCATION_TASK_SCHEDULED,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: CREATE_LOCATION_TASK_SCHEDULED,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CREATE_LOCATION_TASK_SCHEDULED,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const getAllActivities = (token) => async (dispatch) => {
    try {
        const response = await axios.get(`${URLAPI}${ACTIVITIES_PATH}`, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: GET_ALL_ACTIVITIES,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: GET_ALL_ACTIVITIES,
                payload: {
                    data: [],
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: GET_ALL_ACTIVITIES,
                payload: {
                    data: [],
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const get_report = (data, token) => async (dispatch) => {
    try {
        const res = await axios.post(URLAPI + TASK_SCHEDULED_EXPORT_PATH, data, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        })

        dispatch({
            type: GET_REPORT,
            payload: {
                data: res.data,
                status: res.status,
                message: ""
            }
        })
    } catch (e) {
        dispatch({
            type: GET_REPORT,
            payload: {
                data: {},
                status: 500,
                message: "Ocurrio un error al generar el reporte"
            }
        })
    }
}

export const save_notification = (data) => async (dispatch) => {

    dispatch({
        type: GET_NOTIFICATION,
        payload: {
            data: data,
        }
    });
}

export const searchItemTaskScheduled = async (token, paramsUrl) => {
    return await axios.get(`${URLAPI}${TASK_SCHEDULED_ITEM_PATH}${paramsUrl}`, {
        headers: {
            Authorization: `Basic ${token}`,
        },
    });
}

export const searchTaskScheduled = async (token, paramsUrl) => {
    return await axios.get(`${URLAPI}${TASK_SCHEDULED_ADMIN_PATH}${paramsUrl}`, {
        headers: {
            Authorization: `Basic ${token}`,
        },
    });
}

export const searchActivities = async (token, paramsUrl) => {
    return await axios.get(`${URLAPI}${ACTIVITIES_PATH}${paramsUrl}`, {
        headers: {
            Authorization: `Basic ${token}`,
        },
    });
}

export const searchTaskReources = async (token, paramsUrl) => {
    return await axios.get(`${URLAPI}${TASK_RESOURCES_PATH}${paramsUrl}`, {
        headers: {
            Authorization: `Basic ${token}`,
        },
    });
}

export const searchActivitiesMonthly = async (token, paramsUrl) => {
    return await axios.get(`${URLAPI}${ACTIVITIES_MONTHLY_PATH}${paramsUrl}`, {
        headers: {
            Authorization: `Basic ${token}`,
        },
    });
}

export const searchActivitiesDetail = async (token, paramsUrl) => {
    return await axios.get(`${URLAPI}${ACTIVITIES_DETAIL_PATH}${paramsUrl}`, {
        headers: {
            Authorization: `Basic ${token}`,
        },
    });
}

export const searchActivitiesAgenda = async (token, paramsUrl) => {
    return await axios.get(`${URLAPI}${ACTIVITIES_AGENDA_PATH}${paramsUrl}`, {
        headers: {
            Authorization: `Basic ${token}`,
        },
    });
}

export const getLogTaskScheduled = async (token, paramsUrl) => {
    return await axios.get(`${URLAPI}${GET_LOG_TASK_SCHEDULED_PATH}${paramsUrl}`, {
        headers: {
            Authorization: `Basic ${token}`,
        },
    });
}

export const getLogHistoryTaskScheduled = async (token, paramsUrl) => {
    return await axios.get(`${URLAPI}${GET_LOG_HISTORY_TASK_SCHEDULED_PATH}${paramsUrl}`, {
        headers: {
            Authorization: `Basic ${token}`,
        },
    });
}

export const createLogTaskScheduled = async (token, data) => {
    return await axios.post(URLAPI + CREATE_LOG_TASK_SCHEDULED_PATH, data, {
        headers: {
            Authorization: `Basic ${token}`,
        },
    });
}

export const check_export_status = async (token, task_id) => {
    return await axios.get(`${URLAPI}${TASK_SCHEDULED_REPORT_CHECK_PATH}${task_id}/`, {
        headers: {
            Authorization: `Basic ${token}`,
        },
    })
}

export const clear_task_scheduled = (type) => async (dispatch) => {
    dispatch({
        type: type,
        payload: {
            data: [],
            status: 0,
            message: {},
        },
    });
};

// export const clear_activities = (type) => async (dispatch) => {
//     dispatch({
//         type: type,
//         payload: {
//             data: [],
//             status: 0,
//             message: {},
//         },
//     });
// };
